import './ActivityListVp.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Modal,
  Pagination,
  prompt,
  RadioGroup,
  Section,
  TabPane,
  Tabs,
  Todo,
  VSControlledForm,
  VSFormFooter,
  VSFormFooterSubmit,
  VSFormItem,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { isEmpty } from 'lodash';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useCallback, useState } from 'react';

import styles from './TeamListVp.module.less';

const ActivityListVp = (props) => {
  /**
   * 活动类型
   * @type {string}
   */
  const [activityType, setActivityType] = useState(
    props?.type || 'INVOKE_TYPE_ACTIVITY',
  );
  /**
   * 活动类型参数
   * @param {string} type
   * @returns {object}
   */
  const [querys, setQuerys] = useState({ size: 10, page: 1 });
  const [total, setTotal] = useState(0);
  const formatTableParams = (type) => {
    return { activityTypeIs: type };
  };

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.ActivityInfoController_pageActivityInfo_df266a?.({
          qto: {
            activityTypeIs: activityType,
            activityCategoryOneIdIs: params?.extra?.activityCategoryOneName?.id,
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
          },
        });
      setTotal(res?.data?.count ?? res?.data?.length ?? 0);
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [activityType],
  );

  return (
    <div style={{ minHeight: '88vh' }} className="container-box ActivityListVp">
      <Section>
        <div style={{ marginBottom: '15px' }}>
          <Breadcrumb>
            <Breadcrumb.Item>活动列表</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="container__header ">
          <VSControlledForm
            id="form"
            value={querys}
            onSubmit={(_value) => {
              setQuerys({ ..._value, from: 0, size: 10, page: 1 });
            }}
            layout="inline"
            className={styles['vs-form-footer']}
          >
            <VSFormItem
              name={['activityNameLike']}
              label="活动名称"
              valueType="text"
              style={{ marginBottom: 16 }}
              fieldProps={{
                placeholder: '请输入活动名称',
                width: 250,
              }}
              formItemProps={{
                rules: [
                  {
                    message: '活动名称长度不合法',
                    type: 'string',
                    min: 0,
                    max: 1024,
                  },
                ],
              }}
            />

            <VSFormItem
              name={['activityNatureIs']}
              label="活动性质"
              align="center"
              style={{ marginBottom: 16 }}
              editable={false}
              valueType="select"
              dataSource={[
                { label: '村级发起', value: 'VILLAGE_INITIATE' },
                { label: '镇级发起', value: 'TOWN_INITIATE' },
              ]}
              fieldProps={{
                width: 250,
                placeholder: '请选择活动性质',
              }}
            />

            <VSFormItem
              name={['startTimeRangeIn']}
              label="活动时间"
              style={{ marginBottom: 16 }}
              valueType="dateRange"
              transform={(values) => {
                if (!Array.isArray(values)) return values;
                return ['startTimeRangeIn'].reduceRight(
                  (acc, curr) => ({ [curr]: acc }),
                  {
                    begin: values[0],
                    end: values[1],
                    beginInclude: true,
                    endInclude: true,
                  },
                );
              }}
              convertValue={(v) => {
                if (v?.begin) {
                  return [v.begin, v.end];
                }
                return v;
              }}
              fieldProps={{
                width: 300,
                showTime: true,
                placeholder: ['开始时间', '结束时间'],
                separator: '至',
                valueType: 'string',
                format: 'YYYY-MM-DD HH:mm:ss',
              }}
            />

            <VSFormItem
              name={['activityCategoryOneName']}
              label="活动类别"
              align="center"
              style={{ marginBottom: 16 }}
              editable={false}
              valueType="select"
              fieldProps={{
                width: 250,
                placeholder: '请选择活动类别',
                fieldNames: { value: 'id', label: 'categoryName' },
                dataSource: () => {
                  // 活动类别列表数据从接口获取
                  return new Promise((resolve, reject) => {
                    vsf.services
                      .ActivityCategoryController_getOneCategoryList_72bb78?.()
                      .then((res) => {
                        if (res.data) {
                          resolve(res.data);
                        } else {
                          reject();
                        }
                      })
                      .catch(reject);
                  });
                },
              }}
            />

            <VSFormItem
              name={['activityStatusIs']}
              label="活动状态"
              align="center"
              editable={false}
              style={{ marginBottom: 16 }}
              valueType="select"
              order={-3}
              dataSource={[
                { label: '未开始', value: 'NOT_START' },
                { label: '进行中', value: 'A_IN_PROGRESS' },
                { label: '已结束', value: 'Z_ALREADY_END' },
              ]}
              render={(dom, data) => {
                if (data.activityStatus === 'A_IN_PROGRESS') {
                  return <span style={{ color: 'orange' }}>{dom}</span>;
                }
                return dom;
              }}
              fieldProps={{
                width: 250,
                placeholder: '请选择活动状态',
              }}
            />

            <VSFormItem
              name={['activityTimeIntervalIs']}
              label="周期活动"
              style={{ marginBottom: 16 }}
              align="center"
              editable={false}
              valueType="select"
              order={-3}
              dataSource={[
                { label: '仅一次', value: 'ONCE' },
                { label: '每天', value: 'EVERYDAY' },
                { label: '每周', value: 'WEEKLY' },
                { label: '每月', value: 'MONTHLY' },
              ]}
              fieldProps={{
                width: 250,
                placeholder: '请选择活动周期',
              }}
            />

            <VSFormItem
              name={['nicknameLike']}
              label="发起人"
              align="center"
              editable={false}
              style={{ marginBottom: 16 }}
              valueType="text"
              fieldProps={{
                width: 250,
                placeholder: '请输入发起人姓名',
              }}
            />

            <VSFormFooter>
              <VSFormFooterSubmit children="查询" />
              <Button
                children="重置"
                onClick={() => {
                  setQuerys({
                    from: 0,
                    size: 10,
                    orderList: undefined,
                    activityNameLike: undefined,
                    activityNatureIs: undefined,
                    activityCategoryOneIdIs: undefined,
                    activityStatusIs: undefined,
                    nicknameLike: undefined,
                    activityTimeIntervalIs: undefined,
                    startTimeRangeIn: undefined,
                    activityTypeIs: activityType,
                    activityCategoryOneName: undefined,
                    page: 1,
                  });
                }}
              />
            </VSFormFooter>
          </VSControlledForm>
        </div>

        <div className="container__content">
          <div className="container__btn">
            <ButtonGroup>
              <Button
                type="primary"
                onClick={() => vsf.navigateTo('/activityManage/scoreConfig')}
              >
                赋分规则配置
              </Button>
              <Button
                type="primary"
                onClick={async () => {
                  const value = await prompt(
                    {
                      title: '新建活动',
                      value: { type: 'INVOKE_TYPE_ACTIVITY' },
                      items: [
                        {
                          name: 'type',
                          formItemProps: {
                            label: '请选择创建活动的类型',
                            rules: [
                              {
                                require: true,
                                message: '必须选择活动类型',
                              },
                            ],
                          },
                          render: (item) => {
                            return (
                              <RadioGroup
                                {...item}
                                dataSource={[
                                  {
                                    label: '参与类活动',
                                    value: 'INVOKE_TYPE_ACTIVITY',
                                  },
                                  {
                                    label: '上传证明类活动',
                                    value: 'UPLOAD_PROOF_TYPE_ACTIVITY',
                                  },
                                ]}
                              />
                            );
                          },
                        },
                      ],
                    },
                    {
                      modalProps: {
                        width: 500,
                      },
                    },
                  );
                  if (value) {
                    vsf.navigateTo(`/activityManage/add/${value.type}/-1`);
                  }
                }}
              >
                新建活动
              </Button>
            </ButtonGroup>
          </div>
          <VSPromiseTable
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
            scroll={{ x: '90vw' }}
            onRow={(data) => {
              return {
                className: data.isTopping ? 'topping' : undefined,
              };
            }}
            onFetch={onFetch}
            extraParams={querys}
            pagination={{
              showQuickJumper: true,
              showSizeChanger: true,
              defaultPageSize: 10,
            }}
          >
            <VSTableToolbar
              title={
                <Tabs
                  activeKey={activityType}
                  onChange={(_value) => {
                    setActivityType(_value);
                    vsf.navigateTo(
                      `/activityManage/activityList?type=${_value}`,
                    );
                  }}
                >
                  <TabPane
                    key="INVOKE_TYPE_ACTIVITY"
                    tab="参与类活动"
                  ></TabPane>
                  <TabPane
                    key="UPLOAD_PROOF_TYPE_ACTIVITY"
                    tab="上传证明类活动"
                  ></TabPane>
                </Tabs>
              }
            />
            <VSTableColumn
              dataIndex={['id']}
              title="编号"
              width={100}
              align="center"
              editable={false}
              valueType="digit"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['activityImg']}
              title="活动封面"
              align="center"
              width={100}
              editable={false}
              valueType="image"
            />

            <VSTableColumn
              dataIndex={['promoterUser', 'nickname']}
              title="发起人"
              align="center"
              editable={false}
              valueType="text"
              width={100}
              // search
              order={-5}
              columnKey={'nicknameLike'}
              fieldProps={{
                placeholder: '请输入发起人姓名',
              }}
            />

            <VSTableColumn
              dataIndex={['activityCategoryOneName']}
              title="活动类别"
              align="center"
              editable={false}
              valueType="select"
              // search
              order={-2}
              columnKey={'activityCategoryOneIdIs'}
              formItemProps={{
                rules: [
                  {
                    message: '活动类别的值不合法',
                    type: 'number',
                    min: 0,
                    max: null,
                  },
                ],
              }}
              width={120}
              render={(_, data) => {
                const ret = [];
                if (data.activityCategoryThreeName) {
                  ret.push(data.activityCategoryThreeName);
                } else if (data.activityCategoryTwoName) {
                  ret.push(data.activityCategoryTwoName);
                } else if (data.activityCategoryOneName) {
                  ret.push(data.activityCategoryOneName);
                }

                return ret;
              }}
              fieldProps={{
                placeholder: '请选择活动类别',
                fieldNames: { value: 'id', label: 'categoryName' },
                dataSource: () => {
                  // 活动类别列表数据从接口获取
                  return new Promise((resolve, reject) => {
                    vsf.services
                      .ActivityCategoryController_getOneCategoryList_72bb78?.()
                      .then((res) => {
                        if (res.data) {
                          resolve(res.data);
                        } else {
                          reject();
                        }
                      })
                      .catch(reject);
                  });
                },
              }}
            />

            <VSTableColumn
              dataIndex={['activityTimeInterval']}
              title="周期活动"
              editable={false}
              hideInTable
              valueType="select"
              // search
              order={-6}
              columnKey={'activityTimeIntervalIs'}
              dataSource={[
                { label: '仅一次', value: 'ONCE' },
                { label: '每天', value: 'EVERYDAY' },
                { label: '每周', value: 'WEEKLY' },
                { label: '每月', value: 'MONTHLY' },
              ]}
              fieldProps={{
                placeholder: '请选择活动周期',
              }}
            />

            <VSTableColumn
              dataIndex={['activityName']}
              title="活动名称"
              align="center"
              editable={false}
              valueType="text"
              // search
              order={0}
              width={100}
              columnKey={'activityNameLike'}
              formItemProps={{
                rules: [
                  {
                    message: '活动名称长度不合法',
                    type: 'string',
                    min: 0,
                    max: 1024,
                  },
                ],
              }}
              fieldProps={{
                placeholder: '请输入活动名称',
              }}
            />

            <VSTableColumn
              dataIndex={['activityNature']}
              title="活动性质"
              align="center"
              width={100}
              editable={false}
              valueType="select"
              // search
              order={-1}
              columnKey={'activityNatureIs'}
              dataSource={[
                { label: '村级发起', value: 'VILLAGE_INITIATE' },
                { label: '镇级发起', value: 'TOWN_INITIATE' },
              ]}
              fieldProps={{
                placeholder: '请选择活动性质',
              }}
            />

            <VSTableColumn
              dataIndex={['enrollNum']}
              title="报名人数"
              align="center"
              editable={false}
              valueType="digit"
              width={100}
              formItemProps={{
                rules: [
                  {
                    message: '报名人数的值不合法',
                    type: 'number',
                    min: 0,
                    max: null,
                  },
                ],
              }}
              fieldProps={{}}
              render={(_, data) => {
                // if (typeof data.joinNum !== 'number') {
                //   return data.enrollNum || 0;
                // }
                return `${data.enrollNum || 0} / ${data.totalNum ?? '无限制'}`;
              }}
              sorter={true}
            />

            <VSTableColumn
              dataIndex={['joinNum']}
              title="参加人数"
              align="center"
              editable={false}
              width={100}
              valueType="digit"
              formItemProps={{
                rules: [
                  {
                    message: '参加人数的值不合法',
                    type: 'number',
                    min: 0,
                    max: null,
                  },
                ],
              }}
              render={(_, data) => {
                return data.joinNum || 0;
              }}
              fieldProps={{}}
              sorter={true}
            />

            <VSTableColumn
              dataIndex={['integral']}
              title="积分"
              align="center"
              editable={false}
              valueType="digit"
              width={100}
              formItemProps={{
                rules: [
                  {
                    message: '积分的值不合法',
                    type: 'number',
                    min: 0,
                    max: null,
                  },
                ],
              }}
              render={(_, data) => {
                return data.integral || 0;
              }}
              fieldProps={{}}
              sorter={true}
            />

            <VSTableColumn
              // search
              order={-1}
              hideInTable
              columnKey={'startTimeRangeIn'}
              valueType="dateRange"
              title="开始时间"
              width={120}
              formItemProps={{
                label: '活动时间',
              }}
              searchConfig={{
                transform: (values) => {
                  if (!Array.isArray(values)) return values;
                  return ['startTimeRangeIn'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    {
                      begin: values[0],
                      end: values[1],
                      beginInclude: true,
                      endInclude: true,
                    },
                  );
                },
              }}
              fieldProps={{
                showTime: true,
                placeholder: ['开始时间', '结束时间'],
                separator: '至',
                valueType: 'string',
                format: 'YYYY-MM-DD HH:mm:ss',
              }}
            />

            <VSTableColumn
              dataIndex={['startTime']}
              title="开始时间"
              align="center"
              width={120}
              editable={false}
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
            />

            <VSTableColumn
              dataIndex={['endTime']}
              title="结束时间"
              align="center"
              width={120}
              editable={false}
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
            />

            <VSTableColumn
              dataIndex={['activityStatus']}
              title="状态"
              width={120}
              editable={false}
              valueType="select"
              // search
              order={-3}
              columnKey={'activityStatusIs'}
              formItemProps={{ label: '活动状态' }}
              dataSource={[
                { label: '未开始', value: 'NOT_START' },
                { label: '进行中', value: 'A_IN_PROGRESS' },
                { label: '已结束', value: 'Z_ALREADY_END' },
              ]}
              render={(dom, data) => {
                if (data.activityStatus === 'A_IN_PROGRESS') {
                  return <span style={{ color: 'orange' }}>{dom}</span>;
                }
                return dom;
              }}
              fieldProps={{
                placeholder: '请选择活动状态',
              }}
            />

            <VSTableColumn
              title="操作"
              formItemProps={{ labelCol: 4, wrapperCol: 16 }}
              valueType="option"
              dataIndex="oper"
              width={280}
              fixed={'right'}
            >
              <VSTableAction
                children={(data) => (data.isTopping ? '取消置顶' : '置顶')}
                show={(data) => !data.isHidden}
                linkPage={{
                  updateType: 'reload',
                  updateTable: true,
                  onLinkPageValidate: async (data) => {
                    try {
                      let replace = false;
                      if (!data.isTopping) {
                        const hasTopped =
                          await vsf?.services?.ActivityInfoController_isToppingActivity_ae600d?.(
                            {
                              qto: {
                                from: 0,
                                size: 10,
                                activityTypeIs: activityType,
                              },
                            },
                          );

                        if (hasTopped?.data) {
                          replace = await vsf.confirm(
                            '已存在置顶活动，是否进行替换',
                            '替换',
                          );
                          if (!replace) {
                            return false;
                          }
                        }
                      }
                      const res =
                        await vsf.services.ActivityInfoController_updateActivityToppingStatus_6c6710(
                          { activityId: data.id },
                        );
                      if (res?.code === 200) {
                        vsf.showToast(
                          res.data.isTopping
                            ? `${replace ? '置顶替换成功' : '活动置顶成功'}`
                            : '取消置顶成功',
                          'success',
                        );
                        return true;
                      }
                    } catch (e) {
                      //
                    }
                    vsf.showToast(
                      data.isTopping ? '取消置顶失败' : '活动置顶失败',
                      'error',
                    );
                    return false;
                  },
                }}
              />

              <VSTableAction
                children="编辑"
                show={(data) => !data.isHidden}
                onClick={(e, data) => {
                  vsf.navigateTo(
                    `/activityManage/edit/${data.activityType}/${data.id}`,
                  );
                }}
              />

              <VSTableAction
                children={(data) => (data.isHidden ? '已隐藏' : '隐藏')}
                linkPage={{
                  updateTable: true,
                  onLinkPageValidate: async (data) => {
                    try {
                      const confirmed = await vsf.confirm(
                        '隐藏后该活动将从小程序活动列表中消失\n，且发放积分不可回退，是否确认隐藏？',
                      );
                      if (!confirmed) {
                        return false;
                      }
                      const res =
                        await vsf.services.ActivityInfoController_updateActivityHiddenStatus_4689f5(
                          { activityId: data.id },
                        );
                      if (res?.code === 200) {
                        vsf.showToast('活动隐藏成功', 'success');
                        return true;
                      }
                    } catch (e) {
                      //
                    }
                    vsf.showToast('活动隐藏失败', 'error');
                    return false;
                  },
                  updateType: 'reload',
                }}
                disabled={(data) => data.isHidden}
              />

              <VSTableAction
                children="生成二维码"
                show={(data) =>
                  !data.isHidden &&
                  data.activityType === 'INVOKE_TYPE_ACTIVITY' &&
                  data.activityStatus !== 'Z_ALREADY_END'
                }
                onClick={(e, data) => {
                  Modal.confirm({
                    title: '活动二维码',
                    content: (
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <QRCodeCanvas
                          id="qrcode"
                          // value={`https://yigongyinhang.azalea-tech.com:9981/admin_share_activity_detail?activityId=${data.id}`}
                          value={JSON.stringify({
                            activityId: data.id,
                            userId: data.promoterUser?.id,
                          })}
                        />
                      </div>
                    ),
                    okText: '下载图片',
                    icon: <></>,
                    closable: true,
                    onOk: () => {
                      const canvas = document.getElementById('qrcode');
                      const url = canvas.toDataURL('image/png');
                      const link = document.createElement('a');
                      link.download = '活动二维码.png';
                      link.href = url;
                      link.click();
                    },
                  });
                }}
              />

              <VSTableAction
                children="审核列表"
                show={(data) =>
                  !data.isHidden &&
                  data.activityType === 'UPLOAD_PROOF_TYPE_ACTIVITY' &&
                  data.activityStatus !== 'NOT_START'
                }
                onClick={(e, data) =>
                  vsf.navigateTo(`/activityManage/activityProveList/${data.id}`)
                }
              />

              <VSTableAction
                children="关闭周期"
                linkPage={{
                  updateTable: true,
                  onLinkPageValidate: async (data) => {
                    try {
                      const confirmed = await vsf.confirm(
                        '关闭周期后将不能再次打开\n是否确认关闭',
                      );
                      if (!confirmed) {
                        return false;
                      }
                      const res =
                        await vsf.services.ActivityInfoController_closeActivityCycle_326eb8(
                          { activityId: data.id },
                        );
                      if (res?.code === 200) {
                        vsf.showToast('周期关闭成功', 'success');
                        return true;
                      }
                    } catch (e) {
                      //
                    }
                    vsf.showToast('周期关闭失败', 'error');
                    return false;
                  },
                }}
                show={(data) =>
                  !data.isHidden &&
                  data.activityCycle &&
                  data.activityStatus !== 'Z_ALREADY_END'
                }
              />

              <VSTableAction
                children="再次发起"
                linkPage={{ updateTable: true, onLinkPageValidate: '' }}
                show={(data) =>
                  data.isHidden || data.activityStatus === 'Z_ALREADY_END'
                }
                onClick={(e, data) => {
                  vsf.navigateTo(
                    `/activityManage/add/${data.activityType}/${data.id}`,
                  );
                }}
              />
            </VSTableColumn>
          </VSPromiseTable>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <Pagination
              current={querys.page}
              onChange={(page, pageSize) => {
                setQuerys({
                  ...querys,
                  size: pageSize,
                  from: (page - 1) * pageSize,
                  page,
                });
              }}
              total={total ?? 0}
            />
          </div> */}
        </div>
      </Section>
    </div>
  );
};
export default definePage(ActivityListVp);

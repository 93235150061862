import SnapshotList from '@pages/SnapshotList';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';
const Snapshot = (props) => {
  return (
    <Page title="">
      <SnapshotList />
    </Page>
  );
};
export default definePage(Snapshot);

import ActivityProveVp from '@pages/ActivityProveVp';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';
const ActivityProve = (props) => {
  return (
    <Page>
      <ActivityProveVp id={props.routes.params?.id} />
    </Page>
  );
};
export default definePage(ActivityProve);

import vsf from '@vs/vsf-boot';
import {
  Button,
  compose,
  Input,
  message,
  Select,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import { cloneDeep } from 'lodash';
import React, { useRef, useState } from 'react';

export type SelectUserProps = {
  /**
   * 默认值
   */
  defaultValue?: MallVoucherServiceBtoUpdateAssociationMerchantBto[];
  /**
   * 值
   */
  value?: MallVoucherServiceBtoUpdateAssociationMerchantBto[];

  mallVoucherId?: any;
  /**
   * 值变化回调
   */
  onChange?: (
    value?: MallVoucherServiceBtoUpdateAssociationMerchantBto[],
  ) => void;
};

/**
 * SelectUser
 */
const SelectUser = (props: SelectUserProps) => {
  const { defaultValue, value: valueProp, mallVoucherId, onChange } = props;

  const [inputValue, setInputValue] = useState('');
  const [merchant, setMerchant] =
    useState<ComVsUserAccountEntranceWebVoAccountDetailVo>();

  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  const handelSearch = async () => {
    try {
      const response =
        await vsf.services.AccountInfoController_getAccountDetailByIdNum_78688d(
          {
            idNum: inputValue,
          },
        );
      if (response && response.data) {
        response.data?.userInfoDto.isMerchant
          ? setMerchant(response.data)
          : message.error('该用户不是商家');
      } else {
        message.error('用户在系统中不存在');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelSelectMerchant = () => {
    const ids = value?.map((item) => item.id);
    if (ids?.includes(merchant?.id)) {
      message.error('用户已经绑定此商券');
      setMerchant(undefined);
      setInputValue('');
    } else {
      const tempMerchant: MallVoucherServiceBtoUpdateAssociationMerchantBto = {
        id: merchant?.id,
        identityNum: merchant?.userInfoDto?.idNum,
        identityName: merchant?.userInfoDto?.name,
        mallVoucherId,
        identityPhone: merchant?.userInfoDto?.phone,
      };
      setValue([...(value ?? []), tempMerchant]);
      setMerchant(undefined);
      setInputValue('');
    }
  };

  const handelMuitClick = (id) => {
    setValue(cloneDeep(value)?.filter((item) => item.id !== id));
  };

  /** 编写组件逻辑 */
  return (
    <div>
      <div className="aic">
        <Input
          value={inputValue}
          style={{ width: 200 }}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Button onClick={handelSearch}>搜索</Button>
      </div>
      {merchant && (
        <Select
          defaultOpen={true}
          defaultActiveFirstOption={true}
          style={{ width: 265 }}
          value={merchant.id}
          dataSource={
            merchant?.id
              ? [
                  {
                    label: `${merchant?.userInfoDto?.name ?? ''}，${
                      merchant?.userInfoDto?.phone ?? ''
                    }`,
                    value: merchant.id,
                  },
                ]
              : []
          }
          onSelect={handelSelectMerchant}
        />
      )}
      {(value ?? [])?.map((item) => (
        <div className="aic" key={item.id} style={{ height: '100%' }}>
          <Input
            style={{ width: 265, marginTop: 10 }}
            value={`${item?.identityName ?? ''}，${item?.identityPhone ?? ''}`}
            readOnly
          />
          <div
            className="aic"
            style={{ marginLeft: 10, height: '100%', marginTop: 10 }}
          >
            <div
              style={{
                fontSize: 20,
                fontWeight: 'bold',
                width: 25,
                height: 25,
                borderRadius: '50%',
                border: '1px solid #222',
                marginLeft: 10,
                textAlign: 'center',
                lineHeight: 0.9,
              }}
              onClick={() => handelMuitClick(item?.id)}
            >
              -
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
SelectUser.displayName = 'SelectUser';

export default compose(
  withField<string>({
    name: 'SelectUser',
  }),
  withPreview<SelectUserProps>({
    renderPreview: (props) => {
      const { value } = props;

      /** 返回预览模式下的dom */
      return (
        <>
          {(value ?? [])?.map((item) => (
            <div className="aic" key={item.id} style={{ height: '100%' }}>
              {`${item?.identityName ?? ''}，${item?.identityPhone ?? ''}`}
            </div>
          ))}
        </>
      );
    },
  }),
)(SelectUser);

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Image,
  Input,
  message,
  Modal,
  Section,
  VSControlledForm,
  VSDataProvider,
  VSFormItem,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import NOTREVIEW from '@/assets/images/not_review.png';
import PASS from '@/assets/images/review_pass.png';
import REJECT from '@/assets/images/review_reject.png';
import { ReviewStatusEnum } from '@/config';

const SnapshotDetail = (props) => {
  const [detail, setDetail] = useState();

  const { id } = props;

  const statusMap = {
    NOT_REVIEW: '待审核',
    REVIEW_PASS: '审核通过',
    REVIEW_REJECT: '审核拒绝',
  };
  const statusColorMap = {
    NOT_REVIEW: '',
    REVIEW_PASS: 'green',
    REVIEW_REJECT: 'red',
  };

  const imgs = JSON.parse(detail?.contentImg ?? '[]');

  const handlePass = () => {
    vsf.services
      .CommunityInfoController_reviewPassCommunity_8ba691({
        communityId: id,
      })
      .then((res) => {
        if (res?.code === 200) {
          message.success(`已通过`);
          vsf.refs.snapshotTable?.reload();
          vsf.closeModal('snapshotDetailModal');
        } else {
          // message.error(`操作失败: ` + res?.message ?? '请求失败');
        }
      });
  };

  const handleReject = () => {
    const reason =
      vsf.refs.rejectSnapshotReasonInput?.resizableTextArea?.textArea?.value ??
      '';
    if (!reason) return message.error('请输入拒绝原因');

    vsf.services
      .CommunityInfoController_reviewRejectCommunity_06a4f3({
        btoParam: {
          communityId: id,
          rejectReason: reason,
        },
      })
      .then((res) => {
        if (res?.code === 200) {
          message.success(`操作成功`);
          vsf.refs.snapshotTable?.reload();
          vsf.closeModal('snapshotDetailModal');
        } else {
          // message.error(`操作失败: ` + res?.message ?? '请求失败');
        }
      });
  };

  return (
    <Section title="">
      <VSDataProvider
        params={{ communityId: id }}
        service={
          vsf.services.CommunityInfoController_getCommunityInfoDetail_013e6a
        }
        onDataLoaded={(_value) => setDetail({ ..._value })}
      />
      <div style={{ paddingTop: 40, display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1 }}>
          <div>
            <span>图片: </span>
            {imgs.map((url) => (
              <Image key={url} src={url} width={150} style={{ padding: 10 }} />
            ))}
          </div>
          <VSControlledForm
            id="snapshotDetailForm"
            readonly={true}
            value={detail}
            layout="horizontal"
          >
            <VSFormItem
              name={['contentDescr']}
              label="描述"
              valueType="text"
              fieldProps={{}}
            />
            <VSFormItem
              name={['pubTime']}
              label="提交时间"
              valueType="date"
              transform={(v) => {
                return ['createdAt'].reduceRight(
                  (acc, curr) => ({ [curr]: acc }),
                  v,
                );
              }}
              fieldProps={{
                format: 'YYYY-MM-DD HH:mm:ss',
                valueType: 'string',
              }}
            />
            <VSFormItem
              name={['communityType']}
              label="提交人类型"
              valueType="select"
              dataSource={[
                { label: '个人', value: 'USER' },
                { label: '团队', value: 'TEAM' },
              ]}
              fieldProps={{}}
            />
            {detail?.communityStatus !== 'NOT_REVIEW' && (
              <>
                <VSFormItem
                  name={['operateUser', 'nickname']}
                  label="操作人"
                  valueType="text"
                  fieldProps={{}}
                  convertValue={(data) => {
                    console.log(data);
                    return data?.operateUser?.nickname;
                  }}
                />
                <VSFormItem
                  name={['operateTime']}
                  label="操作时间"
                  valueType="date"
                  transform={(v) => {
                    return ['operateTime'].reduceRight(
                      (acc, curr) => ({ [curr]: acc }),
                      v,
                    );
                  }}
                  fieldProps={{
                    format: 'YYYY-MM-DD HH:mm:ss',
                    valueType: 'string',
                  }}
                />
                {detail?.communityStatus === 'REVIEW_REJECT' && (
                  <VSFormItem
                    name={['reject', 'rejectReason']}
                    label="拒绝原因"
                    valueType="text"
                    fieldProps={{}}
                  />
                )}
              </>
            )}
          </VSControlledForm>
        </div>
        <div
          style={{
            // border: '1px solid',
            width: 100,
            height: 100,
            borderRadius: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: statusColorMap[detail?.communityStatus],
            borderColor: statusColorMap[detail?.communityStatus],
          }}
        >
          <img
            className="activity__modal__status__img"
            src={
              detail?.communityStatus === ReviewStatusEnum.NOT_REVIEW
                ? NOTREVIEW
                : detail?.communityStatus === ReviewStatusEnum.REVIEW_PASS
                ? PASS
                : detail?.communityStatus === ReviewStatusEnum.REVIEW_REJECT
                ? REJECT
                : ''
            }
            alt=""
          />
          {/* <span>{statusMap[detail?.communityStatus] ?? '未知'}</span> */}
        </div>
      </div>
      {detail?.communityStatus === 'NOT_REVIEW' && (
        <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
          <Button type="primary" onClick={handlePass}>
            通过
          </Button>
          <Button
            // type="primary"
            onClick={() => vsf.openModal('rejectSnapshotModal')}
          >
            拒绝
          </Button>
        </div>
      )}
      {detail?.communityStatus !== 'NOT_REVIEW' && (
        <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
          <Button
            type="primary"
            onClick={() => vsf.closeModal('snapshotDetailModal')}
          >
            返回
          </Button>
        </div>
      )}
      <Modal
        id="rejectSnapshotModal"
        title="不通过原因"
        onOk={handleReject}
        okText="确定"
        cancelText="取消"
      >
        <Input.TextArea
          showCount
          maxLength={250}
          required
          id="rejectSnapshotReasonInput"
          placeholder="请输入不通过原因"
        />
      </Modal>
    </Section>
  );
};
export default definePage(SnapshotDetail);

import MallVoucherEditor from '@pages/MallVoucherEditor';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';
const EditMallVoucher = (props) => {
  return (
    <Page>
      <MallVoucherEditor isCreate={false} value={props.routes.params} />
    </Page>
  );
};
export default definePage(EditMallVoucher);

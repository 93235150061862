import MerchantListVp from '@pages/MerchantListVp';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';
const TeamList = (props) => {
  return (
    <Page title="">
      <MerchantListVp />
    </Page>
  );
};
export default definePage(TeamList);

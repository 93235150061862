import ScoreConfigVp from '@pages/ScoreConfigVp';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';
const ScoreConfig = (props) => {
  return (
    <Page>
      <ScoreConfigVp />
    </Page>
  );
};
export default definePage(ScoreConfig);

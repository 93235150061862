import ActivityEditor from '@pages/ActivityEditor';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';
const EditAcitivity = (props) => {
  return (
    <Page>
      <ActivityEditor isCreate={false} value={props.routes.params} />
    </Page>
  );
};
export default definePage(EditAcitivity);

import Root from '@/layouts';
import Login from '@/pages/Login';
import MiddlePage from '@/pages/middle_page';

import pageRoutes from './routes';

const routes = [
  {
    path: '/Login',
    exact: true,
    component: Login,
  },
  {
    path: '/MiddlePage',
    exact: true,
    component: MiddlePage,
  },
  {
    path: '/',
    component: Root,
    routes: pageRoutes,
  },
];

export default routes;

import ActivityList from '@pages/ActivityList';
import ActivityManageRoot from '@pages/ActivityManageRoot';
import ActivityProve from '@pages/ActivityProve';
import BannerManage from '@pages/BannerManage';
import CreateActivity from '@pages/CreateActivity';
import CreateMallVoucher from '@pages/CreateMallVoucher';
import EditActivity from '@pages/EditActivity';
import EditMallVoucher from '@pages/EditMallVoucher';
// import TeamList from '@pages/TeamList';
// import TeamList from '@pages/TeamList';
// import TeamList from '@pages/TeamList';
import Login from '@pages/Login';
import MallVoucherList from '@pages/MallVoucherList';
import MallVoucherManageRoot from '@pages/MallVoucherManageRoot';
import MerchantList from '@pages/MerchantList';
import PersonList from '@pages/PersonList';
import ScoreConfig from '@pages/ScoreConfig';
import Snapshot from '@pages/Snapshot';
import TeamDetail from '@pages/TeamDetail';
import TeamList from '@pages/TeamList';
import TeamManageRoot from '@pages/TeamManageRoot';
import React from 'react';

import chooseMerchant from '@/assets/images/menu_icon/chooseMerchant.png';
import choosePerson from '@/assets/images/menu_icon/choosePerson.png';
import defaultMerchant from '@/assets/images/menu_icon/defaultMerchant.png';
import defaultPerson from '@/assets/images/menu_icon/defaultPerson.png';
import FiveIcon from '@/assets/images/menu_icon/icon_five.png';
import FiveIconSelect from '@/assets/images/menu_icon/icon_five_select.png';
import FourIcon from '@/assets/images/menu_icon/icon_four.png';
import FourIconSelect from '@/assets/images/menu_icon/icon_four_select.png';
import OneIcon from '@/assets/images/menu_icon/icon_one.png';
import sixIcon from '@/assets/images/menu_icon/icon_one.png';
import ThreeIcon from '@/assets/images/menu_icon/icon_three.png';
import ThreeIconSelect from '@/assets/images/menu_icon/icon_three_select.png';
import TwoIcon from '@/assets/images/menu_icon/icon_two.png';
import TwoIconSelect from '@/assets/images/menu_icon/icon_two_select.png';
import OneIconSelect from '@/assets/images/menu_icon/iocn_one_select.png';
import SixIconSelect from '@/assets/images/menu_icon/iocn_one_select.png';
import Cockpit from '@/pages/Cockpit';

const routes = [
  {
    path: '/',
    exact: true,
    component: Cockpit,
  },
  {
    path: '/Login',
    exact: true,
    component: Login,
  },
  {
    path: '/cockpit',
    exact: true,
    component: Cockpit,
    label: '义工数据面板',
    icon: OneIcon,
    selectIcon: OneIconSelect,
  },
  {
    path: '/teamManage',
    component: TeamManageRoot,
    label: '团队管理',
    icon: TwoIcon,
    selectIcon: TwoIconSelect,
    routes: [
      {
        path: '/teamManage/team/:id',
        exact: true,
        component: TeamDetail,
        // label: '团队详情',
      },
      {
        path: '/teamManage/teamList',
        exact: true,
        component: TeamList,
        label: '团队列表',
      },
    ],
  },
  {
    path: '/bannerManage',
    exact: true,
    component: BannerManage,
    label: 'Banner管理',
    icon: ThreeIcon,
    selectIcon: ThreeIconSelect,
  },
  {
    path: '/randomClap',
    exact: true,
    component: Snapshot,
    label: '随手拍管理',
    icon: FourIcon,
    selectIcon: FourIconSelect,
  },
  {
    path: '/personList',
    exact: true,
    component: PersonList,
    label: '人员管理',
    icon: defaultPerson,
    selectIcon: choosePerson,
  },
  {
    path: '/merchantList',
    exact: true,
    component: MerchantList,
    label: '商家管理',
    icon: defaultMerchant,
    selectIcon: chooseMerchant,
  },
  {
    path: '/activityManage',
    component: ActivityManageRoot,
    label: '活动管理',
    icon: FiveIcon,
    selectIcon: FiveIconSelect,
    routes: [
      {
        path: '/activityManage/activityList',
        exact: true,
        component: ActivityList,
        label: '活动列表',
      },
      {
        path: '/activityManage/edit/:type/:id',
        exact: true,
        component: EditActivity,
      },
      {
        path: '/activityManage/add/:type/:id',
        exact: true,
        component: CreateActivity,
      },
      {
        path: '/activityManage/scoreConfig',
        exact: true,
        component: ScoreConfig,
        label: '赋分规则',
      },
      {
        path: '/activityManage/activityProveList/:id',
        exact: true,
        component: ActivityProve,
      },
    ],
  },
  {
    path: '/activityManage2',
    component: ActivityManageRoot,
    label: '活动管理',
    icon: FiveIcon,
    selectIcon: FiveIconSelect,
    routes: [
      {
        path: '/activityManage2/activityList',
        exact: true,
        component: ActivityList,
        label: '活动列表',
      },
      {
        path: '/activityManage2/edit/:type/:id',
        exact: true,
        component: EditActivity,
      },
      {
        path: '/activityManage2/add/:type/:id',
        exact: true,
        component: CreateActivity,
      },
      {
        path: '/activityManage2/activityProveList/:id',
        exact: true,
        component: ActivityProve,
      },
    ],
  },
  {
    path: '/mallVoucherManage',
    component: MallVoucherManageRoot,
    label: '商券管理',
    icon: sixIcon,
    selectIcon: SixIconSelect,
    routes: [
      {
        path: '/mallVoucherManage/mallVoucherList',
        exact: true,
        component: MallVoucherList,
        label: '商券列表',
      },
      {
        path: '/mallVoucherManage/add',
        exact: true,
        component: CreateMallVoucher,
      },
      {
        path: '/mallVoucherManage/edit/:id',
        exact: true,
        component: EditMallVoucher,
      },
    ],
  },
];

export default routes;

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useCallback, useRef } from 'react';

import getOssPolicy from '@/utils/getOssPolicy';
const EditTeam = (props) => {
  const { info, isInfoEdit } = props;
  const formRef = useRef(null);
  return (
    <Section title="">
      <VSControlledForm
        ref={formRef}
        id="editTeamDetailForm"
        readonly={props.readonly}
        value={info}
        layout="horizontal"
      >
        <VSFormItem
          name={['avatar']}
          label="团队头像"
          valueType="userAvatar"
          fieldProps={{
            getOssPolicy,
          }}
          rules={[{ required: isInfoEdit, message: '请上传团队头像' }]}
        />
        <VSFormLayout columnCount={2}>
          <VSFormItem
            name={['name']}
            label="团队名称"
            valueType="text"
            rules={[{ required: isInfoEdit }]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['establishTime']}
            label="创建时间"
            valueType="date"
            transform={(v) => {
              return ['establishTime'].reduceRight(
                (acc, curr) => ({ [curr]: acc }),
                `${v} 00:00:00`,
              );
            }}
            fieldProps={{
              format: 'YYYY-MM-DD',
              valueType: 'string',
              width: 285,
            }}
            rules={[{ required: isInfoEdit }]}
          />
          <VSFormItem
            name={['teamType']}
            label="团队类型"
            valueType="select"
            dataSource={[
              { label: '社区服务', value: 'COMMUNITY_SERVICE' },
              { label: '社区安全', value: 'COMMUNITY_SAFETY' },
              { label: '社区文体', value: 'COMMUNITY_STYLE' },
              { label: '其他', value: 'OTHER' },
            ]}
            fieldProps={{}}
            rules={[{ required: isInfoEdit }]}
          />
          {/* <VSFormItem
            name={['registerStatus']}
            label="民政部门是否已登记"
            valueType="switch"
            fieldProps={{}}
            rules={[{ required: isInfoEdit }]}
          /> */}

          <VSFormItem
            name={['contactName']}
            label="联系人"
            valueType="text"
            fieldProps={{}}
            rules={[{ required: isInfoEdit }]}
          />
          <VSFormItem
            name={['registerStatus']}
            label="民政部门是否已登记"
            valueType="select"
            dataSource={[
              { label: '是', value: true },
              { label: '否', value: false },
            ]}
            fieldProps={{}}
            rules={[{ required: isInfoEdit }]}
          />
          {/* <VSFormItem
            name={['teamIntegralDto', 'balanceAmount']}
            label="义工分余额"
            fieldProps={{ disabled: true, placeholder: '0' }}
            valueType="digit"
          /> */}

          <VSFormItem
            name={['contactPhone']}
            label="联系人手机号"
            valueType="text"
            fieldProps={{}}
            rules={[{ required: isInfoEdit }]}
          />
          {/* 
          <VSFormItem
            name={['teamIntegralDto', 'totalIncomeAmount']}
            label="历史总义工分"
            fieldProps={{ disabled: true, placeholder: '0' }}
            valueType="digit"
          /> */}

          <VSFormItem
            name={['address']}
            label="所在地区"
            valueType="address"
            fieldProps={{}}
            rules={[{ required: isInfoEdit }]}
          />
          <VSFormItem
            name={['contactIdentity']}
            label="联系人身份证号"
            valueType="text"
            fieldProps={{}}
            rules={[{ required: isInfoEdit }]}
          />

          <VSFormItem
            name={['qualification']}
            label="团队证明"
            valueType="fileUpload"
            fieldProps={{
              type: 'picture',
              getOssPolicy,
            }}
            rules={[{ required: isInfoEdit }]}
            convertValue={(v) => {
              if (!v || Array.isArray(v)) {
                return v;
              }
              const urlArr = JSON.parse(v ?? '[]');
              return (
                urlArr?.map?.((i) =>
                  typeof i === 'string' ? { url: i } : i,
                ) ?? []
              );
            }}
            transform={(v) => {
              return {
                qualification: JSON.stringify(v?.map?.((vv) => vv.url)) ?? '[]',
              };
            }}
          />
          <div className="antd-formitem-contnent-border">
            <VSFormItem
              name={['descr']}
              label="简介"
              valueType="textarea"
              fieldProps={{
                placeholder: '请输入简介',
                style: { minHeight: 100 },
              }}
              rules={[{ required: isInfoEdit }]}
            />
          </div>
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(EditTeam);

import './ActivityEditor.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Breadcrumb,
  Button,
  Column,
  Columns,
  Divider,
  Modal,
  Radio,
  Section,
  Select,
  Selector,
  Space,
  Tag,
  Todo,
  VSForm,
  VSFormDependency,
  VSFormFooter,
  VSFormFooterSubmit,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import ImgCrop from 'antd-img-crop';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import getOssPolicy from '@/utils/getOssPolicy';

const Tags = (props) => {
  const { value = [], tagRender, titleRender, getKey } = props;
  const title = titleRender(value);
  return (
    <Space
      direction="vertical"
      style={{ paddingTop: value.length > 0 ? 5 : 0 }}
    >
      {title}
      <Space size={[0, 8]} wrap style={{ maxWidth: 500 }}>
        {value.map((v) => {
          return <Tag key={getKey(v)}>{tagRender(v)}</Tag>;
        })}
      </Space>
    </Space>
  );
};

const options = [
  { label: '仅一次', value: 'ONCE', key: 'ONCE' },
  { label: '每天', value: 'EVERYDAY', key: 'EVERYDAY' },
  { label: '每周', value: 'WEEKLY', key: 'WEEKLY' },
  { label: '每月', value: 'MONTHLY', key: 'MONTHLY' },
];
const week = [
  { label: '星期一', value: 'WEEK' },
  { label: '星期二', value: 'TUESDAY' },
  { label: '星期三', value: 'WEDNESDAY' },
  { label: '星期四', value: 'THURSDAY' },
  { label: '星期五', value: 'FRIDAY' },
  { label: '星期六', value: 'SATURDAY' },
  { label: '星期日', value: 'SUNDAY' },
];
const days = [
  { label: '1号', value: '1' },
  { label: '2号', value: '2' },
  { label: '3号', value: '3' },
  { label: '4号', value: '4' },
  { label: '5号', value: '5' },
  { label: '6号', value: '6' },
  { label: '7号', value: '7' },
  { label: '8号', value: '8' },
  { label: '9号', value: '9' },
  { label: '10号', value: '10' },
  { label: '11号', value: '11' },
  { label: '12号', value: '12' },
  { label: '13号', value: '13' },
  { label: '14号', value: '14' },
  { label: '15号', value: '15' },
  { label: '16号', value: '16' },
  { label: '17号', value: '17' },
  { label: '18号', value: '18' },
  { label: '19号', value: '19' },
  { label: '20号', value: '20' },
  { label: '21号', value: '21' },
  { label: '22号', value: '22' },
  { label: '23号', value: '23' },
  { label: '24号', value: '24' },
  { label: '25号', value: '25' },
  { label: '26号', value: '26' },
  { label: '27号', value: '27' },
  { label: '28号', value: '28' },
  { label: '29号', value: '29' },
  { label: '30号', value: '30' },
  { label: '31号', value: '31' },
];

const ActivityEditor = (props) => {
  const { isCreate, value } = props;
  const { id, type } = value || {};
  const formRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [integralCode, setIntegralCode] = useState(0);
  const [timeValue, setTimeValue] = useState('ONCE');
  const [startWeek, setStartWeek] = useState('WEEK');
  const [endWeek, setEndWeek] = useState('WEEK');
  const [startDate, setStartDate] = useState('1');
  const [endDate, setEndDate] = useState('1');
  const [activityStatus, setActivityStatus] = useState();
  const [timeId, setTimeId] = useState();
  const [data, setData] = useState();
  const formDataRef = useRef();

  useEffect(() => {
    if (isCreate) return;
    vsf?.services
      ?.ActivityInfoController_getActivityInfoDetail_60d54e?.({
        activityId: Number(props?.value?.id),
      })
      .then((res) => {
        if (res.code === 200) {
          setIntegralCode(res?.data.integral);
          setTimeValue(res.data.activityCycleInfo?.activityTimeInterval);
          setStartWeek(res.data.activityCycleInfo?.startWeek);
          setEndWeek(res.data.activityCycleInfo?.endWeek);
          setStartDate(res.data.activityCycleInfo?.startDate);
          setEndDate(res.data.activityCycleInfo?.endDate);
          setActivityStatus(res.data.activityStatus);
          setActivityStatus(res.data.activityStatus);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fromData = (data) => {
    const {
      activityCategoryOneName,
      activityCategoryOneId,
      activityCategoryTwoName,
      activityCategoryTwoId,
      activityCategoryThreeName,
      activityCategoryThreeId,
      ...rest
    } = data;

    const categorys = [];
    if (activityCategoryOneId) {
      categorys.push({
        id: activityCategoryOneId,
        categoryName: activityCategoryOneName,
      });
    }
    if (activityCategoryTwoId) {
      categorys.push({
        id: activityCategoryTwoId,
        categoryName: activityCategoryTwoName,
      });
    }
    if (activityCategoryThreeId) {
      categorys.push({
        id: activityCategoryThreeId,
        categoryName: activityCategoryThreeName,
      });
    }

    return {
      ...rest,
      categorys,
    };
  };

  const toData = (data) => {
    const { categorys, ...rest } = data;
    const activityCategoryOneId = categorys?.[0]?.id;
    const activityCategoryOneName = categorys?.[0]?.categoryName;
    const activityCategoryTwoId = categorys?.[1]?.id;
    const activityCategoryTwoName = categorys?.[1]?.categoryName;
    const activityCategoryThreeId = categorys?.[2]?.id;
    const activityCategoryThreeName = categorys?.[2]?.categoryName;
    return {
      ...rest,
      activityCategoryOneId,
      activityCategoryOneName,
      activityCategoryTwoId,
      activityCategoryTwoName,
      activityCategoryThreeId,
      activityCategoryThreeName,
    };
  };

  const onFetch = useCallback(async (params) => {
    if (!params?.activityId || params?.activityId < 0) {
      setLoading(false);
      return {
        ditu: {
          effectiveRadius: 50,
          clockScope: false,
        },
      };
    }
    try {
      const res =
        await vsf?.services?.ActivityInfoController_getActivityInfoDetail_60d54e?.(
          { activityId: params?.activityId },
        );
      setTimeId(res?.data?.activityCycleInfo?.id);
      setData(res?.data);
      if (res?.data) {
        if (isCreate) {
          // 再次创建的活动，剔除id、状态和时间，周期设置为仅一次
          const {
            id,
            startWeek,
            endWeek,
            startDate,
            endDate,
            startTime,
            endTime,
            activityStatus,
            isHidden,
            isTopping,
            clockScope,
            effectiveRadius,
            clockAddress,
            longitude,
            latitude,
            mapName,
            ...rest
          } = res.data;
          setLoading(false);
          return fromData({
            ...rest,
            activityTimeInterval: 'ONCE',
            ditu: {
              latitude,
              longitude,
              clockAddress,
              effectiveRadius: effectiveRadius === null ? 50 : effectiveRadius,
              clockScope: clockScope === null ? false : clockScope,
              mapName,
            },
          });
        }
      } else {
        vsf.showToast('获取活动详情失败', 'error');
      }
      setLoading(false);
      return fromData({
        ...res?.data,
        ditu: {
          latitude: res?.data?.latitude,
          longitude: res?.data?.longitude,
          clockAddress: res?.data?.clockAddress,
          effectiveRadius:
            res?.data?.effectiveRadius === null
              ? 50
              : res?.data?.effectiveRadius,
          mapName: res?.data?.mapName,
          clockScope:
            res?.data?.clockScope === null ? false : res?.data?.clockScope,
        },
        date: [
          +new Date(res.data?.activityCycleInfo?.startTime),
          +new Date(res.data?.activityCycleInfo?.endTime),
        ],
        moreTime: [res.data?.startTime, res.data?.endTime],
        startHourMinute: res.data?.activityCycleInfo?.startHourMinute,
        endHourMinute: res.data?.activityCycleInfo?.endHourMinute,
        startWeek: res.data?.activityCycleInfo?.startWeek,
        endWeek: res.data?.activityCycleInfo?.endWeek,
        startDate: res.data?.activityCycleInfo?.startDate,
        endDate: res.data?.activityCycleInfo?.endDate,
      });
    } catch (error) {
      vsf.showToast('获取活动详情失败', 'error');
    }
    setLoading(false);
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    setTimeValue(e);
  };

  const formLayoutProps = {
    columnCount: 2,
    labelCol: { span: 8 },
  };
  const secondItemStyle = {
    position: 'relative',
    left: '-55%',
  };

  const renderTime = ({ activityTimeInterval = timeValue }) => {
    return (
      <div
        className="select_time"
        style={{
          minWidth:
            timeValue === 'WEEKLY' || timeValue === 'MONTHLY'
              ? '650px'
              : '400px',
        }}
      >
        {activityTimeInterval === 'ONCE' && (
          <div>
            <div style={{ display: 'flex' }}>
              <VSFormItem
                style={{
                  marginRight: '10px',
                }}
                name={['startTime']}
                label="开始时间"
                valueType="date"
                transform={(v) => {
                  return ['startTime'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    v,
                  );
                }}
                fieldProps={{
                  format: 'YYYY-MM-DD',
                  valueType: 'string',
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择活动日期',
                  },
                ]}
              />
              <VSFormItem
                name={['startHourMinute']}
                valueType="time"
                transform={(v) => {
                  return ['startHourMinute'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    v,
                  );
                }}
                fieldProps={{
                  format: 'HH:mm',
                  valueType: 'string',
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择活动时间',
                  },
                ]}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <VSFormItem
                style={{ marginRight: '10px' }}
                name={['endTime']}
                label="结束时间"
                valueType="date"
                transform={(v) => {
                  return ['endTime'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    v,
                  );
                }}
                fieldProps={{
                  format: 'YYYY-MM-DD',
                  valueType: 'string',
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择活动日期',
                  },
                ]}
              />
              <VSFormItem
                name={['endHourMinute']}
                valueType="time"
                transform={(v) => {
                  return ['endHourMinute'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    v,
                  );
                }}
                fieldProps={{
                  format: 'HH:mm',
                  valueType: 'string',
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择活动时间',
                  },
                ]}
              />
            </div>
          </div>
        )}
        {activityTimeInterval === 'EVERYDAY' && (
          <div>
            <div style={{ display: 'flex' }}>
              <VSFormItem
                name={['date']}
                label="日期区间"
                valueType="dateRange"
                transform={(v) => {
                  return ['date'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    {
                      startTime: dayjs(v[0]).format('YYYY-MM-DD'),
                      endTime: dayjs(v[1]).format('YYYY-MM-DD'),
                    },
                  );
                }}
                fieldProps={{
                  format: 'YYYY-MM-DD',
                  valueType: 'string',
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择活动日期',
                  },
                ]}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <VSFormItem
                style={{ marginRight: '10px' }}
                name={['startHourMinute']}
                label="时间区间"
                valueType="time"
                transform={(v) => {
                  return ['startHourMinute'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    v,
                  );
                }}
                fieldProps={{
                  format: 'HH:mm',
                  valueType: 'string',
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择活动时间',
                  },
                ]}
              />
              <div style={{ margin: '0 10px', color: '#ccc' }}>————</div>
              <VSFormItem
                name={['endHourMinute']}
                valueType="time"
                transform={(v) => {
                  return ['endHourMinute'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    v,
                  );
                }}
                fieldProps={{
                  format: 'HH:mm',
                  valueType: 'string',
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择活动时间',
                  },
                ]}
              />
            </div>
          </div>
        )}
        {activityTimeInterval === 'WEEKLY' && (
          <div>
            <div style={{ display: 'flex' }}>
              <VSFormItem
                name={['date']}
                label="日期区间"
                valueType="dateRange"
                transform={(v) => {
                  return ['date'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    {
                      startTime: dayjs(v[0]).format('YYYY-MM-DD'),
                      endTime: dayjs(v[1]).format('YYYY-MM-DD'),
                    },
                  );
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择活动日期',
                  },
                ]}
                fieldProps={{
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                width: '600px',
                height: '40px',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  flexShrink: 0,
                  fontSize: '14px',
                }}
              >
                星期选择：
              </div>
              <Select
                style={{ marginRight: '5px' }}
                fieldProps={{
                  size: 'small',
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                  fieldNames: {
                    fileList: {},
                  },
                }}
                value={startWeek}
                onChange={(v) => {
                  setStartWeek(v);
                }}
                dataSource={week}
              />
              <VSFormItem
                name={['startHourMinute']}
                valueType="time"
                style={{ alignSelf: 'flex-start', marginTop: '5px' }}
                transform={(v) => {
                  return ['startHourMinute'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    v,
                  );
                }}
                fieldProps={{
                  format: 'HH:mm',
                  valueType: 'string',
                  width: 120,
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择活动时间',
                  },
                ]}
              />
              <div style={{ margin: '0 10px', color: '#ccc' }}>————</div>

              <Select
                fieldProps={{
                  size: 'small',
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                  fieldNames: {
                    fileList: {},
                  },
                }}
                value={endWeek}
                style={{ marginRight: '5px' }}
                dataSource={week}
                onChange={(v) => {
                  setEndWeek(v);
                }}
              />
              <VSFormItem
                name={['endHourMinute']}
                valueType="time"
                style={{ alignSelf: 'flex-start', marginTop: '5px' }}
                transform={(v) => {
                  return ['endHourMinute'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    v,
                  );
                }}
                fieldProps={{
                  format: 'HH:mm',
                  valueType: 'string',
                  width: 120,
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择活动时间',
                  },
                ]}
              />
            </div>
          </div>
        )}
        {activityTimeInterval === 'MONTHLY' && (
          <div>
            <div style={{ display: 'flex' }}>
              <VSFormItem
                name={['date']}
                label="日期区间"
                valueType="dateRange"
                transform={(v) => {
                  return ['date'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    {
                      startTime: dayjs(v[0]).format('YYYY-MM-DD'),
                      endTime: dayjs(v[1]).format('YYYY-MM-DD'),
                    },
                  );
                }}
                fieldProps={{
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择活动日期',
                  },
                ]}
              />
            </div>
            <div
              style={{
                display: 'flex',
                width: '600px',
                height: '40px',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  flexShrink: 0,
                  fontSize: '14px',
                }}
              >
                日期选择：
              </div>
              <Select
                style={{ marginRight: '5px' }}
                value={startDate}
                fieldProps={{
                  size: 'small',
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                  fieldNames: {
                    fileList: {},
                  },
                }}
                onChange={(v) => {
                  setStartDate(v);
                }}
                dataSource={days}
                disabled={
                  activityStatus === 'A_IN_PROGRESS' ||
                  activityStatus === 'Z_ALREADY_END'
                }
              />
              <VSFormItem
                name={['startHourMinute']}
                valueType="time"
                style={{ alignSelf: 'flex-start', marginTop: '5px' }}
                transform={(v) => {
                  return ['startHourMinute'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    v,
                  );
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择星期',
                  },
                ]}
                fieldProps={{
                  format: 'HH:mm',
                  valueType: 'string',
                  width: 120,
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
              />
              <div style={{ margin: '0 10px', color: '#ccc' }}>————</div>
              <Select
                value={endDate}
                fieldProps={{
                  size: 'small',
                  fieldNames: {
                    fileList: {},
                  },
                }}
                disabled={
                  activityStatus === 'A_IN_PROGRESS' ||
                  activityStatus === 'Z_ALREADY_END'
                }
                onChange={(v) => {
                  setEndDate(v);
                }}
                style={{ marginRight: '5px' }}
                dataSource={days}
              />
              <VSFormItem
                name={['endHourMinute']}
                valueType="time"
                style={{ alignSelf: 'flex-start', marginTop: '5px' }}
                transform={(v) => {
                  return ['endHourMinute'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    v,
                  );
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择星期',
                  },
                ]}
                fieldProps={{
                  format: 'HH:mm',
                  valueType: 'string',
                  width: 120,
                  disabled:
                    activityStatus === 'A_IN_PROGRESS' ||
                    activityStatus === 'Z_ALREADY_END',
                }}
              />
            </div>
          </div>
        )}
        {!activityTimeInterval && (
          <div>
            <div style={{ display: 'flex' }}>
              <VSFormItem
                disabled={true}
                name={['moreTime']}
                label="日期区间"
                valueType="dateRange"
                fieldProps={{
                  disabled: true,
                  showTime: true,
                  format: 'YYYY-MM-DD HH:ss',
                  valueType: 'string',
                }}
                convertValue={(v) => {
                  if (v?.begin) {
                    return [
                      dayjs(v?.begin).format('YYYY-MM-DD HH:ss'),
                      dayjs(v?.end).format('YYYY-MM-DD HH:ss'),
                    ];
                  }
                  return v;
                }}
                transform={(v) => {
                  return ['date'].reduceRight(
                    (acc, curr) => ({ [curr]: acc }),
                    {
                      startTime: dayjs(v[0]).format('YYYY-MM-DD HH:ss'),
                      endTime: dayjs(v[1]).format('YYYY-MM-DD HH:ss'),
                    },
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item
            style={{ cursor: 'pointer' }}
            onClick={() => {
              vsf.navigateTo(
                `/activityManage/activityList?type=${data.activityType}`,
              );
            }}
          >
            活动列表
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {isCreate ? '活动新建' : '活动编辑'}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Section
        className="activityContainer"
        style={{
          backgroundColor: '#ffffff',
          padding: '20px',
          minHeight: '88vh',
          borderRadius: '6px',
        }}
        title={isCreate ? '新建活动' : '编辑活动'}
        extra={
          isCreate ? (
            <div>
              <Button
                style={{ marginRight: '15px' }}
                type="primary"
                onClick={() => {
                  formRef.current?.resetFields();
                }}
              >
                一键清空
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  vsf.navigateTo('/activityManage/activityList');
                }}
              >
                返回
              </Button>
            </div>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                vsf.navigateTo(
                  `/activityManage/activityList?type=${data.activityType}`,
                );
              }}
            >
              返回
            </Button>
          )
        }
      >
        <Divider style={{ marginTop: 0 }} />
        <VSForm
          style={{ height: loading ? '0px' : undefined, overflow: 'hidden' }}
          ref={formRef}
          layout="horizontal"
          labelCol={{
            span: 4,
          }}
          validateMessages="请将所有字段填写完整"
          params={{ activityId: id }}
          onFetch={onFetch}
          onFinish={(data, success) => {
            const text = isCreate ? '活动新建' : '活动编辑';
            if (success) {
              setTimeout(() => {
                vsf.showToast(`${text}成功`, 'success');
                vsf.navigateTo(
                  `/activityManage/activityList?type=${data.activityType}`,
                );
              }, 2000);
            }
          }}
          onChange={(_value) => {
            formDataRef.current = _value;
          }}
          onSubmit={async (params) => {
            setIsLoading(true);
            const _params = {
              ...params,
              ...params.ditu,
              ditu: undefined,
              startHourMinute: undefined,
              endHourMinute: undefined,
              startDate: undefined,
              endDate: undefined,
              startTime: undefined,
              endTime: undefined,
              activityTimeInterval: undefined,
              date: undefined,
            };
            if (params?.ditu?.clockScope && !_params?.clockAddress) {
              return vsf.showToast('请选择地址范围', 'error');
            }
            try {
              const request = isCreate
                ? vsf.services.ActivityInfoController_createActivityInfo_dd2751
                : data?.activityCycleInfo
                ? data?.activityStatus === 'Z_ALREADY_END'
                  ? vsf.services
                      .ActivityInfoController_updateActivityInfoNotCycle_93b476
                  : vsf.services
                      .ActivityInfoController_updateActivityInfo_a5b91d
                : vsf.services
                    .ActivityInfoController_updateActivityInfoNotCycle_93b476;

              const cycleBto = {
                // id:,
                ...params.date,
                startHourMinute: params.startHourMinute,
                endHourMinute: params.endHourMinute,
                startWeek: timeValue === 'WEEKLY' ? startWeek : undefined,
                endWeek: timeValue === 'WEEKLY' ? endWeek : undefined,
                startDate: timeValue === 'MONTHLY' ? startDate : undefined,
                endDate: timeValue === 'MONTHLY' ? endDate : undefined,
                startTime: params?.date?.startTime
                  ? dayjs(params?.date?.startTime).format('YYYY-MM-DD')
                  : dayjs(params?.startTime).format('YYYY-MM-DD'),
                endTime: params?.date?.endTime
                  ? dayjs(params?.date?.endTime).format('YYYY-MM-DD')
                  : dayjs(params?.endTime).format('YYYY-MM-DD'),
                activityTimeInterval: timeValue,
                id: timeId ?? undefined,
              };
              const res = await request?.({
                btoParam: toData(
                  isCreate
                    ? {
                        ..._params,
                        activityType: type,
                      }
                    : _params,
                ),
                cycleBto: isCreate
                  ? cycleBto
                  : data?.activityCycleInfo
                  ? data?.activityStatus === 'Z_ALREADY_END'
                    ? undefined
                    : cycleBto
                  : undefined,
              });
              if (res?.data) {
                return fromData(res.data);
              }
              if (res?.code === 200) {
                setTimeout(() => {
                  setIsLoading(false);
                  vsf.navigateTo('/activityManage/activityList');
                }, 2000);
              } else {
                setIsLoading(false);
              }
            } catch (error) {
              setIsLoading(false);
              console.error();
              //
            }
            return undefined;
          }}
        >
          <VSFormItem
            name={['id']}
            label="主键"
            style={{ display: 'none' }}
            valueType="digit"
            fieldProps={{}}
          />
          <VSFormLayout {...formLayoutProps}>
            <VSFormItem
              name={['activityImg']}
              readonly={activityStatus === 'A_IN_PROGRESS'}
              label="活动主图"
              valueType="fileUpload"
              fieldProps={{
                type: 'picture',
                getOssPolicy,
                limit: 500 * 1024,
                onLimitOverflow: (fileSize, limit) => {
                  vsf.showToast('图片内存大小不可超过500kb', 'error');
                },
                uploadProps: {
                  maxCount: 1,
                  accept: '.jpg,.png,.jpeg',
                  // disabled: activityStatus === 'A_IN_PROGRESS',
                },
                container: (
                  <ImgCrop
                    modalTitle="裁剪图片"
                    rotationSlider
                    aspect={1 / 1}
                    showReset
                    modalWidth={1000}
                    children={<></>}
                  />
                ),
              }}
              convertValue={(v) => {
                if (!v || Array.isArray(v)) {
                  return v;
                }
                return [{ url: v }];
              }}
              transform={(v) => {
                return {
                  activityImg: Array.isArray(v) ? v?.[0]?.url : v,
                };
              }}
              rules={[
                {
                  required: true,
                  message: '请选择图片',
                },
              ]}
            />
            {!isCreate && (
              <VSFormItem
                name={['activityStatus']}
                valueType="seal"
                dataSource={[
                  { label: '未开始', value: 'NOT_START' },
                  { label: '进行中', value: 'A_IN_PROGRESS' },
                  { label: '已结束', value: 'Z_ALREADY_END' },
                ]}
                convertValue={(v) => {
                  if (v === 'Z_ALREADY_END') {
                    return { text: '已结束', color: 'gray' };
                  } else if (v === 'A_IN_PROGRESS') {
                    return { text: '进行中', color: 'green' };
                  } else if (v === 'NOT_START') {
                    return { text: '未开始', color: 'blue' };
                  }
                  return undefined;
                }}
                fieldProps={{
                  width: 200,
                }}
              />
            )}
          </VSFormLayout>
          {
            <>
              <div
                style={{
                  margin: '-10px 0 12px 230px',
                  fontSize: '12px',
                }}
              >
                只能上传jpg/png格式文件，且不超过500kb
              </div>
            </>
          }
          <VSFormItem
            name={['activityName']}
            label="活动名称"
            valueType="text"
            rules={[
              {
                required: true,
                message: '请输入活动名称',
              },
              {
                message: '活动名称长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ]}
            fieldProps={{
              placeholder: '请输入活动名称',
              maxLength: 16,
              width: 400,
              disabled: activityStatus === 'A_IN_PROGRESS',
            }}
          />

          <VSFormItem
            name={['activityAddress']}
            label="活动地点"
            valueType="text"
            rules={[
              {
                required: true,
                message: '请输入活动地点',
              },
              {
                message: '活动地址长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ]}
            fieldProps={{
              placeholder: '请输入活动地点',
              width: 400,
              disabled: activityStatus === 'A_IN_PROGRESS',
              maxLength: 16,
            }}
          />

          <VSFormItem
            name={['categorys']}
            label="活动类别"
            valueType="cascader"
            rules={[
              {
                required: true,
                message: '请选择活动类别',
              },
            ]}
            render={(_, data) => {
              const ret = [];
              if (data.activityCategoryOneName) {
                ret.push(data.activityCategoryOneName);
              }
              if (data.activityCategoryTwoName) {
                ret.push(data.activityCategoryTwoName);
              }
              if (data.activityCategoryThreeName) {
                ret.push(data.activityCategoryThreeName);
              } else if (data.activityCategoryTwoName) {
                ret.push(data.activityCategoryTwoName);
              } else if (data.activityCategoryOneName) {
                ret.push(data.activityCategoryOneName);
              }
              return ret.join('/');
            }}
            fieldProps={{
              placeholder: '请选择活动类别',
              width: 400,
              fieldNames: {
                value: 'id',
                label: 'categoryName',
                children: 'activityCategoryDtoList',
              },
              disabled: activityStatus === 'A_IN_PROGRESS',
              dataSource: () => {
                // 活动类别列表数据从接口获取
                return new Promise((resolve, reject) => {
                  vsf.services
                    .ActivityCategoryController_getActivityCategoryDetail_0c5bb6?.()
                    .then((res) => {
                      if (res.data) {
                        resolve(res.data);
                      } else {
                        reject();
                      }
                    })
                    .catch(reject);
                });
              },

              onChange: (val, a) => {
                const tempData = val?.[val.length - 1]?.activityIntegral;
                setIntegralCode(tempData);
                formRef?.current?.setFieldValue('integral', tempData);
              },
            }}
          />
          {/* <VSFormLayout {...formLayoutProps}> */}
          <div style={{ marginBottom: '15px' }}>
            <VSFormItem
              label="活动时间"
              valueType="custom"
              name={['activityTimeInterval']}
              // rules={[
              //   {
              //     required: true,
              //     message: '请选择活动时间',
              //   },
              // ]}
            >
              <Radio.Group
                style={{ marginBottom: '10px' }}
                dataSource={options}
                onChange={onChange}
                value={timeValue}
                disabled={
                  activityStatus === 'A_IN_PROGRESS' ||
                  activityStatus === 'Z_ALREADY_END'
                }
                fieldProps={{
                  buttonStyle: 'solid',
                  fileList: {},
                }}
                rules={[
                  {
                    required: true,
                    message: '请选择活动时间',
                  },
                ]}
                optionType="button"
              />
              <VSFormDependency name={['activityTimeInterval']}>
                {(value) => {
                  return renderTime(timeValue);
                }}
              </VSFormDependency>
            </VSFormItem>
          </div>

          <div className="antd-formitem-contnent-border">
            <VSFormItem
              name={['activityDetail']}
              label="活动详情"
              valueType="textarea"
              rules={[
                {
                  required: true,
                  message: '请输入活动详情',
                },
                {
                  message: '活动详情长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1024,
                },
              ]}
              fieldProps={{
                placeholder: '请输入活动详情',
                maxLength: 220,
                showCount: true,
                width: 400,
                disabled: activityStatus === 'A_IN_PROGRESS',
              }}
            />
          </div>

          {type !== 'UPLOAD_PROOF_TYPE_ACTIVITY' && (
            <VSFormItem
              name="ditu"
              valueType="map"
              transform={(e) => {
                if (e.clockScope === undefined) {
                  return {
                    ...e,
                    clockScope: true,
                  };
                }
                return e;
              }}
              rules={[
                {
                  validator: (_, value) => {
                    return new Promise((resolve, reject) => {
                      if (!value?.effectiveRadius && value?.clockScope) {
                        reject('请选择有效半径');
                      }
                      // if (!value?.longitude && value?.clockScope) {
                      //   reject('请选择有效地址');
                      // }
                      resolve();
                    });
                  },
                },
              ]}
              fieldProps={{
                defaultValue: 50,
                disabled:
                  activityStatus === 'A_IN_PROGRESS' ||
                  activityStatus === 'Z_ALREADY_END',
              }}
            />
          )}

          <VSFormItem
            name={['integral']}
            label="参与活动积分"
            valueType="digit"
            // rules={[
            //   { message: '积分的值不合法', type: 'number', min: 0, max: null },
            // ]}
            fieldProps={{
              disabled: true,
              addonAfter: '分/人',
              placeholder: '积分',
              width: 120,
              // value: integralCode,
            }}
          />

          <VSFormLayout {...formLayoutProps}>
            <VSFormItem
              name={['isActivityEnroll']}
              label="是否开启团队报名"
              valueType="switch"
              initialValue={true}
              fieldProps={{
                disabled:
                  activityStatus === 'A_IN_PROGRESS' ||
                  activityStatus === 'Z_ALREADY_END' ||
                  activityStatus === 'NOT_START',
              }}
            />
            <VSFormDependency name={['isActivityEnroll']}>
              {(value) => {
                if (value.isActivityEnroll) {
                  return (
                    <VSFormItem
                      style={secondItemStyle}
                      name={['activityNum']}
                      valueType="digit"
                      modalTitle=""
                      // convertValue={(value) => {
                      //   return typeof value === 'number' && value >= 1
                      //     ? value
                      //     : 1;
                      // }}
                      rules={[
                        {
                          message: '团队数量的值不合法',
                          type: 'number',
                          min: 1,
                          max: null,
                        },
                        {
                          required: true,
                          message: '请输入报名人数',
                        },
                      ]}
                      fieldProps={{
                        placeholder: '团队数量',
                        addonAfter: '个',
                        width: 120,
                        disabled:
                          activityStatus === 'A_IN_PROGRESS' ||
                          activityStatus === 'Z_ALREADY_END' ||
                          activityStatus === 'NOT_START',
                      }}
                      transform={(values) => {
                        return { activityNum: values ? values : 1 };
                      }}
                    />
                  );
                }
              }}
            </VSFormDependency>
          </VSFormLayout>

          <VSFormLayout {...formLayoutProps}>
            <VSFormItem
              name={['isEnrollNum']}
              label="是否限制报名人数"
              valueType="switch"
              initialValue={false}
              fieldProps={{
                disabled:
                  activityStatus === 'A_IN_PROGRESS' ||
                  activityStatus === 'Z_ALREADY_END' ||
                  activityStatus === 'NOT_START',
              }}
            />

            <VSFormDependency name={['isEnrollNum']}>
              {(value) => {
                if (value.isEnrollNum) {
                  return (
                    <VSFormItem
                      style={secondItemStyle}
                      name={['totalNum']}
                      valueType="digit"
                      // convertValue={(value) =>
                      //   typeof value === 'number' && value >= 0 ? value : 0
                      // }
                      rules={[
                        {
                          message: '总人数的值不合法',
                          type: 'number',
                          min: 0,
                          max: null,
                        },
                        {
                          required: true,
                          message: '请输入报名人数',
                        },
                      ]}
                      fieldProps={{
                        placeholder: '总人数',
                        addonAfter: '人',
                        width: 120,
                        disabled:
                          activityStatus === 'A_IN_PROGRESS' ||
                          activityStatus === 'Z_ALREADY_END' ||
                          activityStatus === 'NOT_START',
                      }}
                      transform={(value) => {
                        return { totalNum: value ? value : 0 };
                      }}
                    />
                  );
                }
              }}
            </VSFormDependency>
          </VSFormLayout>

          <VSFormLayout {...formLayoutProps}>
            <VSFormItem
              name={['isAgeLimit']}
              label="是否年龄限制"
              valueType="switch"
              initialValue={false}
              fieldProps={{
                disabled:
                  activityStatus === 'A_IN_PROGRESS' ||
                  activityStatus === 'Z_ALREADY_END' ||
                  activityStatus === 'NOT_START',
              }}
            />

            <VSFormDependency name={['isAgeLimit']}>
              {(value) => {
                if (value.isAgeLimit) {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        gap: 8,
                        ...secondItemStyle,
                      }}
                    >
                      <VSFormItem
                        name={['minimumAge']}
                        valueType="digit"
                        initialValue={20}
                        convertValue={(value) =>
                          typeof value === 'number' && value >= 0 ? value : 0
                        }
                        rules={[
                          {
                            required: true,
                            message: '请输入最小年龄',
                          },
                          {
                            message: '最小年龄的值不合法',
                            type: 'number',
                            min: 0,
                            max: null,
                          },
                        ]}
                        fieldProps={{
                          width: 140,
                          placeholder: '请输入最小年龄',
                          addonAfter: '岁',
                          disabled:
                            activityStatus === 'A_IN_PROGRESS' ||
                            activityStatus === 'Z_ALREADY_END' ||
                            activityStatus === 'NOT_START',
                        }}
                      />
                      <span
                        style={{
                          marginBottom: 24,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        到
                      </span>
                      <VSFormItem
                        name={['maxAge']}
                        valueType="digit"
                        initialValue={80}
                        convertValue={(value) =>
                          typeof value === 'number' && value >= 0 ? value : 0
                        }
                        rules={[
                          {
                            required: true,
                            message: '请输入最大年龄',
                          },
                          {
                            message: '最大年龄的值不合法',
                            type: 'number',
                            min: 0,
                            max: null,
                          },
                        ]}
                        fieldProps={{
                          width: 140,
                          placeholder: '请输入最大年龄',
                          addonAfter: '岁',
                          disabled:
                            activityStatus === 'A_IN_PROGRESS' ||
                            activityStatus === 'Z_ALREADY_END' ||
                            activityStatus === 'NOT_START',
                        }}
                      />
                    </div>
                  );
                }
              }}
            </VSFormDependency>
          </VSFormLayout>

          {type === 'UPLOAD_PROOF_TYPE_ACTIVITY' && (
            <VSFormItem
              name={['isAlbum']}
              label="是否可从相册选择"
              valueType="switch"
              initialValue={false}
              fieldProps={{
                disabled:
                  activityStatus === 'A_IN_PROGRESS' ||
                  activityStatus === 'Z_ALREADY_END',
              }}
            />
          )}
          {!isCreate && (
            <>
              <VSFormItem
                name={['enrollTeamInfoDtoList']}
                label="活动报名团队"
                valueType="custom"
              >
                <Tags
                  tagRender={(v) => v.team?.name || '未知团队'}
                  titleRender={(v) => (v.length ? `${v.length}个` : '0个')}
                  getKey={(v) => v.id}
                />
              </VSFormItem>
              <VSFormItem
                name={['enrollInfoDtoList']}
                label="活动报名人"
                valueType="custom"
              >
                <Tags
                  tagRender={(v) => v.user?.nickname || '未知人员'}
                  titleRender={(v) => (v.length ? `${v.length}人` : '0人')}
                  getKey={(v) => v.id}
                />
              </VSFormItem>
              <VSFormItem
                name={['invokeInfoDtoList']}
                label="活动参与人"
                valueType="custom"
              >
                <Tags
                  tagRender={(v) => v.user?.nickname || '未知人员'}
                  titleRender={(v) => (v.length ? `${v.length}人` : '0人')}
                  getKey={(v) => v.id}
                />
              </VSFormItem>
            </>
          )}
          <VSFormFooter>
            <Button
              onClick={() => {
                if (isCreate) {
                  Modal.confirm({
                    icon: null,
                    content: (
                      <div>已填写的信息不会保存，是否取消新建活动？</div>
                    ),
                    okText: '确定',
                    onOk: () => {
                      vsf.navigateTo(
                        `/activityManage/activityList?type=${type}`,
                      );
                    },
                  });
                } else {
                  vsf.navigateTo(`/activityManage/activityList?type=${type}`);
                }
              }}
            >
              取消
            </Button>
            <VSFormFooterSubmit
              disabled={activityStatus === 'A_IN_PROGRESS'}
              loading={isLoading}
            >
              确定
            </VSFormFooterSubmit>
          </VSFormFooter>
        </VSForm>
      </Section>
    </div>
  );
};
export default definePage(ActivityEditor);

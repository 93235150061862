import vsf, { definePage } from '@vs/vsf-boot';
import React, { useCallback, useEffect } from 'react';

import { UserTypeEnum } from '@/config';

const MiddlePage: React.FC<React.Component> = (props: any) => {
  const {
    routes: {
      query: { code },
    },
  } = props;
  const Login = useCallback(async () => {
    try {
      const response =
        await vsf.services?.AccountInfoController_loginByWechat_645f63({
          code,
        });
      if (response && response.code === 200) {
        localStorage.setItem('userInfo', JSON.stringify(response.data));
        console.log(response.data, 'response.data====');
        if (UserTypeEnum.VILLAGE_ADMIN === response.data?.userType) {
          vsf.navigateTo('/activityManage/activityList');
        } else {
          vsf.navigateTo('/Cockpit');
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [code]);

  useEffect(() => {
    Login();
  }, [code, Login]);

  return <div>请稍等....</div>;
};

export default definePage(MiddlePage);

import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSControlledForm, VSFormItem } from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';

const AddMember = (props) => {
  const [user, setUser] = useState({});

  const handleValuesChange = (values) => {
    const { userInfoDto } = values;
    if (userInfoDto?.idNum?.length === 18) {
      vsf.services
        .AccountInfoController_getAccountDetailByIdNum_78688d({
          idNum: userInfoDto.idNum,
        })
        .then((res) => {
          setUser(
            res?.code === 200 && res.data
              ? res.data
              : {
                  userInfoDto,
                },
          );
        });
    }
  };

  const handleBlur = useCallback((e) => {
    const idNum = e.target.value;
    if (idNum?.length !== 18) {
      setUser({
        userInfoDto: {
          idNum,
        },
        id: '',
        nickname: '',
      });
    }
  }, []);

  return (
    <Section title="">
      <VSControlledForm
        id="addMemberForm"
        layout="horizontal"
        onValuesChange={handleValuesChange}
        value={user}
      >
        <VSFormItem
          name={['userInfoDto', 'idNum']}
          label="身份证号"
          valueType="text"
          fieldProps={{
            allowClear: true,
            onBlur: handleBlur,
          }}
        />
        <VSFormItem
          name={['id']}
          label="id"
          valueType="text"
          fieldProps={{
            disabled: true,
          }}
          style={{ display: 'none' }} /* 需要用id，但是不显示 */
        />
        <VSFormItem
          name={['nickname']}
          label="个人姓名"
          valueType="text"
          fieldProps={{
            disabled: true,
          }}
        />
        <VSFormItem
          name={['adminStatus']}
          label="同时设置为管理员"
          valueType="switch"
        />
      </VSControlledForm>
    </Section>
  );
};

export default definePage(AddMember);

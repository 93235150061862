import './TeamListVp.less';

import EditTeam from '@pages/EditTeam';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Breadcrumb,
  Button,
  message,
  Modal,
  Pagination,
  RadioGroup,
  Section,
  Space,
  useControllableState,
  VSControlledForm,
  VSForm,
  VSFormFooter,
  VSFormFooterSubmit,
  VSFormItem,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';

import { getAgeAndGenderFromID } from '@/utils/index';

import styles from './TeamListVp.module.less';

const ScoreStatusRadio = ({ value, onChange }) => {
  const [v, setV] = useControllableState({
    value: String(value),
    onChange: (val) => {
      onChange?.(val === 'true');
    },
  });
  return (
    <RadioGroup
      value={v}
      onChange={setV}
      dataSource={[
        {
          label: '加分',
          value: 'true',
        },
        {
          label: '扣分',
          value: 'false',
        },
      ]}
    />
  );
};
const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');
const PersonListVp = (props) => {
  /**
   * 查询参数
   * @type {TeamInfoPersistQtoPageTeamInfoQto}
   */
  const [total, setTotal] = useState(0);
  const [querys, setQuerys] = useState({ size: 10, page: 1 });
  const [currentRecord, setCurrentRecord] = useState({});

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.UserInfoMerchantController_getPagedByUserInfoMerchant_32a6b3?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}),
          },
        },
      );
    setTotal(res?.data?.count ?? res?.data?.length ?? 0);
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const handleScore = useCallback(async () => {
    try {
      const values =
        await vsf?.refs?.scoreUpdateForm?.validateFieldsReturnFormatValue();
      const res =
        await vsf.services.UserInfoMerchantController_updateMerchantName_466cd6(
          {
            accountId: currentRecord?.user?.id,
            ...values,
          },
        );

      if (res && res.code === 200) {
        message.success('编辑成功');
        vsf.refs.table?.reload();
      }

      return true;
    } catch (e) {
      return false;
    }
  }, [currentRecord]);

  return (
    <div style={{ minHeight: '88vh' }} className="container-box">
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>商家管理</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Section className={styles['vs-form-footer']}>
        <div className="container__team-headr">
          <VSControlledForm
            id="form"
            layout="inline"
            value={querys}
            onSubmit={(_value) =>
              setQuerys({ ..._value, from: 0, size: 10, page: 1 })
            }
          >
            <VSFormItem
              name={['merchantNameLike']}
              label="商铺名称"
              valueType="text"
              fieldProps={{
                placeholder: '请输入商铺名称',
              }}
            />
            <VSFormItem
              name={['nameLike']}
              label="联系人姓名"
              valueType="text"
              fieldProps={{
                placeholder: '请输入联系人姓名',
              }}
            />

            <VSFormFooter>
              <VSFormFooterSubmit children="查询" />
              <Button
                children="重置"
                onClick={() =>
                  setQuerys({
                    page: 1,
                    size: 10,
                    from: 0,
                    nameLike: undefined,
                    merchantNameLike: undefined,
                  })
                }
              />
            </VSFormFooter>
          </VSControlledForm>
        </div>
      </Section>
      <div className="container__content">
        <Space style={{ marginTop: 5, marginLeft: 10, float: 'left' }}>
          <div className="tableTitle">商家列表</div>
        </Space>
        <div style={{ margin: '24px 0', display: 'flex' }} />

        <VSPromiseTable
          id="table"
          cardProps={{
            bodyStyle: {
              padding: 0,
            },
          }}
          // scroll={{ y: 550 }}
          onFetch={onFetch}
          // pagination={false}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            defaultPageSize: 10,
          }}
          extraParams={querys}
        >
          <VSTableColumn
            dataIndex={['merchantName']}
            title="商铺名称"
            align="center"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['name']}
            title="联系人"
            align="center"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['phone']}
            title="联系方式"
            align="center"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={[
              'user',
              'merchantExchangeStatisticsDto',
              'integralAmount',
            ]}
            title="累计销售义工分"
            align="center"
            editable={false}
            valueType="digit"
            render={(_, data) => {
              return (
                data.user.merchantExchangeStatisticsDto?.integralAmount || 0
              );
            }}
          />

          <VSTableColumn
            dataIndex={[
              'user',
              'merchantExchangeStatisticsDto',
              'exchangeTimes',
            ]}
            title="兑换次数"
            align="center"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn title="操作" valueType="text" align="center">
            <VSTableAction
              children="编辑"
              onClick={(v, record) => {
                setCurrentRecord(record);
                vsf.openModal('scoreUpdateModal');
                setTimeout(() => {
                  vsf.refs.scoreUpdateForm?.setFieldsValue(record);
                }, 1);
              }}
            />
          </VSTableColumn>
        </VSPromiseTable>
      </div>

      <Modal
        title="编辑商家"
        id="scoreUpdateModal"
        onOk={handleScore}
        okText="确定"
        cancelText="取消"
      >
        <VSForm
          id="scoreUpdateForm"
          layout="horizontal"
          initialValues={{ addStatus: true }}
        >
          <VSFormItem
            name={['merchantName']}
            label="商铺名称"
            required
            valueType="text"
            rules={[
              {
                required: true,
                message: '请填写商铺名称',
              },
            ]}
            fieldProps={{
              style: {
                width: 300,
              },
            }}
          />
          <VSFormItem
            name={['idNum']}
            label="身份证号"
            valueType="digit"
            readonly
            fieldProps={{
              style: {
                width: 300,
              },
            }}
          />

          <VSFormItem
            name={['name']}
            label="联系人姓名"
            valueType="text"
            readonly
            fieldProps={{
              style: {
                width: 300,
              },
            }}
          />

          <VSFormItem
            name={['phone']}
            label="手机号"
            valueType="text"
            readonly
            fieldProps={{
              style: {
                width: 300,
              },
            }}
          />
        </VSForm>
      </Modal>
    </div>
  );
};
export default definePage(PersonListVp);

let callbacks: MapInstanceCallback[] = [];
let loading = false;

export type MapInstanceCallback = (instance: any) => void;
export function loadSDK() {
  if (loading) {
    return;
  }
  const key = 'ADDBZ-33M3Q-FVR5P-BWP7N-CNGV6-LKBRC';
  const libs = ['visualization', 'tools', 'geometry', 'service'];
  const _window: any = window;
  if (_window.TMap) {
    callbacks.forEach((callback) => {
      callback(_window.TMap);
    });
    callbacks = [];
    return;
  }
  loading = true;
  _window.tmapCallback = function tmapCallback() {
    callbacks.forEach((callback) => {
      callback(_window.TMap);
    });
    callbacks = [];
    loading = false;
  };
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `https://map.qq.com/api/gljs?v=1.exp&key=${key}&libraries=${libs.join(
    ',',
  )}&callback=tmapCallback`;
  document.body.appendChild(script);
}

export function addCallback(callback: MapInstanceCallback) {
  callbacks.push(callback);
}

export function removeCallback(callback: MapInstanceCallback) {
  const index = callbacks.indexOf(callback);
  if (index > -1) {
    callbacks.splice(index, 1);
  }
}

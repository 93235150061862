import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import cn from 'classnames';
import * as echarts from 'echarts';
import React, { useCallback, useEffect, useState } from 'react';

import { StatisticsQueryScopeEnum } from '@/config';
import { formatNumber } from '@/utils/index';

const EchartsMenu = [
  {
    label: '昨日',
    value: StatisticsQueryScopeEnum.YESTERDAY,
  },
  {
    label: '近7天',
    value: StatisticsQueryScopeEnum.SEVEN_DAY,
  },
  {
    label: '近30天',
    value: StatisticsQueryScopeEnum.THIRTY_DAY,
  },
  {
    label: '全部',
    value: StatisticsQueryScopeEnum.TOTAL,
  },
];

const Cockpit: React.FC<React.Component> = (props) => {
  const [data, setData] = useState<StatisticsInfoPersistEoStatisticsInfoEo>();
  const [activeTabsValue, setActiveTabsValue] = useState(
    StatisticsQueryScopeEnum.YESTERDAY,
  );

  useEffect(() => {
    loadExtraData();
  }, []);

  const loadExtraData = async () => {
    try {
      const response =
        await vsf.services?.StatisticsInfoController_getStatisticsInfo_ad5044();
      if (response && response.code === 200) {
        setData(response.data);
      } else {
        setData({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadEchartsData = useCallback(async () => {
    try {
      const response =
        await vsf.services.StatisticsInfoController_getIntegralPub_803898({
          queryScope: activeTabsValue,
        });
      const chartDom = document.getElementById('cockpit_echarts');
      if (chartDom) {
        const myChart = echarts.init(chartDom);
        myChart.setOption(getOPtions(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  }, [activeTabsValue]);

  useEffect(() => {
    loadEchartsData();
  }, [activeTabsValue, loadEchartsData]);

  const getOPtions = (echartsData) => {
    return {
      width: '95%',
      height: '100%',
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {},
      grid: {
        top: '0%',
        left: '0%',
        right: '4%',
        // bottom: '5%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        axisLine: {
          show: true,
        },
      },
      yAxis: {
        type: 'category',
        data: ['手动赋分', '心愿', '随手拍', '组织活动'],
        axisLine: {
          show: false,
        },
      },
      series: [
        {
          type: 'bar',
          data: [
            echartsData?.integralPubHandle ?? 0,
            echartsData?.integralPubWish ?? 0,
            echartsData?.integralPubCommunity ?? 0,
            echartsData?.integralPubActivity ?? 0,
          ],
          barWidth: '20px',
          itemStyle: {
            normal: {
              color: '#ffb335',
              borderRadius: 30,
              borderColor: 'transparent',
            },
          },
        },
      ],
    };
  };

  return (
    <div className="cockpit_container">
      <div className="cockpit_container__foundation">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '16px',
              height: '16px',
              borderRadius: '16px',
              background: '#FADEDE',
              marginRight: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: '8px',
                height: '8px',
                borderRadius: '8px',
                background: '#D81F1D',
              }}
            ></div>
          </div>
          <div style={{ fontSize: '18px', color: '#252525', fontWeight: 600 }}>
            基础数据
          </div>
        </div>
        <div className="cockpit_container__foundation__data">
          <div className="cockpit_container__foundation__data__item">
            <div>团队数</div>
            <strong>{formatNumber(data?.teamAmount) ?? 0}</strong>
          </div>
          <div className="cockpit_container__foundation__data__item">
            <div>团队总人数</div>
            <strong>{formatNumber(data?.teamMemberAmount) ?? 0}</strong>
          </div>
          <div className="cockpit_container__foundation__data__item">
            <div>活动总数</div>
            <strong>{formatNumber(data?.activityAmount) ?? 0}</strong>
          </div>
          <div className="cockpit_container__foundation__data__item">
            <div>心愿总数</div>
            <strong>{formatNumber(data?.wishAmount) ?? 0}</strong>
          </div>
          <div className="cockpit_container__foundation__data__item">
            <div>随手拍总数</div>
            <strong>{formatNumber(data?.communityAmount) ?? 0}</strong>
          </div>
          <div className="cockpit_container__foundation__data__item">
            <div>义工分发放总数</div>
            <strong>{formatNumber(data?.integralPubTotalAmount) ?? 0}</strong>
          </div>
        </div>
      </div>

      <div className="cockpit_container__teamdata">
        <div className="cockpit_container__teamdata__item">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '16px',
            }}
          >
            <div
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '16px',
                background: '#FADEDE',
                marginRight: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '8px',
                  background: '#D81F1D',
                }}
              ></div>
            </div>
            <div
              style={{ fontSize: '18px', color: '#252525', fontWeight: 600 }}
            >
              团队活动数据
            </div>
          </div>
          <div className="cockpit_container__teamdata__item__data">
            <div className="cockpit_container__teamdata__item__data__box">
              <h3 className="cockpit_container__teamdata__item__data__box-title">
                活动数量
              </h3>
              <div className="cockpit_container__teamdata__item__data__box__value">
                <div>
                  <div className="cockpit_container__teamdata__item__data__box__value_name">
                    近7天
                  </div>
                  <div className="cockpit_container__teamdata__item__data__box__value_text">
                    {data?.sevenDayActivityAmount ?? 0}
                  </div>
                </div>
                <div>
                  <div className="cockpit_container__teamdata__item__data__box__value_name">
                    近30天
                  </div>
                  <div className="cockpit_container__teamdata__item__data__box__value_text">
                    {data?.thirtyDayActivityAmount ?? 0}
                  </div>
                </div>
              </div>
            </div>

            <div className="cockpit_container__teamdata__item__data__line"></div>
            <div className="cockpit_container__teamdata__item__data__box">
              <h3 className="cockpit_container__teamdata__item__data__box-title">
                参与人数
              </h3>
              <div className="cockpit_container__teamdata__item__data__box__value">
                <div>
                  <div className="cockpit_container__teamdata__item__data__box__value_name">
                    近7天
                  </div>
                  <div className="cockpit_container__teamdata__item__data__box__value_text">
                    {data?.sevenDayActivityUserAmount ?? 0}
                  </div>
                </div>
                <div>
                  <div className="cockpit_container__teamdata__item__data__box__value_name">
                    近30天
                  </div>
                  <div className="cockpit_container__teamdata__item__data__box__value_text">
                    {data?.thirtyDayActivityUserAmount ?? 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cockpit_container__teamdata__item">
          {/* <h3>心愿数据</h3> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '16px',
            }}
          >
            <div
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '16px',
                background: '#FADEDE',
                marginRight: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '8px',
                  background: '#D81F1D',
                }}
              ></div>
            </div>
            <div
              style={{ fontSize: '18px', color: '#252525', fontWeight: 600 }}
            >
              心愿数据
            </div>
          </div>
          <div className="cockpit_container__teamdata__item__data">
            <div className="cockpit_container__teamdata__item__data__box">
              <h3 className="cockpit_container__teamdata__item__data__box-title">
                心愿数量
              </h3>
              <div className="cockpit_container__teamdata__item__data__box__value">
                <div>
                  <div className="cockpit_container__teamdata__item__data__box__value_name">
                    近7天
                  </div>
                  <div className="cockpit_container__teamdata__item__data__box__value_text">
                    {data?.sevenDayWishAmount ?? 0}
                  </div>
                </div>
                <div>
                  <div className="cockpit_container__teamdata__item__data__box__value_name">
                    近30天
                  </div>
                  <div className="cockpit_container__teamdata__item__data__box__value_text">
                    {data?.thirtyDayWishAmount ?? 0}
                  </div>
                </div>
              </div>
            </div>

            <div className="cockpit_container__teamdata__item__data__line"></div>
            <div className="cockpit_container__teamdata__item__data__box">
              <h3 className="cockpit_container__teamdata__item__data__box-title">
                认领数量
              </h3>
              <div className="cockpit_container__teamdata__item__data__box__value">
                <div>
                  <div className="cockpit_container__teamdata__item__data__box__value_name">
                    近7天
                  </div>
                  <div className="cockpit_container__teamdata__item__data__box__value_text">
                    {data?.sevenDayWishClaimAmount ?? 0}
                  </div>
                </div>
                <div>
                  <div className="cockpit_container__teamdata__item__data__box__value_name">
                    近30天
                  </div>
                  <div className="cockpit_container__teamdata__item__data__box__value_text">
                    {data?.thirtyDayWishClaimAmount ?? 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cockpit_container__teamdata__item">
          {/* <h3>随手拍数据</h3> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '16px',
            }}
          >
            <div
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '16px',
                background: '#FADEDE',
                marginRight: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '8px',
                  background: '#D81F1D',
                }}
              ></div>
            </div>
            <div
              style={{ fontSize: '18px', color: '#252525', fontWeight: 600 }}
            >
              随手拍数据
            </div>
          </div>
          <div className="cockpit_container__teamdata__item__data">
            <div className="cockpit_container__teamdata__item__data__box">
              <h3 className="cockpit_container__teamdata__item__data__box-title">
                随手拍数量
              </h3>
              <div className="cockpit_container__teamdata__item__data__box__value">
                <div>
                  <div className="cockpit_container__teamdata__item__data__box__value_name">
                    近7天
                  </div>
                  <div className="cockpit_container__teamdata__item__data__box__value_text">
                    {data?.sevenDayCommunityAmount ?? 0}
                  </div>
                </div>
                <div>
                  <div className="cockpit_container__teamdata__item__data__box__value_name">
                    近30天
                  </div>
                  <div className="cockpit_container__teamdata__item__data__box__value_text">
                    {data?.thirtyDayCommunityAmount ?? 0}
                  </div>
                </div>
              </div>
            </div>

            <div className="cockpit_container__teamdata__item__data__line"></div>
            <div className="cockpit_container__teamdata__item__data__box">
              <h3 className="cockpit_container__teamdata__item__data__box-title">
                团队随手拍数量
              </h3>
              <div className="cockpit_container__teamdata__item__data__box__value">
                <div>
                  <div className="cockpit_container__teamdata__item__data__box__value_name">
                    近7天
                  </div>
                  <div className="cockpit_container__teamdata__item__data__box__value_text">
                    {data?.sevenDayTeamCommunityAmount ?? 0}
                  </div>
                </div>
                <div>
                  <div className="cockpit_container__teamdata__item__data__box__value_name">
                    近30天
                  </div>
                  <div className="cockpit_container__teamdata__item__data__box__value_text">
                    {data?.thirtyDayTeamCommunityAmount ?? 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cockpit_container__echarts">
        <div
          className="cockpit_container__echarts__menu"
          style={{
            borderBottom: '1px solid  rgba(0,0,0,0.09)',
            paddingBottom: '10px',
            position: 'relative',
          }}
        >
          {EchartsMenu?.map((item) => (
            <div
              className="cockpit_container__echarts__menu__item"
              key={item.value}
              onClick={() => {
                setActiveTabsValue(item.value);
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <a
                style={{
                  fontWeight: item.value === activeTabsValue ? 700 : 400,
                  color: item.value === activeTabsValue ? '#D81F1D' : '#8C8C8C',
                }}
                className={cn({
                  ['cockpit_container__echarts__menu__item-active']:
                    item.value !== activeTabsValue,
                })}
              >
                {item.label}
              </a>
              {item.value === activeTabsValue && (
                <div
                  style={{
                    width: '32px',
                    height: '2px',
                    background: '#D81F1D',
                    position: 'absolute',
                    bottom: 0,
                  }}
                ></div>
              )}
            </div>
          ))}
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '40px',
          }}
        >
          <div className="cockpit_container__echarts__main">
            <h3>
              <strong>义工分发放数据</strong>
            </h3>
            <div
              style={{ width: '90%', height: '250px' }}
              id="cockpit_echarts"
            />
          </div>
          <div className="cockpit_container__echarts__right">
            <h3>
              <strong>义工分消耗</strong>
            </h3>
            <div className="cockpit_container__echarts__right__item">
              <div className="cockpit_container__echarts__right__item__box">
                <h4>义工商城</h4>
                <div className="cockpit_container__echarts__right__item__label">
                  <h3>
                    <strong>{data?.integralExpendMall ?? 0}</strong>
                  </h3>
                </div>
              </div>
            </div>
            <div className="cockpit_container__echarts__right__item">
              <div className="cockpit_container__echarts__right__item__box">
                <h4>兑换群治分</h4>
                <div className="cockpit_container__echarts__right__item__label">
                  <h3>
                    <strong>{data?.integralExpendQunzhi ?? 0}</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default definePage(Cockpit);

import vsf from '@vs/vsf-boot';

let ossPolicy = null;

const getOssPolicy = async () => {
  if (ossPolicy) return ossPolicy;
  const { data } = await vsf.services.OssPolicyController_getOssPolicy_6df994();
  ossPolicy = data;
  return data;
};

export default getOssPolicy;

import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { PageRouterProps } from '@vs/vsf-boot/lib/esm/definePage';
import { Menu } from '@vs/vsf-kit';
import { cloneDeep, flatMapDeep } from 'lodash';
import React, { useEffect, useState } from 'react';

import { UserTypeEnum } from '@/config';
import routes from '@/router/routes';

const townPermissionList = [
  '/cockpit',
  '/teamManage',
  '/bannerManage',
  '/randomClap',
  '/activityManage',
  '/mallVoucherManage',
  '/personList',
  '/merchantList',
];

const villagePermissionList = ['/activityManage2'];

type OwnProps = {
  demo?: string;
} & PageRouterProps;

const Left: React.FC<OwnProps> = (props) => {
  const [openKeys, setOpenKeys] = useState<string[]>();
  const [items, setItems] = useState<any[]>([]);
  const [selectedKey, setSelectedKey] = useState<string>();

  useEffect(() => {
    const getItems = () => {
      const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');

      const tempMenu = cloneDeep(routes)
        ?.filter(
          (item) =>
            item?.label &&
            (userInfo.userType === UserTypeEnum.VILLAGE_ADMIN
              ? villagePermissionList?.includes(item.path)
              : townPermissionList?.includes(item.path)),
        )
        ?.map((route) => {
          return {
            key: route?.path,
            label: route?.label,
            icon: (
              <img
                style={{ width: '16px', height: '16px' }}
                src={
                  route?.path === '/cockpit' ? route?.selectIcon : route?.icon
                }
                alt=""
              />
            ),
            children: route?.routes
              ?.filter((item) => item?.label)
              ?.map((secRoute) => ({
                key: secRoute?.path,
                label: secRoute?.label,
              })),
          };
        });

      if (tempMenu)
        return [
          {
            label: '义工银行专区',
            key: 'index',
            children: tempMenu,
          },
        ];
      return [];
    };
    const items = getItems();
    setItems(items);
    const openKeys = flatMapDeep(getItems(), 'children').map(
      (item) => item.key,
    );
    setOpenKeys([...openKeys, 'index']);
  }, []);

  useEffect(() => {
    setSelectedKey(window.location.pathname);
  }, [props.routes, selectedKey]);

  const handelMenuItemClick = (e) => {
    const _key = e.key;
    const parentKey = `/${_key?.split('/')?.[1]}`;
    const parentNode = items?.[0]?.children?.find(
      (item) => item.key === parentKey,
    );
    const tempMenu = cloneDeep(routes);

    setItems(
      items?.map((item) => {
        return {
          ...item,
          children: item?.children?.map((_v) => {
            return {
              ..._v,
              icon:
                _v.key === parentNode?.key ? (
                  <img
                    style={{ width: '16px', height: '16px' }}
                    src={tempMenu?.find((vv) => vv.path === _v.key)?.selectIcon}
                    alt=""
                  />
                ) : (
                  <img
                    style={{ width: '16px', height: '16px' }}
                    src={tempMenu?.find((vv) => vv.path === _v.key)?.icon}
                    alt=""
                  />
                ),
            };
          }),
        };
      }),
    );

    vsf?.navigateTo(e.key);
  };

  return (
    <Menu
      selectedKeys={selectedKey ? [selectedKey] : ['/cockpit']}
      openKeys={openKeys}
      onOpenChange={(keys) => setOpenKeys(keys)}
      mode="inline"
      items={items}
      onClick={handelMenuItemClick}
    />
  );
};

export default definePage(Left);

export const STORAGE_KEY_PREFIX = 'volunteer-bank-admin';

/**
 * 统计查询范围
 */
export enum StatisticsQueryScopeEnum {
  YESTERDAY = 'YESTERDAY',
  SEVEN_DAY = 'SEVEN_DAY',
  THIRTY_DAY = 'THIRTY_DAY',
  TOTAL = 'TOTAL',
}

/**
 * 用户类型
 */
export enum UserTypeEnum {
  USER = 'USER',
  VILLAGE_ADMIN = 'VILLAGE_ADMIN',
  TOWN_ADMIN = 'TOWN_ADMIN',
  MERCHANT = 'MERCHANT',
}

/**
 * 审核状态
 */
export enum ReviewStatusEnum {
  NOT_REVIEW = 'NOT_REVIEW',
  REVIEW_PASS = 'REVIEW_PASS',
  REVIEW_REJECT = 'REVIEW_REJECT',
}

export default {};

import './BannerManage.less';

import { VSForm } from '@vs/vs-form';
import vsf from '@vs/vsf-boot';
import { definePage } from '@vs/vsf-boot';
import {
  Button,
  message,
  Modal,
  Page,
  Section,
  TabPane,
  Tabs,
  VSControlledForm,
  VSFormItem,
} from '@vs/vsf-kit';
import ImgCrop from 'antd-img-crop';
import React, { useCallback, useEffect, useState } from 'react';

import getOssPolicy from '@/utils/getOssPolicy';

const BannerManage: React.FC<React.Component> = (props) => {
  const [activityType, setActivityType] = useState('HOME');
  const [imgList, setImgList] =
    useState<BannerInfoEntranceWebVoHomepageBannerInfoVo[]>();
  const [imgUrl, setImgUrl] = useState<any[]>();
  const [editItem, setEditItem] = useState<any>();
  const [isCreat, setIsCreat] = useState<boolean>(true);
  const [modalVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (activityType === 'HOME') {
      vsf?.services
        ?.HomepageBannerInfo_getHomepageBannerInfoVoList_b46037?.()
        .then((res) => {
          if (res.code === 200) {
            setImgList(res.data);
          }
        });
    } else {
      vsf?.services
        ?.IntegralMallBannerInfo_getIntervalMallBannerInfoList_20f804?.()
        .then((res) => {
          if (res.code === 200) {
            setImgList(res.data);
          }
        });
    }
  }, [activityType]);

  // const ossKey = `${oss?.dir}/${new Date().getTime()}`;

  const handleBannerOpen = useCallback((item?) => {
    if (item?.id) {
      setIsCreat(false);
      setEditItem(item);
      setImgUrl([
        {
          url: item?.bannerImg,
        },
      ]);
    } else {
      setIsCreat(true);
    }
    setVisible(true);
  }, []);

  const handleBanner = () => {
    if (!imgUrl) return message.error('请上传Banner图片');
    if (isCreat) {
      if (activityType === 'HOME') {
        vsf?.services
          ?.HomepageBannerInfo_createHomepageBannerInfo_1b13a9?.({
            img: imgUrl?.[0]?.url,
          })
          .then((res) => {
            if (res.code === 200) {
              message.success('新增Banner成功');
              setVisible(false);
              setImgUrl([]);
              setTimeout(() => {
                vsf?.services
                  ?.HomepageBannerInfo_getHomepageBannerInfoVoList_b46037?.()
                  .then((res) => {
                    if (res.code === 200) {
                      setImgList(res.data);
                    }
                  })
                  .catch((err) => {
                    message.error(err);
                  });
              }, 1000);
            } else {
              message.error('新增Banner失败');
            }
          })
          .catch((err) => {
            message.error(err);
          });
      } else {
        vsf?.services
          ?.IntegralMallBannerInfo_createIntegralMallBannerInfo_2f151c?.({
            img: imgUrl?.[0]?.url,
          })
          .then((res) => {
            if (res.code === 200) {
              message.success('新增Banner成功');
              setVisible(false);
              setImgUrl([]);
              setTimeout(() => {
                vsf?.services
                  ?.IntegralMallBannerInfo_getIntervalMallBannerInfoList_20f804?.()
                  .then((res) => {
                    if (res.code === 200) {
                      setImgList(res.data);
                    } else {
                      message.error(res.message);
                    }
                  })
                  .catch((err) => {
                    message.error(err);
                  });
              }, 1000);
            } else {
              message.error('新增Banner失败');
            }
          })
          .catch((err) => {
            message.error(err);
          });
      }
    } else {
      // const _url = `${oss?.host}/${ossKey}`;
      if (activityType === 'HOME') {
        vsf?.services
          ?.HomepageBannerInfo_updateHomepageBannerInfo_3171e2?.({
            btoParam: {
              id: editItem.id,
              bannerImg: imgUrl?.[0]?.url,
            },
          })
          .then((res) => {
            if (res.code === 200) {
              message.success('修改Banner成功');
              setVisible(false);
              setImgUrl([]);
              vsf?.services
                ?.HomepageBannerInfo_getHomepageBannerInfoVoList_b46037?.()
                .then((res) => {
                  if (res.code === 200) {
                    setImgList(res.data);
                  }
                });
            } else {
              message.error(res.message);
            }
          })
          .catch((err) => {
            message.error(err);
          });
      } else {
        vsf?.services
          ?.IntegralMallBannerInfo_updateIntegralMallBannerInfo_89208d?.({
            btoParam: {
              id: editItem.id,
              bannerImg: imgUrl?.[0]?.url,
            },
          })
          .then((res) => {
            if (res.code === 200) {
              message.success('修改Banner成功');
              setVisible(false);
              setImgUrl([]);
              vsf?.services
                ?.IntegralMallBannerInfo_getIntervalMallBannerInfoList_20f804?.()
                .then((res) => {
                  if (res.code === 200) {
                    setImgList(res.data);
                  }
                });
            } else {
              message.error(res.message);
            }
          })
          .catch((err) => {
            message.error(err);
          });
      }
    }
  };

  const renderTabContent = (tab) => {
    return (
      <>
        <div className="banner_list">
          {imgList?.map((item) => {
            return (
              <div key={item?.id} className="banner_list_item">
                <div className="banner_list_item_box">
                  <div className="banner_list_item_img">
                    <img
                      style={{ width: '100%', height: '100%' }}
                      src={item?.bannerImg}
                      alt=""
                    />
                  </div>
                </div>
                <div className="banner_btn">
                  <div
                    className="banner_btn-item"
                    onClick={() => {
                      handleBannerOpen(item);
                    }}
                  >
                    编辑
                  </div>
                  <div className="banner_btn-line"></div>
                  <div
                    className="banner_btn-item"
                    onClick={() => {
                      if (tab === 'HOME') {
                        vsf?.services
                          ?.HomepageBannerInfo_deleteHomepageBannerInfo_4ebb25?.(
                            {
                              id: item?.id,
                            },
                          )
                          .then((res) => {
                            if (res.code === 200) {
                              message.success('删除成功');
                              vsf?.services
                                ?.HomepageBannerInfo_getHomepageBannerInfoVoList_b46037?.()
                                .then((res) => {
                                  if (res.code === 200) {
                                    setImgList(res.data);
                                  }
                                })
                                .catch((err) => {
                                  message.error(err);
                                });
                            }
                          })
                          .catch((err) => {
                            message.error(err);
                          });
                      } else {
                        vsf?.services
                          ?.IntegralMallBannerInfo_deleteIntegralMallBannerInfo_4fcdfa?.(
                            {
                              id: item?.id,
                            },
                          )
                          .then((res) => {
                            if (res.code === 200) {
                              message.success('删除成功');
                              vsf?.services
                                ?.IntegralMallBannerInfo_getIntervalMallBannerInfoList_20f804?.()
                                .then((res) => {
                                  if (res.code === 200) {
                                    setImgList(res.data);
                                  }
                                })
                                .catch((err) => {
                                  message.error(err);
                                });
                            }
                          })
                          .catch((err) => {
                            message.error(err);
                          });
                      }
                    }}
                  >
                    删除
                  </div>
                </div>
              </div>
            );
          })}
          {imgList && imgList.length < 5 && (
            <div
              className="banner_list_addbanner"
              onClick={() => {
                handleBannerOpen();
              }}
            >
              + 新增Banner
            </div>
          )}
        </div>
      </>
    );
  };
  return (
    <div className="bannerContainer">
      <Tabs
        activeKey={activityType}
        onChange={(_value) => {
          setActivityType(_value);
        }}
      >
        <TabPane key="HOME" tab="首页Banner">
          {renderTabContent('HOME')}
        </TabPane>
        <TabPane key="MALL" tab="积分商城Banner">
          {renderTabContent('MALL')}
        </TabPane>
      </Tabs>
      <Modal
        id="banner"
        title={isCreat ? '新建Banner' : '编辑Banner'}
        onOk={handleBanner}
        onCancel={() => {
          setVisible(false);
          setImgUrl([]);
        }}
        cancelText="取消"
        okText="保存"
        visible={modalVisible}
      >
        <VSForm
          initialValues={{ bannerImgs: imgUrl }}
          onChange={(v) => {
            setImgUrl(v?.bannerImgs?.map((img) => ({ url: img })));
          }}
        >
          <VSFormItem
            name={['bannerImgs']}
            label=""
            valueType="fileUpload"
            convertValue={(v) => {
              if (!v) {
                return v;
              }
              return v.map((vv) => {
                if (typeof vv === 'string') {
                  return { url: vv };
                }
                return vv;
              });
            }}
            transform={(v) => {
              return {
                bannerImgs: v?.map((vv) => {
                  return vv.url;
                }),
              };
            }}
            rules={[{ required: true, message: '请至少上传一张图片' }]}
            fieldProps={{
              container: (
                <ImgCrop
                  modalTitle="裁剪图片"
                  rotationSlider
                  aspect={activityType === 'HOME' ? 343 / 166 : 343 / 150}
                  showReset
                  modalWidth={1000}
                  children={<></>}
                />
              ),
              type: 'picture',
              getOssPolicy: getOssPolicy,
              showUploadList: false,
              limit: 500 * 1024,
              onLimitOverflow: (fileSize, limit) => {
                vsf.showToast('图片内存大小不可超过500kb', 'error');
              },
              uploadProps: {
                maxCount: 1,
                accept: '.jpg,.png,.jpeg',
              },
            }}
          />
        </VSForm>
      </Modal>
    </div>
  );
};

export default definePage(BannerManage);

import './ActivityProveVp.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Image,
  Input,
  message,
  Modal,
  Popover,
  Section,
  Space,
  Spin,
  Text,
  Todo,
  VSDataProvider,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import NOTREVIEW from '@/assets/images/not_review.png';
import PASS from '@/assets/images/review_pass.png';
import REJECT from '@/assets/images/review_reject.png';
import { ReviewStatusEnum } from '@/config';

const CustomerDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 90%;
  margin-bottom: 20px;

  .activity_img {
    width: 100px;
    height: 100px;
    margin: 0 10px;
    object-fit: cover;
  }
`;

const diyReasonList = [
  '请勿替其他人提交申请',
  '请不要重复申请',
  '请上传清晰的证明图片',
];

const ActivityProveVp = (props) => {
  const [activity, setActivity] = useState();
  const [loading, setLoading] = useState(false);
  const [checkVisible, setCheckVisible] = useState(false);
  const [detail, setDetail] = useState();
  const [rejectVisible, setRejectVisible] = useState(false);
  const rejectResonRef = useState('');
  const [currentIndex, setCurrentIndex] = useState();
  const [btnLoaing, setBtnLoading] = useState(false);
  const list = useRef([]);
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [isShowReason, setIsShowReason] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [time, setTime] = useState(3);
  const timeCount = useRef(3);

  const timer = useRef();

  useEffect(() => {
    if (time === 0) {
      clearInterval(timer);
    }
  }, [time]);

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.ActivityProofController_pageInvokeActivityProof_8c2cc5?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}), // 传入的额外参数
          },
        },
      );
    list.current = res?.data?.result ?? res?.data ?? [];
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const handelPreviousClick = () => {
    handelActionClick({}, list.current[currentIndex - 1]);
  };
  const _timer = () => {
    setInterval(() => {}, 5000);
  };

  const handelNextClick = () => {
    handelActionClick({}, list.current[currentIndex + 1]);
  };

  const handelRejectClick = () => {
    setRejectVisible(true);
  };

  const handelPassClick = async () => {
    setBtnLoading(true);
    try {
      const response =
        await vsf.services.ActivityProofController_updateActivityProofPass_6aff1d(
          {
            activityProofId: detail?.id,
          },
        );
      if (response && response.code === 200) {
        // handelNextClick();
        setTimeout(() => {
          setBtnLoading(false);
        }, 2000);
        message.success('已通过');
        setActivity({
          ...activity,
          notReviewNum:
            activity.notReviewNum - 1 <= 0 ? 0 : activity.notReviewNum - 1,
        });
        if (currentIndex < list.current.length - 1) {
          handelNextClick();
        } else {
          setIsRefresh(!isRefresh);
          setCheckVisible(false);
        }
      } else {
        setBtnLoading(false);
        message.error(response.message);
      }
    } catch (error) {
      setBtnLoading(false);
      console.error(error);
    }
  };

  const handelActionClick = async (e, data) => {
    const response =
      await vsf?.services?.ActivityProofController_getActivityProof_1bfcce({
        activityProofId: data.id,
      });
    if (response && response.code === 200) {
      setDetail(response.data);
      setCheckVisible(true);
      setCurrentIndex(list.current.findIndex((item) => item.id === data.id));
      if (data.reviewStatus !== 'NOT_REVIEW') {
        setIsShowDetail(true);
      } else {
        timer.current = setInterval(() => {
          timeCount.current = timeCount.current - 1;
          setTime(timeCount.current);
        }, 1000);
      }
    } else {
      message.error(response.message);
    }
  };

  const getImages = (value) => {
    try {
      return JSON.parse(value) ?? [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handelRejectSubmit = async (item) => {
    setBtnLoading(true);
    try {
      const response =
        await vsf.services.ActivityProofController_updateActivityProofReject_d0aafa(
          {
            activityProofId: detail?.id,
            notPassReason:
              typeof item === 'object' ? rejectResonRef.current : item,
          },
        );
      if (response && response.code === 200) {
        message.success('已拒绝');
        setTimeout(() => {
          setBtnLoading(false);
        }, 2000);
        setActivity({
          ...activity,
          notReviewNum:
            activity.notReviewNum - 1 <= 0 ? 0 : activity.notReviewNum - 1,
        });
        if (currentIndex < list.current.length - 1) {
          handelNextClick();
          setRejectVisible(false);
        } else {
          setTimeout(() => {
            setRejectVisible(false);
            setCheckVisible(false);
            setBtnLoading(false);
            setIsRefresh(!isRefresh);
          }, 2000);
        }
      } else {
        setBtnLoading(false);
        message.error(response.message);
      }
    } catch (error) {
      setBtnLoading(false);
      console.log(error);
    }
  };

  const diyReason = (
    <div>
      {diyReasonList?.map((item) => {
        return (
          <p
            key={item}
            onClick={() => {
              handelRejectSubmit(item);
            }}
          >
            {item}
          </p>
        );
      })}
    </div>
  );

  return (
    <div className="container-box" style={{ minHeight: '88vh' }}>
      <Section>
        <VSDataProvider
          onLoading={setLoading}
          onDataLoaded={setActivity}
          service={
            vsf.services
              .ActivityProofController_getActivityNameAndNotReviewNum_366172
          }
          params={{ activityId: props.id }}
        />
        <VSPromiseTable
          searchConfig={{
            optionButtons: ['search', 'reset'],
            collapsed: false,
            collapseRender: false,
          }}
          extraParams={{ activityIdIs: props.id, isRefresh }}
          onFetch={onFetch}
          cardProps={{
            bodyStyle: {
              padding: 20,
              borderRadius: '6px',
            },
          }}
        >
          <VSTableToolbar
            title={
              <div>
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <Space>
                    <Text>{activity?.activityName}</Text>
                    <Text color="var(--vs-colors-link-link)">{`目前有${
                      activity?.notReviewNum || 0
                    }条 未审核`}</Text>
                  </Space>
                )}
              </div>
            }
          />
          <VSTableColumn
            dataIndex={['initiateUserId', 'nickname']}
            title="申请人"
            align="center"
            valueType="text"
            search
            order={-1}
            columnKey={'nicknameLike'}
            fieldProps={{
              placeholder: '请输入申请人姓名',
            }}
          />

          <VSTableColumn
            dataIndex={['initiateUserId', 'userInfoDto', 'phone']}
            title="联系方式"
            align="center"
            valueType="text"
            search
            order={-3}
            columnKey={'initiatePhoneLike'}
            formItemProps={{
              rules: [
                {
                  message: '手机号长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1024,
                },
              ],
            }}
            fieldProps={{
              placeholder: '请输入申请人联系方式',
            }}
          />

          <VSTableColumn
            search
            order={0}
            hideInTable
            columnKey={'initiateTimeRangeIn'}
            valueType="dateRange"
            title="申请时间"
            align="center"
            searchConfig={{
              transform: (values) => {
                if (!Array.isArray(values)) return values;
                return ['initiateTimeRangeIn'].reduceRight(
                  (acc, curr) => ({ [curr]: acc }),
                  {
                    begin: values[0],
                    end: values[1],
                    beginInclude: true,
                    endInclude: true,
                  },
                );
              },
            }}
            fieldProps={{
              placeholder: ['开始时间', '结束时间'],
              separator: '至',
              valueType: 'string',
            }}
          />

          <VSTableColumn
            dataIndex={['initiateTime']}
            title="申请时间"
            align="center"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />

          <VSTableColumn
            dataIndex={['reviewStatus']}
            title="状态"
            align="center"
            valueType="select"
            search
            order={-4}
            columnKey={'reviewStatusIs'}
            dataSource={[
              { label: '未审核', value: 'NOT_REVIEW' },
              { label: '已通过', value: 'REVIEW_PASS' },
              { label: '未通过', value: 'REVIEW_REJECT' },
            ]}
            formItemProps={{
              label: '审核状态',
            }}
            render={(dom, data) => {
              if (data.reviewStatus === 'NOT_REVIEW') {
                return <span style={{ color: 'red' }}>{dom}</span>;
              }
              return dom;
            }}
            fieldProps={{
              placeholder: '请选择审核状态',
            }}
          />

          <VSTableColumn
            dataIndex={['reviewUser', 'nickname']}
            title="审核人"
            align="center"
            valueType="text"
            search
            order={-1}
            columnKey={'reviewNicknameLike'}
            render={(_, data) => {
              return data.reviewUser?.nickname ?? '-';
            }}
            fieldProps={{
              placeholder: '请输入审核人姓名',
            }}
          />

          <VSTableColumn
            title="操作"
            valueType="option"
            dataIndex="oper"
            align="center"
          >
            <VSTableAction
              children={(data) =>
                data.reviewStatus !== 'NOT_REVIEW' ? '查看详情' : '审核'
              }
              linkPage={{ updateTable: true }}
              show={(data) => !data.isHidden}
              onClick={handelActionClick}
            />
          </VSTableColumn>
        </VSPromiseTable>
        <Modal
          title={'活动审核'}
          open={checkVisible}
          width={700}
          onCancel={() => {
            setCheckVisible(false);
            setIsRefresh(!isRefresh);
          }}
          footer={false}
          style={{ position: 'relative' }}
        >
          <div className="activity__modal__box">
            <div className="activity__modal__box__item">
              <div className="activity__modal__box__item_left"> 凭 证 : </div>
              <div className="activity__modal__box__item_img">
                <CustomerDiv>
                  <Image.PreviewGroup>
                    {(getImages(detail?.proofImg) ?? [])?.map((item, _idx) => (
                      <Image className="activity_img" src={item} key={_idx} />
                    ))}
                  </Image.PreviewGroup>
                </CustomerDiv>
              </div>
            </div>

            <div className="activity__modal__box__item">
              <div className="activity__modal__box__item_left"> 申 请 人:</div>
              <div className="activity__modal__box__item_right">
                {detail?.initiateUserId?.userInfoDto?.name ?? ''}
              </div>
            </div>

            <div className="activity__modal__box__item">
              <div className="activity__modal__box__item_left">联系方式:</div>
              <div className="activity__modal__box__item_right">
                {detail?.initiateUserId?.userInfoDto?.phone ?? ''}
              </div>
            </div>

            {detail?.detailDescr ? (
              <>
                <div className="activity__modal__box__item">
                  <div className="activity__modal__box__item_left">
                    申请详情:
                  </div>
                  <div className="activity__modal__box__item_right">
                    {detail?.detailDescr ?? ''}
                  </div>
                </div>
              </>
            ) : (
              ''
            )}

            <div className="activity__modal__box__item_voice">
              {detail?.voice && <audio src={detail?.voice} controls></audio>}
            </div>

            {detail?.reviewUser?.nickname ? (
              <>
                <div className="activity__modal__box__item">
                  <div className="activity__modal__box__item_left">
                    审 核 人:
                  </div>
                  <div className="activity__modal__box__item_right">
                    {detail?.reviewUser?.nickname ?? ''}
                  </div>
                </div>
              </>
            ) : (
              ''
            )}

            {detail?.reviewStatus === ReviewStatusEnum.REVIEW_REJECT && (
              <div className="activity__modal__box__item">
                <div className="activity__modal__box__item_left">
                  未通过原因:
                </div>
                <div className="activity__modal__box__item_right">
                  {detail?.notPassReason ?? ''}
                </div>
              </div>
            )}
          </div>

          <div className="activity__modal__status">
            <img
              className="activity__modal__status__img"
              src={
                detail?.reviewStatus === ReviewStatusEnum.NOT_REVIEW
                  ? NOTREVIEW
                  : detail?.reviewStatus === ReviewStatusEnum.REVIEW_PASS
                  ? PASS
                  : detail?.reviewStatus === ReviewStatusEnum.REVIEW_REJECT
                  ? REJECT
                  : ''
              }
              alt=""
            />
          </div>

          <div
            className="aic jcsb"
            style={{ position: 'relative', marginTop: '30px' }}
          >
            <div
              style={
                currentIndex > 0 ? { cursor: 'pointer' } : { color: 'gray' }
              }
              onClick={currentIndex > 0 ? handelPreviousClick : null}
            >
              上一条
            </div>
            {!isShowDetail && (
              <>
                <div className="aic">
                  <Popover content={diyReason} trigger="hover">
                    <Button loading={btnLoaing} onClick={handelRejectClick}>
                      不通过
                    </Button>
                  </Popover>
                  <div style={{ width: 10 }} />
                  {time <= 0 && (
                    <Button
                      loading={btnLoaing}
                      type="primary"
                      onClick={handelPassClick}
                    >
                      通过
                    </Button>
                  )}
                  {time > 0 && (
                    <Button loading={btnLoaing} type="primary" disabled>
                      {time}s通过
                    </Button>
                  )}
                </div>
              </>
            )}

            <div
              style={
                currentIndex < list.current.length - 1
                  ? { cursor: 'pointer' }
                  : { color: 'gray' }
              }
              onClick={
                currentIndex < list.current.length - 1 > 0
                  ? handelNextClick
                  : null
              }
            >
              下一条
            </div>
          </div>
        </Modal>
        <Modal
          open={rejectVisible}
          onCancel={() => setRejectVisible(false)}
          onOk={handelRejectSubmit}
        >
          <div className="aic" style={{ marginTop: 30 }}>
            <h3 style={{ flex: 'none' }}>
              <strong>请输入原因：</strong>
            </h3>
            <Input
              maxLength={50}
              placeholder="请输入不通过原因"
              onChange={(e) => (rejectResonRef.current = e.target.value)}
            />
          </div>
        </Modal>
      </Section>
    </div>
  );
};
export default definePage(ActivityProveVp);

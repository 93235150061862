import {
  Avatar,
  compose,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import FileUpload, { FileUploadProps } from '../FileUpload';

export type UserAvatarProps = Pick<
  FileUploadProps,
  'ossParams' | 'getOssPolicy' | 'uploadProps'
> & {
  /**
   * 默认值
   */
  defaultValue?: string;
  /**
   * 值
   */
  value?: string;
  /**
   * 值变化回调
   */
  onChange?: (value?: string) => void;
};

/**
 * 头像展示
 */
const UserAvatar = (props: UserAvatarProps) => {
  const { defaultValue, value: valueProp, onChange, ...rest } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  const [origin, setOrigin] = useState<any>();

  const handleFileUpload = useCallback(
    (v) => {
      setOrigin([v?.[0]]);
      setValue(v?.[0]?.url);
    },
    [setValue, setOrigin],
  );

  /** 编写组件逻辑 */
  const fileValue = useMemo(() => {
    const target =
      value && origin && origin?.[0]?.url === value
        ? origin
        : value
        ? [
            {
              url: value,
              name: '头像',
            },
          ]
        : [];
    return target;
  }, [value, origin]);

  return (
    <div>
      <FileUpload
        type="picture"
        value={fileValue}
        onChange={handleFileUpload}
        {...rest}
        uploadProps={{
          maxCount: 1,
          ...(rest?.uploadProps ?? {}),
        }}
      />
    </div>
  );
};
UserAvatar.displayName = 'UserAvatar';

export default compose(
  withField<string>({
    name: 'UserAvatar',
  }),
  withPreview<UserAvatarProps>({
    renderPreview: (props) => {
      const { value } = props;

      /** 返回预览模式下的dom */
      return (
        <Avatar
          src={
            value ??
            'https://yigongyinhang.oss-cn-hangzhou.aliyuncs.com/community1693807234950.png'
          }
        />
      );
    },
  }),
)(UserAvatar);

import vsf, { createService, defineStore } from '@vs/vsf-boot';
import { isEmpty } from 'lodash';

const service = createService({
  url: '/api/db_enum/index',
});
const findChildren = (list = [], parent) => {
  return list
    .filter((item: any) => {
      if (isEmpty(item.parentCode) && isEmpty(parent.code)) return false;
      return item.parentCode === parent.code;
    })
    .map((item: any) => ({
      label: item.cname,
      value: item.code,
      children: findChildren(list, item),
      data: item,
    }));
};

export const loadEnums = async () => {
  if (Object.keys(vsf.stores.dbenums.enums).length !== 0) return;

  const enumNames = vsf.stores.dbenums.enumNames;
  if (enumNames.length <= 0) return;

  const res = await service();
  if (!res?.data) return;

  const fileMap = res.data as any[];
  const fileList = enumNames.map((e) => {
    const emap = fileMap.find((f) => f.enumName === e);
    return emap?.jsonFileUrl ?? '';
  });
  const fileSet = new Set<string>(fileList); // 去重
  const loadFilePromises = Array.from(fileSet)
    .filter((f) => !!f)
    .map((url) => {
      const getFileService = createService({ url: url, raw: true });
      return getFileService();
    });
  const files = await Promise.all(loadFilePromises);
  const data = {};
  files.forEach((f) => {
    Object.keys(f).forEach((fkey) => {
      data[fkey] = f[fkey]
        .filter((d) => !d.dbEnumParent) // 一级
        .map((d) => ({
          label: d.cname,
          value: d.code,
          children: findChildren(f[fkey], d),
          data: d,
        }));
    });
  });
  vsf.stores.dbenums.initEnums(data);
};

const dbenums = defineStore(
  {
    // state
    enums: {},
    enumNames: [],

    // action
    initEnums: (data) => (dbenums.enums = data),
  },
  'dbenums',
);

export default dbenums;

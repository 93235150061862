import AddMember from '@pages/AddMember';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  message,
  Modal,
  Pagination,
  Section,
  VSControlledForm,
  VSFormFooter,
  VSFormFooterSubmit,
  VSFormItem,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';

import styles from './MemberList.module.less';

const MemberList = (props) => {
  /**
   * 表格查询
   * @type {object}
   */
  const [total, setTotal] = useState(0);
  const [querys, setQuerys] = useState({ size: 10, page: 1 });
  /**
   * joinTeamId
   * @param {string} id
   * @param {object} params
   * @returns {object}
   */
  const joinTeamId = ({ id, params }) => {
    return { teamIdIs: id, ...(params || {}) };
  };

  const handleSetAdmin = useCallback(
    (v, record) =>
      new Promise((resolve) => {
        const setAdmin = (isAdmin) => {
          vsf.services
            .TeamMemberController_updateTeamMemberAdminStatus_c0565d({
              btoParam: {
                id: record.id,
                adminStatus: isAdmin,
              },
            })
            .then((res) => {
              const action = isAdmin ? '设置' : '取消';
              if (res?.code === 200) {
                message.success(`${action}成功`);

                setTimeout(() => {
                  vsf.refs.memberTable?.reload();
                }, 1000);
                resolve(true);
              } else {
                // message.error(`${action}失败: ` + res?.message ?? '请求失败');
                resolve(false);
              }
            });
        };

        if (record.adminStatus) {
          // 取消
          Modal.confirm({
            content: '确定取消管理员身份？',
            onOk: () => setAdmin(false),
          });
        } else {
          // 设置
          setAdmin(true);
        }
      }),
    [],
  );

  const handleDelete = useCallback(
    (v, record) =>
      new Promise((resolve) => {
        Modal.confirm({
          content: '确定从组织中删除该成员？',
          onOk: () => {
            vsf.services
              .TeamMemberController_deleteTeamMember_c563a2({
                teamMemberId: record.id,
              })
              .then((res) => {
                if (res?.code === 200) {
                  message.success(`删除成功`);

                  setTimeout(() => {
                    vsf.refs.memberTable?.reload();
                  }, 1000);
                  resolve(true);
                } else {
                  // message.error(`删除失败: ` + res?.message ?? '请求失败');
                  resolve(false);
                }
              });
          },
        });
      }),
    [],
  );

  const handleAddMember = useCallback(async () => {
    const user = vsf.refs.addMemberForm?.getFieldsFormatValue();

    if (user?.userInfoDto?.idNum?.length !== 18) {
      message.warning('请填写18位有效身份证');
      return false;
    }
    if (!user?.nickname) {
      message.warning('该用户不在系统内');
      return false;
    }

    const res = await vsf.services.TeamMemberController_createTeamMember_3753e6(
      {
        btoParam: {
          teamId: props.id,
          userId: user.id,
          adminStatus: !!user.adminStatus,
        },
      },
    );
    if (res?.code === 200) {
      message.success('新增成功');

      setTimeout(() => {
        vsf.refs.memberTable?.reload();
      }, 1000);

      return true;
    } else {
      // message.error('新增失败: ' + res.message ?? '请求错误');
      return false;
    }
  }, [props.id]);

  return (
    <Section className={styles['vs-form-footer']}>
      <div className="menberList__header">
        <VSControlledForm
          id="memberForm"
          value={querys}
          onSubmit={(_value) =>
            setQuerys({ ..._value, from: 0, size: 10, page: 1 })
          }
          layout="inline"
        >
          <VSFormItem
            name={['nicknameLike']}
            label="人员姓名"
            valueType="text"
            fieldProps={{}}
          />

          <VSFormItem
            name={['adminStatusIs']}
            label="人员类型"
            valueType="select"
            dataSource={[
              { label: '全部', value: 'ALL' },
              { label: '管理员', value: 'ADMIN' },
              { label: '成员', value: 'MEMBER' },
            ]}
            convertValue={(v) => {
              if (typeof v === 'string') return v;
              if (v === true) return 'ADMIN';
              if (v === false) return 'MEMBER';
              return 'ALL';
            }}
            transform={(v) => {
              if (v === 'ADMIN') return { adminStatusIs: true };
              if (v === 'MEMBER') return { adminStatusIs: false };
              return {};
            }}
            fieldProps={{}}
          />

          <VSFormFooter>
            <VSFormFooterSubmit children="查询" />

            <Button
              children="重置"
              onClick={() =>
                setQuerys({
                  from: 0,
                  size: 10,
                  orderList: [],
                  nicknameLike: undefined,
                  adminStatusIs: undefined,
                  page: 1,
                })
              }
            />
          </VSFormFooter>
        </VSControlledForm>
      </div>

      <div style={{ margin: '24px 0', display: 'flex' }} />

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          children="新增人员"
          type="primary"
          onClick={() => vsf.openModal('addMemberModal')}
          style={{ marginBottom: 30 }}
        />
      </div>
      <VSPromiseTable
        cardProps={{
          bodyStyle: {
            padding: 0,
          },
        }}
        editable={{ editType: 'none' }}
        id="memberTable"
        pagination={false}
        onFetch={async (params) => {
          const res =
            await vsf?.services?.TeamMemberController_pageTeamUser_240bfa?.({
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                teamIdIs: props.id,
                ...(params?.extra ?? {}),
              },
            });
          setTotal(res?.data?.count ?? res?.data?.length ?? 0);
          return {
            data: res?.data?.result ?? res?.data ?? [],
            total: res?.data?.count ?? res?.data?.length ?? 0,
          };
        }}
        extraParams={joinTeamId({ id: props.id, params: querys })}
      >
        <VSTableColumn
          dataIndex={['id']}
          title="编号"
          editable={false}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['user', 'nickname']}
          title="姓名"
          editable={false}
          valueType="text"
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['adminStatus']}
          title="角色"
          editable={false}
          valueType="text"
          render={(v, rec) => (rec.adminStatus ? '管理员' : '成员')}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['user', 'userInfoDto', 'phone']}
          title="联系方式"
          editable={false}
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn title="操作" valueType="text">
          <VSTableAction
            children={(record) =>
              `${record.adminStatus ? '取消' : '设置'}管理员`
            }
            onClick={handleSetAdmin}
          />
          <VSTableAction children="删除" onClick={handleDelete} />
        </VSTableColumn>
      </VSPromiseTable>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: '20px',
        }}
      >
        <Pagination
          current={querys.page}
          onChange={(page, pageSize) => {
            setQuerys({
              ...querys,
              size: pageSize,
              from: (page - 1) * pageSize,
              page,
            });
          }}
          total={total ?? 0}
        />
      </div>
      <Modal
        id="addMemberModal"
        title="新增人员"
        onOk={handleAddMember}
        okText="确定"
        cancelText="取消"
      >
        <AddMember />
      </Modal>
    </Section>
  );
};
export default definePage(MemberList);

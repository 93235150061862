import { createServices } from '@vs/vsf-boot';
export default createServices({
  TeamIntegralSerialController_createTeamIntegralSerial_778ecb: {
    method: 'post',
    url: '/api/integral_team/createTeamIntegralSerial',
    parameterFomatter: (data?: {
      btoParam: IntegralTeamServiceBtoCreateTeamIntegralSerialBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TeamIntegralSerialController_fallTeamIntegralSerial_c125d6: {
    method: 'post',
    url: '/api/integral_team/fallTeamIntegralSerial',
    parameterFomatter: (data?: {
      qto: IntegralTeamPersistQtoFallTeamIntegralSerialQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: IntegralTeamEntranceWebVoTeamIntegralSerialVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TeamIntegralSerialController_pageTeamIntegralSerialDetail_95156b: {
    method: 'post',
    url: '/api/integral_team/pageTeamIntegralSerialDetail',
    parameterFomatter: (data?: {
      qto: IntegralTeamPersistQtoPageTeamIntegralSerialDetailQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: IntegralTeamEntranceWebVoTeamIntegralSerialDetailVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TeamIntegralController_getTeamIntegral_d8ef22: {
    method: 'post',
    url: '/api/integral_team/getTeamIntegral',
    parameterFomatter: (data?: { teamId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: IntegralTeamEntranceWebVoTeamIntegralVo,
    ) => data,
  },
  TeamMonthSerialController_getTeamMonthSerial_2305a6: {
    method: 'post',
    url: '/api/integral_team/getTeamMonthSerial',
    parameterFomatter: (data?: { teamId: number; month: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: IntegralTeamEntranceWebVoTeamMonthSerialVo,
    ) => data,
  },
  CommunityConfigController_updateCommunityConfig_8bad86: {
    method: 'post',
    url: '/api/community_config/updateCommunityConfig',
    parameterFomatter: (data?: {
      btoParam: CommunityConfigPersistEoCommunityConfigEo;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  CommunityConfigController_getCommunityConfig_0c8b31: {
    method: 'post',
    url: '/api/community_config/getCommunityConfig',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: CommunityConfigPersistEoCommunityConfigEo,
    ) => data,
  },
  StatisticsInfoController_getIntegralPub_803898: {
    method: 'post',
    url: '/api/statistics_info/getIntegralPub',
    parameterFomatter: (data?: { queryScope: StatisticsQueryScopeEnum }) =>
      data,
    responseFormatter: (_, __, data?: StatisticsInfoPersistEoIntegralPubEo) =>
      data,
  },
  StatisticsInfoController_getStatisticsInfo_ad5044: {
    method: 'post',
    url: '/api/statistics_info/getStatisticsInfo',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: StatisticsInfoPersistEoStatisticsInfoEo,
    ) => data,
  },
  AccountInfoController_getAccountDetail_071d4f: {
    method: 'post',
    url: '/api/user_account/getAccountDetail',
    parameterFomatter: (data?: { userId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ComVsUserAccountEntranceWebVoAccountDetailVo,
    ) => data,
  },
  AccountInfoController_getLoginAccountDetail_94311d: {
    method: 'post',
    url: '/api/user_account/getLoginAccountDetail',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ComVsUserAccountEntranceWebVoAccountDetailVo,
    ) => data,
  },
  AccountInfoController_loginByWechat_645f63: {
    method: 'post',
    url: '/api/user_account/loginByWechat',
    parameterFomatter: (data?: { code: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ComVsUserAccountEntranceWebVoAccountInfoVo,
    ) => data,
  },
  AccountInfoController_getAccountDetailByIdNum_78688d: {
    method: 'post',
    url: '/api/user_account/getAccountDetailByIdNum',
    parameterFomatter: (data?: { idNum: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ComVsUserAccountEntranceWebVoAccountDetailVo,
    ) => data,
  },
  AccountInfoController_loginByMini_0185f2: {
    method: 'post',
    url: '/api/user_account/loginByMini',
    parameterFomatter: (data?: { code: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ComVsUserAccountEntranceWebVoAccountInfoVo,
    ) => data,
  },
  AccountInfoController_loginByUnionId_aca181: {
    method: 'post',
    url: '/api/user_account/loginByUnionId',
    parameterFomatter: (data?: { unionId: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: ComVsUserAccountEntranceWebVoAccountInfoVo,
    ) => data,
  },
  AccountInfoController_logout_0c48b9: {
    method: 'post',
    url: '/api/user_account/logout',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  OssPolicyController_getOssPolicy_6df994: {
    method: 'post',
    url: '/api/external_api/getOssPolicy',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: ExternalApiPersistEoOssPolicyEo) => data,
  },
  InvokeInfoController_createInvokeInfo_cbbc6e: {
    method: 'post',
    url: '/api/activity-info/create-invoke-info',
    parameterFomatter: (data?: {
      activityId: number;
      quiltCodeUserId: number;
    }) => data,
    responseFormatter: (_, __, data?: ActivityInfoEntranceWebVoInvokeInfoVo) =>
      data,
  },
  InvokeInfoController_createScanInvokeInfo_61b12c: {
    method: 'post',
    url: '/api/activity-info/createScanInvokeInfo',
    parameterFomatter: (data?: {
      activityId: number;
      scanCodeUserId: number;
    }) => data,
    responseFormatter: (_, __, data?: ActivityInfoEntranceWebVoInvokeInfoVo) =>
      data,
  },
  InvokeInfoController_iDCardRegistration_76003f: {
    method: 'post',
    url: '/api/activity-info/iDCardRegistration',
    parameterFomatter: (data?: { activityId: number; img: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ActivityProofController_createActivityProof_a1e426: {
    method: 'post',
    url: '/api/activity-info/createActivityProof',
    parameterFomatter: (data?: {
      btoParam: ActivityInfoServiceBtoCreateActivityProofBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityProofVo,
    ) => data,
  },
  ActivityProofController_fallActivityProofDetail_564141: {
    method: 'post',
    url: '/api/activity-info/fall-activity-proof-detail',
    parameterFomatter: (data?: {
      qto: ActivityInfoPersistQtoActivityProofDetailQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ActivityInfoEntranceWebVoActivityProofDetailVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ActivityProofController_getActivityNameAndNotReviewNum_366172: {
    method: 'post',
    url: '/api/activity-info/getActivityNameAndNotReviewNum',
    parameterFomatter: (data?: { activityId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityProofNameNumVo,
    ) => data,
  },
  ActivityProofController_pageInvokeActivityProof_8c2cc5: {
    method: 'post',
    url: '/api/activity-info/page-invoke-activity-proof',
    parameterFomatter: (data?: {
      qto: ActivityInfoPersistQtoActivityProofQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ActivityInfoEntranceWebVoActivityProofVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ActivityProofController_updateActivityProof_37f58a: {
    method: 'post',
    url: '/api/activity-info/update-activity-proof',
    parameterFomatter: (data?: {
      bto: ActivityInfoServiceBtoUpdateActivityProofBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityProofVo,
    ) => data,
  },
  ActivityInfoController_getActivityInfoDetail_60d54e: {
    method: 'post',
    url: '/api/activity-info/get-activity-info-detail',
    parameterFomatter: (data?: { activityId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityInfoDeleteVo,
    ) => data,
  },
  ActivityCategoryController_getOneCategoryList_72bb78: {
    method: 'post',
    url: '/api/activity-info/getOneCategoryList',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityCategoryVo[],
    ) => data,
  },
  ActivityInfoController_pageActivityInfo_df266a: {
    method: 'post',
    url: '/api/activity-info/pageActivityInfo',
    parameterFomatter: (data?: {
      qto: ActivityInfoPersistQtoPageActivityInfoQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ActivityInfoEntranceWebVoActivityInfoDetailVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ActivityInfoController_updateActivityInfoNotCycle_93b476: {
    method: 'post',
    url: '/api/activity-info/updateActivityInfoNotCycle',
    parameterFomatter: (data?: {
      btoParam: ActivityInfoServiceBtoUpdateActivityInfoBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityInfoDetailVo,
    ) => data,
  },
  ActivityProofController_getActivityProof_1bfcce: {
    method: 'post',
    url: '/api/activity_info-entrance-web/getActivityProof',
    parameterFomatter: (data?: { activityProofId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityProofVo,
    ) => data,
  },
  ActivityProofController_getUserProof_6dea9d: {
    method: 'post',
    url: '/api/activity_info-entrance-web/getUserProof',
    parameterFomatter: (data?: { activityId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityProofMiniVo,
    ) => data,
  },
  ActivityProofController_updateActivityProofPass_6aff1d: {
    method: 'post',
    url: '/api/activity_info-entrance-web/updateActivityProofPass',
    parameterFomatter: (data?: { activityProofId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityProofVo,
    ) => data,
  },
  ActivityProofController_updateActivityProofReject_d0aafa: {
    method: 'post',
    url: '/api/activity_info-entrance-web/updateActivityProofReject',
    parameterFomatter: (data?: {
      activityProofId: number;
      notPassReason: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityProofVo,
    ) => data,
  },
  ActivityInfoController_closeActivityCycle_326eb8: {
    method: 'post',
    url: '/api/activity_info-entrance-web/closeActivityCycle',
    parameterFomatter: (data?: { activityId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ActivityInfoController_createActivityInfo_dd2751: {
    method: 'post',
    url: '/api/activity_info-entrance-web/createActivityInfo',
    parameterFomatter: (data?: {
      btoParam: ActivityInfoServiceBtoCreateActivityInfoBto;
      cycleBto: ActivityInfoServiceBtoCreateActivityCycleBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityInfoDetailVo,
    ) => data,
  },
  ActivityInfoController_fallActivityInfo_174749: {
    method: 'post',
    url: '/api/activity_info-entrance-web/fallActivityInfo',
    parameterFomatter: (data?: {
      qto: ActivityInfoPersistQtoFallActivityInfoQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ActivityInfoEntranceWebVoActivityInfoVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ActivityInfoController_fallActivityInitiateInfo_8984f3: {
    method: 'post',
    url: '/api/activity_info-entrance-web/fallActivityInitiateInfo',
    parameterFomatter: (data?: {
      qto: ActivityInfoPersistQtoFallActivityUserInfoQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ActivityInfoEntranceWebVoActivityInfoVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ActivityInfoController_fallMicroWishActivityInfo_563971: {
    method: 'post',
    url: '/api/activity_info-entrance-web/fallMicroWishActivityInfo',
    parameterFomatter: (data?: {
      qto: ActivityInfoPersistQtoFallActivityInfoQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ActivityInfoEntranceWebVoActivityInfoVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ActivityInfoController_fallNewActivityInfo_341d35: {
    method: 'post',
    url: '/api/activity_info-entrance-web/fallNewActivityInfo',
    parameterFomatter: (data?: {
      qto: ActivityInfoPersistQtoFallActivityInfoQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ActivityInfoEntranceWebVoActivityInfoVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ActivityInfoController_getActivityInfo_39b6b7: {
    method: 'post',
    url: '/api/activity_info-entrance-web/getActivityInfo',
    parameterFomatter: (data?: { activityId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityInfoVo,
    ) => data,
  },
  ActivityInfoController_isToppingActivity_ae600d: {
    method: 'post',
    url: '/api/activity_info-entrance-web/isToppingActivity',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ActivityInfoController_updateActivityHiddenStatus_4689f5: {
    method: 'post',
    url: '/api/activity_info-entrance-web/updateActivityHiddenStatus',
    parameterFomatter: (data?: { activityId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityInfoDeleteVo,
    ) => data,
  },
  ActivityInfoController_updateActivityInfo_a5b91d: {
    method: 'post',
    url: '/api/activity_info-entrance-web/updateActivityInfo',
    parameterFomatter: (data?: {
      btoParam: ActivityInfoServiceBtoUpdateActivityInfoBto;
      cycleBto: ActivityInfoServiceBtoUpdateActivityCycleBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityInfoDetailVo,
    ) => data,
  },
  ActivityInfoController_updateActivityToppingStatus_6c6710: {
    method: 'post',
    url: '/api/activity_info-entrance-web/updateActivityToppingStatus',
    parameterFomatter: (data?: { activityId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityInfoDeleteVo,
    ) => data,
  },
  ActivityCategoryController_createActivityCategory_f8fd46: {
    method: 'post',
    url: '/api/activity_info-entrance-web/createActivityCategory',
    parameterFomatter: (data?: {
      btoParam: ActivityInfoServiceBtoCreateActivityCategoryBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityCategoryDetailVo,
    ) => data,
  },
  ActivityCategoryController_deleteActivityCategory_26ab3e: {
    method: 'post',
    url: '/api/activity_info-entrance-web/deleteActivityCategory',
    parameterFomatter: (data?: { activityCategoryId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityCategoryDetailVo,
    ) => data,
  },
  ActivityCategoryController_getActivityCategoryDetail_0c5bb6: {
    method: 'post',
    url: '/api/activity_info-entrance-web/getActivityCategoryDetail',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityCategoryDetailVo[],
    ) => data,
  },
  ActivityCategoryController_updateActivityCategory_e3c8d4: {
    method: 'post',
    url: '/api/activity_info-entrance-web/updateActivityCategory',
    parameterFomatter: (data?: {
      btoParam: ActivityInfoServiceBtoUpdateActivityCategoryBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityCategoryDetailVo,
    ) => data,
  },
  EnrollInfoController_cancelEnroll_71918f: {
    method: 'post',
    url: '/api/activity_info-entrance-web/cancelEnroll',
    parameterFomatter: (data?: { activityId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  EnrollInfoController_createEnroll_e6b1e1: {
    method: 'post',
    url: '/api/activity_info-entrance-web/createEnroll',
    parameterFomatter: (data?: { activityId: number }) => data,
    responseFormatter: (_, __, data?: ActivityInfoEntranceWebVoEnrollInfoVo) =>
      data,
  },
  EnrollInfoController_fallEnrollInfoUserInfo_025cc6: {
    method: 'post',
    url: '/api/activity_info-entrance-web/fallEnrollInfoUserInfo',
    parameterFomatter: (data?: { qto: ActivityInfoPersistQtoEnrollInfoQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ActivityInfoEntranceWebVoEnrollInfoVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  EnrollTeamInfoController_cancelTeamEnroll_0e468b: {
    method: 'post',
    url: '/api/activity_info-entrance-web/cancelTeamEnroll',
    parameterFomatter: (data?: { activityId: number; teamId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  EnrollTeamInfoController_createTeamEnroll_a8e9ed: {
    method: 'post',
    url: '/api/activity_info-entrance-web/createTeamEnroll',
    parameterFomatter: (data?: { activityId: number; teamId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoEnrollTeamInfoVo,
    ) => data,
  },
  ActivityLikeController_createActivityLike_0f66ab: {
    method: 'post',
    url: '/api/activity_info-entrance-web/createActivityLike',
    parameterFomatter: (data?: { activityId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityLikeVo,
    ) => data,
  },
  ActivityLikeController_deleteActivityLike_85253d: {
    method: 'post',
    url: '/api/activity_info-entrance-web/deleteActivityLike',
    parameterFomatter: (data?: { activityId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoActivityLikeVo,
    ) => data,
  },
  AuthorizedInfoController_createAuthorizedInfo_022af0: {
    method: 'post',
    url: '/api/activity_info-entrance-web/createAuthorizedInfo',
    parameterFomatter: (data?: { activityId: number; userId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ActivityInfoEntranceWebVoAuthorizedInfoVo,
    ) => data,
  },
  TeamReviewController_applyJoinTeam_2a940e: {
    method: 'post',
    url: '/api/team_info/applyJoinTeam',
    parameterFomatter: (data?: { teamId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TeamReviewController_fallTeamReviewUser_d4da16: {
    method: 'post',
    url: '/api/team_info/fallTeamReviewUser',
    parameterFomatter: (data?: { qto: TeamInfoPersistQtoFallTeamReviewQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TeamInfoEntranceWebVoTeamReviewUserVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TeamReviewController_fallUserReviewTeam_2f090e: {
    method: 'post',
    url: '/api/team_info/fallUserReviewTeam',
    parameterFomatter: (data?: {
      qto: TeamInfoPersistQtoFallUserReviewTeamQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TeamInfoEntranceWebVoUserReviewTeamVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TeamReviewController_inReviewForTeam_76c401: {
    method: 'post',
    url: '/api/team_info/inReviewForTeam',
    parameterFomatter: (data?: { teamId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  TeamReviewController_pageTeamReviewUser_391fce: {
    method: 'post',
    url: '/api/team_info/pageTeamReviewUser',
    parameterFomatter: (data?: { qto: TeamInfoPersistQtoPageTeamReviewQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TeamInfoEntranceWebVoTeamReviewUserVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TeamReviewController_teamReviewPass_294037: {
    method: 'post',
    url: '/api/team_info/teamReviewPass',
    parameterFomatter: (data?: { reviewId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TeamReviewController_teamReviewPassList_5b6b19: {
    method: 'post',
    url: '/api/team_info/teamReviewPassList',
    parameterFomatter: (data?: { reviewIds: number[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  TeamReviewController_teamReviewReject_407d84: {
    method: 'post',
    url: '/api/team_info/teamReviewReject',
    parameterFomatter: (data?: { reviewId: number; rejectReason: string }) =>
      data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TeamReviewController_teamReviewRejectList_2969ab: {
    method: 'post',
    url: '/api/team_info/teamReviewRejectList',
    parameterFomatter: (data?: { reviewIds: number[]; rejectReason: string }) =>
      data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  TeamInfoController_createTeamInfo_47e1f8: {
    method: 'post',
    url: '/api/team_info/createTeamInfo',
    parameterFomatter: (data?: {
      btoParam: TeamInfoServiceBtoCreateTeamInfoBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TeamInfoController_fallUserNotJoinTeam_6c51c9: {
    method: 'post',
    url: '/api/team_info/fallUserNotJoinTeam',
    parameterFomatter: (data?: { qto: TeamInfoPersistQtoFallTeamInfoQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TeamInfoEntranceWebVoTeamInfoVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TeamInfoController_getTeamInfoDetail_90cb08: {
    method: 'post',
    url: '/api/team_info/getTeamInfoDetail',
    parameterFomatter: (data?: { teamId: number }) => data,
    responseFormatter: (_, __, data?: TeamInfoEntranceWebVoTeamInfoDetailVo) =>
      data,
  },
  TeamInfoController_pageTeamInfo_d25e51: {
    method: 'post',
    url: '/api/team_info/pageTeamInfo',
    parameterFomatter: (data?: { qto: TeamInfoPersistQtoPageTeamInfoQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TeamInfoEntranceWebVoTeamInfoDetailVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TeamInfoController_updateTeamInfo_3ce494: {
    method: 'post',
    url: '/api/team_info/updateTeamInfo',
    parameterFomatter: (data?: {
      btoParam: TeamInfoServiceBtoUpdateTeamInfoBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TeamMemberController_createTeamMember_3753e6: {
    method: 'post',
    url: '/api/team_info/createTeamMember',
    parameterFomatter: (data?: {
      btoParam: TeamInfoServiceBtoCreateTeamMemberBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TeamMemberController_deleteTeamMember_c563a2: {
    method: 'post',
    url: '/api/team_info/deleteTeamMember',
    parameterFomatter: (data?: { teamMemberId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TeamMemberController_fallTeamUser_f616e7: {
    method: 'post',
    url: '/api/team_info/fallTeamUser',
    parameterFomatter: (data?: { qto: TeamInfoPersistQtoFallTeamUserQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TeamInfoEntranceWebVoTeamMemberUserVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TeamMemberController_fallUserTeam_67650b: {
    method: 'post',
    url: '/api/team_info/fallUserTeam',
    parameterFomatter: (data?: { qto: TeamInfoPersistQtoFallUserTeamQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TeamInfoEntranceWebVoUserTeamInfoVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TeamMemberController_pageTeamUser_240bfa: {
    method: 'post',
    url: '/api/team_info/pageTeamUser',
    parameterFomatter: (data?: { qto: TeamInfoPersistQtoPageTeamUserQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TeamInfoEntranceWebVoTeamMemberUserVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TeamMemberController_quitTeam_32d410: {
    method: 'post',
    url: '/api/team_info/quitTeam',
    parameterFomatter: (data?: { teamId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TeamMemberController_updateTeamMemberAdminStatus_c0565d: {
    method: 'post',
    url: '/api/team_info/updateTeamMemberAdminStatus',
    parameterFomatter: (data?: {
      btoParam: TeamInfoServiceBtoUpdateTeamMemberAddressStatusBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  TeamChangeRecordController_fallTeamChangeRecord_886e37: {
    method: 'post',
    url: '/api/team_info/fallTeamChangeRecord',
    parameterFomatter: (data?: {
      qto: TeamInfoPersistQtoFallTeamChangeRecordUserQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TeamInfoEntranceWebVoTeamChangeRecordVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TeamReviewAmountController_getTeamReviewAmount_b5145a: {
    method: 'post',
    url: '/api/team_info/getTeamReviewAmount',
    parameterFomatter: (data?: { teamId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: TeamInfoEntranceWebVoTeamReviewAmountVo,
    ) => data,
  },
  CommunityUserLikeController_communityLike_5a7e84: {
    method: 'post',
    url: '/api/community_info/communityLike',
    parameterFomatter: (data?: { communityId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  CommunityInfoController_createCommunityInfo_fc3dfa: {
    method: 'post',
    url: '/api/community_info/createCommunityInfo',
    parameterFomatter: (data?: {
      btoParam: CommunityInfoServiceBtoCreateCommunityInfoBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  CommunityInfoController_deleteCommunityInfo_57dbbd: {
    method: 'post',
    url: '/api/community_info/deleteCommunityInfo',
    parameterFomatter: (data?: { communityId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  CommunityInfoController_fallCommunityInfoDetail_d1046b: {
    method: 'post',
    url: '/api/community_info/fallCommunityInfoDetail',
    parameterFomatter: (data?: {
      qto: CommunityInfoPersistQtoFallCommunityInfoQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: CommunityInfoEntranceWebVoCommunityInfoDetailVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  CommunityInfoController_fallUserCommunityInfoDetail_e8e06a: {
    method: 'post',
    url: '/api/community_info/fallUserCommunityInfoDetail',
    parameterFomatter: (data?: {
      qto: CommunityInfoPersistQtoFallCommunityInfoQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: CommunityInfoEntranceWebVoCommunityInfoDetailVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  CommunityInfoController_getCommunityInfoDetail_013e6a: {
    method: 'post',
    url: '/api/community_info/getCommunityInfoDetail',
    parameterFomatter: (data?: { communityId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: CommunityInfoEntranceWebVoCommunityInfoDetailVo,
    ) => data,
  },
  CommunityInfoController_pageCommunityInfoBase_c98e2d: {
    method: 'post',
    url: '/api/community_info/pageCommunityInfoBase',
    parameterFomatter: (data?: {
      qto: CommunityInfoPersistQtoPageCommunityInfoQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: CommunityInfoEntranceWebVoCommunityInfoBaseVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  CommunityInfoController_reviewPassCommunity_8ba691: {
    method: 'post',
    url: '/api/community_info/reviewPassCommunity',
    parameterFomatter: (data?: { communityId: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  CommunityInfoController_reviewRejectCommunity_06a4f3: {
    method: 'post',
    url: '/api/community_info/reviewRejectCommunity',
    parameterFomatter: (data?: {
      btoParam: CommunityInfoServiceBtoCreateCommunityRejectRecordBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  CommunityInfoController_updateCommunityContent_930f69: {
    method: 'post',
    url: '/api/community_info/updateCommunityContent',
    parameterFomatter: (data?: {
      btoParam: CommunityInfoServiceBtoUpdateCommunityContentBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  CommunityInfoBaseDtoController_getCommunityInfoBaseById_28b407: {
    method: 'post',
    url: '/api/community_info/getCommunityInfoBaseById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: CommunityInfoManagerDtoCommunityInfoBaseDto,
    ) => data,
  },
  HomepageBannerInfo_createHomepageBannerInfo_1b13a9: {
    method: 'post',
    url: '/api/banner-info/createHomepageBannerInfo',
    parameterFomatter: (data?: { img: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BannerInfoEntranceWebVoHomepageBannerInfoVo,
    ) => data,
  },
  HomepageBannerInfo_deleteHomepageBannerInfo_4ebb25: {
    method: 'post',
    url: '/api/banner-info/deleteHomepageBannerInfo',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  HomepageBannerInfo_getHomepageBannerInfoById_6cd7d6: {
    method: 'post',
    url: '/api/banner-info/getHomepageBannerInfoById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: BannerInfoEntranceWebVoHomepageBannerInfoVo,
    ) => data,
  },
  HomepageBannerInfo_getHomepageBannerInfoVoList_b46037: {
    method: 'post',
    url: '/api/banner-info/getHomepageBannerInfoVoList',
    parameterFomatter: (data?: {
      qto: BannerInfoPersistQtoHomepageBannerInfoQtoSimple;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: BannerInfoEntranceWebVoHomepageBannerInfoVo[],
    ) => data,
  },
  HomepageBannerInfo_updateHomepageBannerInfo_3171e2: {
    method: 'post',
    url: '/api/banner-info/updateHomepageBannerInfo',
    parameterFomatter: (data?: {
      btoParam: BannerInfoServiceBtoUpdateHomepageBannerInfoBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: BannerInfoEntranceWebVoHomepageBannerInfoVo,
    ) => data,
  },
  IntegralMallBannerInfo_createIntegralMallBannerInfo_2f151c: {
    method: 'post',
    url: '/api/banner-info/createIntegralMallBannerInfo',
    parameterFomatter: (data?: { img: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: BannerInfoEntranceWebVoIntervalMallBannerInfoVo,
    ) => data,
  },
  IntegralMallBannerInfo_deleteIntegralMallBannerInfo_4fcdfa: {
    method: 'post',
    url: '/api/banner-info/deleteIntegralMallBannerInfo',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  IntegralMallBannerInfo_getIntegralMallBannerInfoById_7de2c1: {
    method: 'post',
    url: '/api/banner-info/getIntegralMallBannerInfoById',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: BannerInfoEntranceWebVoIntervalMallBannerInfoVo,
    ) => data,
  },
  IntegralMallBannerInfo_getIntervalMallBannerInfoList_20f804: {
    method: 'post',
    url: '/api/banner-info/getIntervalMallBannerInfoList',
    parameterFomatter: (data?: {
      qto: BannerInfoPersistQtoIntegralMallBannerInfoQtoSimple;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: BannerInfoEntranceWebVoIntervalMallBannerInfoVo[],
    ) => data,
  },
  IntegralMallBannerInfo_updateIntegralMallBannerInfo_89208d: {
    method: 'post',
    url: '/api/banner-info/updateIntegralMallBannerInfo',
    parameterFomatter: (data?: {
      btoParam: BannerInfoServiceBtoUpdateIntegralMallBannerInfoBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: BannerInfoEntranceWebVoIntervalMallBannerInfoVo,
    ) => data,
  },
  VoucherOrderContoller_confirmPay_925063: {
    method: 'post',
    url: '/api/mall-voucher/confirmPay',
    parameterFomatter: (data?: { voucherId: number; buyNum: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MallVoucherEntranceWebVoVoucherOrderVo[],
    ) => data,
  },
  VoucherOrderContoller_deleteVoucherOrder_c56d8d: {
    method: 'post',
    url: '/api/mall-voucher/deleteVoucherOrder',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  VoucherOrderContoller_fallUserMallVoucherMini_83c469: {
    method: 'post',
    url: '/api/mall-voucher/fallUserMallVoucherMini',
    parameterFomatter: (data?: { qto: MallVoucherPersistQtoVoucherOrderQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MallVoucherEntranceWebVoVoucherOrderMiniVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  VoucherOrderContoller_getVoucherOrderDetail_fb8e38: {
    method: 'post',
    url: '/api/mall-voucher/getVoucherOrderDetail',
    parameterFomatter: (data?: { voucherOrderId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MallVoucherEntranceWebVoVoucherOrderDetailVo,
    ) => data,
  },
  MallVoucherController_fallMallVoucherMini_a916b3: {
    method: 'post',
    url: '/api/mall-voucher/fallMallVoucherMini',
    parameterFomatter: (data?: { qto: MallVoucherPersistQtoMallVoucherQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MallVoucherEntranceWebVoMallVoucherMiniVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  MallVoucherController_getMallVoucherMiniInfo_946947: {
    method: 'post',
    url: '/api/mall-voucher/getMallVoucherMiniInfo',
    parameterFomatter: (data?: { mallVoucherId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MallVoucherEntranceWebVoMallVoucherMiniDetailVo,
    ) => data,
  },
  MallVoucherController_promptlyBuy_e10418: {
    method: 'post',
    url: '/api/mall-voucher/promptlyBuy',
    parameterFomatter: (data?: { mallVoucherId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MallVoucherEntranceWebVoMallVoucherPromptlyBuyVo,
    ) => data,
  },
  VoucherStatisticsInfo_getStatisticsMallVoucher_25150e: {
    method: 'post',
    url: '/api/mall-voucher/getStatisticsMallVoucher',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (
      _,
      __,
      data?: MallVoucherEntranceWebVoStatisticsMallVoucherVo,
    ) => data,
  },
  MallVoucherController_createMallVoucherInfo_f2514b: {
    method: 'post',
    url: '/api/mall_voucher-entrance-web/createMallVoucherInfo',
    parameterFomatter: (data?: {
      btoParam: MallVoucherServiceBtoCreateVoucherInfoBto;
      camBtoParam: MallVoucherServiceBtoCreateAssociationMerchantBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MallVoucherEntranceWebVoMallVoucherDetailVo,
    ) => data,
  },
  MallVoucherController_getMallVoucher_5fcff3: {
    method: 'post',
    url: '/api/mall_voucher-entrance-web/getMallVoucher',
    parameterFomatter: (data?: { mallVoucherId: number }) => data,
    responseFormatter: (_, __, data?: MallVoucherEntranceWebVoMallVoucherVo) =>
      data,
  },
  MallVoucherController_pageMallVoucherDetail_d4dcd8: {
    method: 'post',
    url: '/api/mall_voucher-entrance-web/pageMallVoucherDetail',
    parameterFomatter: (data?: {
      qto: MallVoucherPersistQtoMallVoucherDetailQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MallVoucherEntranceWebVoMallVoucherDetailVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  MallVoucherController_updateVoucherGroundingStatus_444f59: {
    method: 'post',
    url: '/api/mall_voucher-entrance-web/updateVoucherGroundingStatus',
    parameterFomatter: (data?: { mallVoucherId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MallVoucherEntranceWebVoMallVoucherDetailVo,
    ) => data,
  },
  MallVoucherController_updateVoucherInfo_b4a70c: {
    method: 'post',
    url: '/api/mall_voucher-entrance-web/updateVoucherInfo',
    parameterFomatter: (data?: {
      btoParam: MallVoucherServiceBtoUpdateVoucherInfoBto;
      camBtoParam: MallVoucherServiceBtoUpdateAssociationMerchantBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MallVoucherEntranceWebVoMallVoucherDetailVo,
    ) => data,
  },
  MallVoucherController_updateVoucherNotSuggest_10daec: {
    method: 'post',
    url: '/api/mall_voucher-entrance-web/updateVoucherNotSuggest',
    parameterFomatter: (data?: { mallVoucherId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MallVoucherEntranceWebVoMallVoucherDetailVo,
    ) => data,
  },
  MallVoucherController_updateVoucherOfflineStatus_882bca: {
    method: 'post',
    url: '/api/mall_voucher-entrance-web/updateVoucherOfflineStatus',
    parameterFomatter: (data?: { mallVoucherId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MallVoucherEntranceWebVoMallVoucherDetailVo,
    ) => data,
  },
  MallVoucherController_updateVoucherSuggest_ebf1b0: {
    method: 'post',
    url: '/api/mall_voucher-entrance-web/updateVoucherSuggest',
    parameterFomatter: (data?: { mallVoucherId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MallVoucherEntranceWebVoMallVoucherDetailVo,
    ) => data,
  },
  VoucherExchangeRecordController_createExchangeRecord_0f05a7: {
    method: 'post',
    url: '/api/mall_voucher-entrance-web/createExchangeRecord',
    parameterFomatter: (data?: { voucherId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MallVoucherEntranceWebVoVoucherExchangeRecordVo,
    ) => data,
  },
  VoucherExchangeRecordController_pageVoucherExchangeRecord_e606b1: {
    method: 'post',
    url: '/api/mall_voucher-entrance-web/pageVoucherExchangeRecord',
    parameterFomatter: (data?: {
      qto: MallVoucherPersistQtoVoucherExchangeRecordQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MallVoucherEntranceWebVoVoucherExchangeRecordVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },

  UserInfoIntegralController_getPagedByUserInfoIntegral_05337b: {
    method: 'post',
    url: '/api/volunteer-bank-user-account-entrance-web/get-paged-by-user-info-integral',
    parameterFomatter: (data?: {
      qto: ComVsUserAccountPersistQtoUserInfoIntegralQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ComVsUserAccountEntranceWebVoUserInfoIntegralVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserInfoIntegralController_updateUserFromQunzhi_f8a4b8: {
    method: 'post',
    url: '/api/volunteer-bank-user-account-entrance-web/update-user-from-qunzhi',
    parameterFomatter: (data?: { accountId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ComVsUserAccountEntranceWebVoUserInfoIntegralVo,
    ) => data,
  },

  UserInfoMerchantController_getPagedByUserInfoMerchant_32a6b3: {
    method: 'post',
    url: '/api/volunteer-bank-user-account-entrance-web/get-paged-by-user-info-merchant',
    parameterFomatter: (data?: {
      qto: ComVsUserAccountPersistQtoUserInfoMerchantQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ComVsUserAccountEntranceWebVoUserInfoMerchantVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  UserInfoMerchantController_updateMerchantName_466cd6: {
    method: 'post',
    url: '/api/volunteer-bank-user-account-entrance-web/update-merchant-name',
    parameterFomatter: (data?: { accountId: number; merchantName: string }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: ComVsUserAccountEntranceWebVoUserInfoMerchantVo,
    ) => data,
  },

  UserIntegralSerialOperationController_getPagedByUserIntegralSerialQto_24f346:
    {
      method: 'post',
      url: '/api/integral-user/get-paged-by-user-integral-serial-qto',
      parameterFomatter: (data?: {
        qto: IntegralUserPersistQtoUserIntegralSerialQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: {
          count: number;
          result: IntegralUserEntranceWebVoUserIntegralSerialOperationVo[];
          from: number;
          size: number;
          scrollId: string;
          hasMore: boolean;
        },
      ) => data,
    },
  UserIntegralSerialOperationController_handleUserIntegralSerial_e57684: {
    method: 'post',
    url: '/api/integral-user/handle-user-integral-serial',
    parameterFomatter: (data?: {
      btoParam: IntegralUserServiceBtoCreateUserIntegralSerialBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});

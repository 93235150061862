import TeamDetailVp from '@pages/TeamDetailVp';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';
const TeamDetail = (props) => {
  return (
    <Page title="">
      <TeamDetailVp id={props.routes.params?.id} />
    </Page>
  );
};
export default definePage(TeamDetail);

import './index.less';

import {
  compose,
  Input,
  Radio,
  Select,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { boolean } from 'yargs';

import { addCallback, loadSDK, removeCallback } from './load';

type MapBto = {
  clockScope?: boolean;
  /**
   * 有效半径
   * @format int64
   */
  effectiveRadius?: number;
  /** 打卡地址 */
  clockAddress?: string;
  /** 经度 */
  longitude?: string;

  /** 纬度 */
  latitude?: string;

  /** 打卡地点 */
  mapName?: string;

  /** 是否禁用*/
  disabled?: boolean;
};

export type MapProps = {
  /**
   * 默认值
   */
  defaultValue?: MapBto;
  /**
   * 值
   */
  value?: MapBto;
  /**
   * 值变化回调
   */
  onChange?: (value?: MapBto) => void;
  /**
   * 是否禁用
   */
  disabled?: boolean;
};

const radioOptions = [
  { value: true, label: '设置', key: '1' },
  { value: false, label: '不设置', key: '2' },
];

const selectOptions = [
  { value: 50, label: '50m' },
  { value: 100, label: '100m' },
  { value: 150, label: '150m' },
  { value: 300, label: '300m' },
  { value: 400, label: '400m' },
  { value: 500, label: '500m' },
];

const defaultLocation = {
  lat: 30.245772,
  lng: 120.210042,
};

/**
 * Map
 */
const Map = (props: MapProps) => {
  const { defaultValue, value: valueProp, onChange, disabled } = props;

  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  const [isLoad, setIsLoad] = useState(false);

  const effectiveRadius = useRef(50);
  const [searchKey, setSearchKey] = useState('');

  const search = useRef<any>();
  const suggest = useRef<any>();
  const map = useRef<any>();
  const marker = useRef<any>();
  const circle = useRef<any>();
  const domRef = useRef<HTMLDivElement | null>(null);
  const [suggestionList, setSuggestionList] = useState<any>([]);
  const [suggestionVisible, setSuggestionVisible] = useState(false);

  const infoWindowList = Array(10);

  const setMap = useCallback(
    (instance: any) => {
      if (domRef.current) {
        const mapIns = new instance.Map(domRef.current, {});
        map.current = mapIns;
        // console.log();
        search.current = new instance.service.Search({ pageSize: 10 }); // 新建一个地点搜索类
        suggest.current = new instance.service.Suggestion({
          // 新建一个关键字输入提示类
          pageSize: 10, // 返回结果每页条目数
          region: '浙江', // 限制城市范围
          // center: new _window.TMap.LatLng(39.9178032, -82.7347142),
          // regionFix: true, // 搜索无结果时是否固定在当前城市
        });

        setIsLoad(true);
      }
    },
    [domRef],
  );

  useEffect(() => {
    const callback = setMap;
    addCallback(callback);
    loadSDK();
    return () => {
      removeCallback(callback);
    };
  }, [setMap]);

  useEffect(() => {
    if (isLoad && value) {
      setLocation(
        value?.latitude
          ? {
              lat: value.latitude,
              lng: value.longitude,
            }
          : defaultLocation,
      );
    }
  }, [isLoad, value]);
  useEffect(() => {
    setSearchKey(value?.mapName ?? '');
  }, [value]);

  const handelSelect = (e) => {
    effectiveRadius.current = e;
    setValue({
      ...value,
      effectiveRadius: e,
    });
  };

  const setLocation = (location) => {
    const _window: any = window;
    const position = new _window.TMap.LatLng(location.lat, location.lng);

    const builtStyle = (opt: { [key: string]: any }) => {
      const _window: any = window;
      const styledCircle = {};
      Object.keys(opt).forEach((k) => {
        styledCircle[k] = new _window.TMap.CircleStyle(opt[k]);
      });
      return styledCircle;
    };

    map.current.setCenter(position);
    if (marker.current) {
      marker.current.setMap(null);
    }
    marker.current = new _window.TMap.MultiMarker({
      map: map.current,
      geometries: [
        {
          position,
        },
      ],
    });
    if (circle.current) {
      circle.current.setMap(null);
    }
    circle.current = new _window.TMap.MultiCircle({
      map: map.current,
      styles: builtStyle({
        circle: {
          color: 'rgba(41,91,255,0.2)',
          showBorder: false,
          borderColor: 'rgba(41,91,255,1)',
          borderWidth: 2,
        },
      }),
      geometries: [
        {
          styleId: 'circle',
          center: position,
          radius: effectiveRadius.current,
        },
      ],
    });
  };
  const setSuggestion = (index) => {
    // 点击输入提示后，于地图中用点标记绘制该地点，并显示信息窗体，包含其名称、地址等信息
    infoWindowList.length = 0;
    setSuggestionVisible(false);
    // setSearchKey(suggestionList[index]?.title);
    setValue({
      ...value,
      clockScope: value === undefined ? true : value?.clockScope,
      effectiveRadius: effectiveRadius.current,
      clockAddress: suggestionList[index]?.address,
      longitude: suggestionList[index]?.location?.lng,
      latitude: suggestionList[index]?.location?.lat,
      mapName: suggestionList[index]?.title,
    });
    setLocation(suggestionList[index].location);
  };

  const getSuggestions = () => {
    try {
      const _window: any = window;
      if (searchKey) {
        suggest.current
          .getSuggestions({
            keyword: searchKey,
            location: new _window.TMap.LatLng(
              defaultLocation.lat,
              defaultLocation.lng,
            ),
          })
          .then((result) => {
            setSuggestionList(result.data);
            setSuggestionVisible(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (e) {
      console.error(e);
    }
  };

  /** 编写组件逻辑 */
  return (
    <div>
      <div className="aic">
        <div className="ant-col ant-col-4 ant-form-item-label">打卡范围：</div>
        <Radio.Group
          value={radioOptions.find(
            (item) =>
              item.value === (value === undefined ? false : value?.clockScope),
          )}
          dataSource={radioOptions}
          onChange={(e) => {
            setValue({
              ...value,
              clockScope: e,
            });
          }}
          disabled={disabled}
        />
        {(value === undefined ? false : value?.clockScope) && (
          <>
            有效半径选择
            <div style={{ width: 10 }} />
            <Select
              width={100}
              value={value?.effectiveRadius}
              dataSource={selectOptions}
              onChange={handelSelect}
              disabled={disabled}
            />
          </>
        )}
      </div>
      {(value === undefined ? false : value?.clockScope) && (
        <div style={{ marginTop: 20 }}>
          <div className="aic">
            <div className="ant-col ant-col-4 ant-form-item-label">
              范围搜索：
            </div>
            <Input
              id="keyword"
              width={500}
              placeholder="请输入"
              value={searchKey}
              onChange={(e) => {
                setSearchKey(e.target.value);
                getSuggestions();
              }}
              disabled={disabled}
            />
          </div>
          <ul id="suggestionList">
            {suggestionVisible &&
              suggestionList?.map((item, index) => (
                <li key={index}>
                  <a href="#" onClick={() => setSuggestion(index)}>
                    {item.title}
                    <span className="item_info">{item.address}</span>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      )}
      {
        <div
          style={{ boxSizing: 'border-box', paddingLeft: 290, paddingTop: 20 }}
        >
          <div
            ref={(r) => {
              domRef.current = r;
            }}
            style={{
              width: 300,
              height: (value === undefined ? false : value?.clockScope)
                ? 300
                : 1,
              // visibility: (value === undefined ? false : value?.clockScope)
              //   ? 'visible'
              //   : 'hidden',
            }}
          />
        </div>
      }
    </div>
  );
};
Map.displayName = 'Map';

export default compose(
  withField<string>({
    name: 'Map',
  }),
  withPreview<MapProps>({
    renderPreview: (props) => {
      const { value } = props;

      /** 返回预览模式下的dom */
      return <>地址：{value?.clockAddress}</>;
    },
  }),
)(Map);

import vsService from '@vs/service';
import vsf from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';
import { createBrowserHistory } from 'history';

import storage from '@/utils/storage';

const history = createBrowserHistory();

vsService.interceptors.response.use(
  (res) => {
    if (res?.data?.code === 401 && res?.data?.errMsg === 'Not logged in!')
      return res;
    if (res.status !== 200 || res?.data?.code !== 200) {
      message.error(res?.data?.message || '请求错误');
      if (res.data?.code === 301 || res.data?.code === 302) {
        console.log(vsf);
        vsf?.history.replace(`/Login`);
      }
    }
    return res;
  },
  (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 301:
          vsf?.history.replace(`/Login`);
      }
    }
    return Promise.reject(err);
  },
);

vsService.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    const userData = storage.get('userData');
    if (userData) {
      config.headers['Authorization'] = `Bearer ${userData}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export default vsService;

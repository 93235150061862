import EditTeam from '@pages/EditTeam';
import MemberCheck from '@pages/MemberCheck';
import MemberList from '@pages/MemberList';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Badge,
  Breadcrumb,
  Button,
  message,
  Section,
  TabPane,
  Tabs,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';

const TeamDetailVp = (props) => {
  /**
   * 是否编辑态
   * @type {boolean}
   */
  const [isInfoEdit, setIsInfoEdit] = useState();
  const [info, setInfo] = useState({});
  const [count, setCount] = useState(0);
  /**
   * 编辑态转readonly
   * @param {boolean} isEdit
   * @returns {boolean}
   */
  const edit2readonly = (isEdit) => {
    return !isEdit;
  };

  const updateCount = useCallback(() => {
    vsf.services
      .TeamReviewAmountController_getTeamReviewAmount_b5145a({
        teamId: props.id,
      })
      .then((res) => {
        setCount(res?.data?.notReviewAmount ?? 0);
      });
  }, [props.id]);

  useEffect(() => {
    vsf.services
      .TeamInfoController_getTeamInfoDetail_90cb08({
        teamId: props.id,
      })
      .then((res) => {
        setInfo(res?.code === 200 ? res?.data : {});
      });

    updateCount();
  }, [props.id, updateCount]);

  const handleSave = useCallback(async () => {
    const isHaveValues = await vsf.refs.editTeamDetailForm
      ?.validateFieldsReturnFormatValue()
      .catch((error) => {
        console.log(error);
      });
    if (isHaveValues) {
      const values = vsf.refs.editTeamDetailForm?.getFieldsFormatValue() ?? {};
      const res = await vsf.services.TeamInfoController_updateTeamInfo_3ce494({
        btoParam: {
          ...values,
          id: props.id,
        },
      });
      if (res?.code === 200) {
        message.success('保存成功');
        setIsInfoEdit(false);
      } else {
        // message.error('保存失败：' + res?.message ?? '请求错误');
      }
    }
  }, [props.id]);

  const handleMemberChecked = useCallback(() => {
    updateCount();
  }, [updateCount]);

  return (
    <div>
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item
            style={{ cursor: 'pointer' }}
            onClick={() => {
              vsf.navigateTo('/teamManage/teamList');
            }}
          >
            团队列表
          </Breadcrumb.Item>
          <Breadcrumb.Item>团队详情</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Section
        title="团队详情"
        style={{
          backgroundColor: '#ffffff',
          padding: '20px',
          minHeight: '88vh',
          borderRadius: '6px',
        }}
        extra={
          <Button
            type="primary"
            onClick={() => {
              vsf.navigateTo('/teamManage/teamList');
            }}
          >
            返回
          </Button>
        }
      >
        <Tabs defaultActiveKey="INFO">
          <TabPane key="INFO" tab="团队信息">
            <EditTeam
              info={info}
              readonly={edit2readonly(isInfoEdit)}
              isInfoEdit={isInfoEdit}
            />
            {!isInfoEdit && (
              <Button
                children="编辑"
                type="primary"
                onClick={() => setIsInfoEdit(true)}
              />
            )}

            {isInfoEdit && (
              <>
                <Button
                  children="取消"
                  onClick={() => {
                    vsf.refs?.editTeamDetailForm?.setFieldsValue?.(info);
                    setIsInfoEdit(false);
                  }}
                />
                <Button
                  children="保存"
                  type="primary"
                  onClick={handleSave}
                  style={{ marginLeft: 10 }}
                />
              </>
            )}
          </TabPane>
          <TabPane key="MEMBER" tab="人员管理">
            <MemberList id={props.id} />
          </TabPane>
          <TabPane
            key="MEMBER_CHECK"
            tab={
              <Badge count={count} size="small">
                <span>人员审核</span>
              </Badge>
            }
          >
            <MemberCheck id={props.id} onChange={handleMemberChecked} />
          </TabPane>
        </Tabs>
      </Section>
    </div>
  );
};
export default definePage(TeamDetailVp);

import './MallVoucherListVp.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Breadcrumb,
  Button,
  Image,
  Pagination,
  Section,
  Space,
  Spin,
  Text,
  VSControlledForm,
  VSDataProvider,
  VSForm,
  VSFormFooter,
  VSFormFooterSubmit,
  VSFormItem,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import { now, values } from 'lodash';
import React, { useCallback, useState } from 'react';

import styles from './TeamListVp.module.less';

const MallVoucherListVp = (props) => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState();
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [querys, setQuerys] = useState({ size: 10, page: 1 });
  const convert = (data) => {
    const { voucherEffectiveStart, voucherEffectiveEnd, ...rest } = data;
    return {
      voucherEffective:
        voucherEffectiveStart && voucherEffectiveEnd
          ? {
              start: voucherEffectiveStart,
              end: voucherEffectiveEnd,
            }
          : undefined,
      ...rest,
    };
  };

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.MallVoucherController_pageMallVoucherDetail_d4dcd8?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 5,
            orderList: params.orderList, // 排序结果
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}), // 传入的额外参数
          },
        },
      );
    const data = res?.data?.result ?? res?.data ?? [];
    setTotal(res?.data?.count ?? res?.data?.length ?? 0);
    return {
      data: data.map((d) => convert(d)),
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  return (
    <div style={{ minHeight: '88vh' }} className="container-box">
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb routes={[{ breadcrumbName: '商券列表' }]} />
      </div>
      <Section className={styles['vs-form-footer']}>
        <div className="container__team-headr">
          <VSControlledForm
            id="form"
            layout="inline"
            value={querys}
            onSubmit={(_value) =>
              setQuerys({ ..._value, from: 0, size: 10, page: 1 })
            }
          >
            <VSFormItem
              name={['voucherName']}
              label="商券名称"
              valueType="text"
              fieldProps={{
                placeholder: '请输入商券名称',
              }}
            />
            <VSFormItem
              name={['voucherPriceRangeIn']}
              label="商券价值"
              valueType="digitRange"
              fieldProps={{
                placeholder: '请输入',
              }}
              transform={(values) => {
                if (!Array.isArray(values)) return values;
                return {
                  voucherPriceRangeIn: {
                    begin: values[0],
                    end: values[1],
                    beginInclude: true,
                    endInclude: true,
                  },
                };
              }}
            />
            <VSFormItem
              name={['identityNameLike']}
              label="核销商家"
              valueType="text"
              fieldProps={{
                placeholder: '请输入商家名称',
              }}
            />
            <VSFormItem
              name={['voucherStatusIs']}
              label="商券状态"
              valueType="select"
              dataSource={[
                { value: true, label: '上架' },
                { value: false, label: '下架' },
              ]}
              fieldProps={{
                placeholder: '请选择商券状态',
                width: 160,
              }}
            />

            <VSFormFooter>
              <VSFormFooterSubmit children="查询" />
              <Button
                children="重置"
                onClick={() =>
                  setQuerys({
                    page: 1,
                    size: 10,
                    from: 0,
                    voucherNameLike: undefined,
                    identityNameLike: undefined,
                    voucherStatusIs: undefined,
                    voucherPriceRangeIn: undefined,
                  })
                }
              />
            </VSFormFooter>
          </VSControlledForm>
        </div>
      </Section>
      <div className="container__content">
        <VSDataProvider
          onLoading={setLoading}
          onDataLoaded={setInfo}
          service={
            vsf.services.VoucherStatisticsInfo_getStatisticsMallVoucher_25150e
          }
          config={{ count }}
        />
        <VSPromiseTable
          id="table"
          cardProps={{
            bodyStyle: {
              padding: 0,
            },
          }}
          onFetch={onFetch}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            defaultPageSize: 10,
          }}
          extraParams={querys}
        >
          <VSTableToolbar
            title={
              <div style={{ marginBottom: '10px' }}>
                {loading ? (
                  <Spin size="small" />
                ) : (
                  <Text>{`总计${info?.totalVoucher || 0}个商券，上架商券${
                    info?.groundingNum || 0
                  }个，下架商券${info?.offlineNum || 0}个，已兑换积分${
                    info?.alreadyExchangeIntegralNum || 0
                  }`}</Text>
                )}

                <div
                  style={{ position: 'absolute', right: '20px', top: '0px' }}
                >
                  <Button
                    type="primary"
                    onClick={() => vsf.navigateTo(`/mallVoucherManage/add`)}
                  >
                    新增商券
                  </Button>
                </div>
              </div>
            }
          />
          <VSTableColumn
            dataIndex={['id']}
            title="商券id"
            align="center"
            editable={false}
            valueType="digit"
            width={70}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['voucherImg']}
            title="商券主图"
            align="center"
            width={100}
            editable={false}
            render={(_, data) => {
              return <Image src={data.voucherImg?.[0]} />;
            }}
          />

          <VSTableColumn
            dataIndex={['voucherName']}
            title="商券名称"
            width={150}
            align="center"
            editable={false}
            valueType="text"
            order={-10}
            columnKey={'voucherNameLike'}
            formItemProps={{
              rules: [
                {
                  message: '商券名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1024,
                },
              ],
            }}
            fieldProps={{
              placeholder: '请输入商券名称',
            }}
          />

          <VSTableColumn
            dataIndex={['voucherPrice']}
            title="商券价值"
            width={70}
            align="center"
            editable={false}
            valueType="digit"
            render={(_, data) => {
              return data.voucherPrice || 0;
            }}
          />

          <VSTableColumn
            dataIndex={['residueStock']}
            title="剩余库存数"
            align="center"
            width={70}
            editable={false}
            valueType="digit"
            render={(_, data) => {
              return data.residueStock || 0;
            }}
          />

          <VSTableColumn
            dataIndex={['notUsedStock']}
            title="未使用数"
            width={70}
            align="center"
            editable={false}
            valueType="digit"
            render={(_, data) => {
              return data.notUsedStock || 0;
            }}
          />

          <VSTableColumn
            dataIndex={['exchangeNum']}
            title="已核销数"
            width={70}
            sorter
            align="center"
            editable={false}
            valueType="digit"
            render={(_, data) => {
              return data.exchangeNum || 0;
            }}
          />
          <VSTableColumn
            dataIndex={'voucherEffective'}
            title="商券有效期"
            align="center"
            width={200}
            render={(_, data) => {
              if (!data.voucherEffective) {
                return '-';
              }
              return `${dayjs(data.voucherEffective.start).format(
                'YY-MM-DD HH:mm',
              )} 至 ${dayjs(data.voucherEffective.end).format(
                'YY-MM-DD HH:mm',
              )}`;
            }}
          >
            {/* <VSTableColumn
          dataIndex={['voucherEffectiveStart']}
          title="开始"
          width={100}
          align="center"
          editable={false}
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['voucherEffectiveEnd']}
          title="结束"
          width={100}
          align="center"
          editable={false}
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        /> */}
          </VSTableColumn>
          <VSTableColumn
            dataIndex={['identityNameLike']}
            title="核销商家"
            hideInTable
            editable={false}
            valueType="text"
            columnKey={'identityNameLike'}
            formItemProps={{
              rules: [
                {
                  message: '核销商家长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1024,
                },
              ],
            }}
            // transform={(v) => {
            //   return v;
            // }}
            fieldProps={{
              placeholder: '请输入商家名称',
            }}
          />

          <VSTableColumn
            dataIndex={['voucherStatus']}
            title="商券状态"
            hideInTable
            editable={false}
            valueType="select"
            dataSource={[
              { value: true, label: '上架' },
              { value: false, label: '下架' },
            ]}
            columnKey={'voucherStatusIs'}
            fieldProps={{}}
          />

          <VSTableColumn
            title="操作"
            formItemProps={{ labelCol: 4, wrapperCol: 16 }}
            valueType="option"
            dataIndex="oper"
            width={150}
          >
            <VSTableAction
              children="商券详情"
              onClick={(e, data) => {
                vsf.navigateTo(`/mallVoucherManage/edit/${data.id}`);
              }}
            />

            <VSTableAction
              children={(data) => (data.voucherStatus ? '下架' : '上架')}
              linkPage={{
                updateTable: true,
                onLinkPageValidate: async (data) => {
                  const effectTime = data?.voucherEffective.end;
                  const text =
                    data.voucherStatus || dayjs().isAfter(effectTime)
                      ? '上架'
                      : '下架';
                  const text1 = !(
                    data.voucherStatus || dayjs().isAfter(effectTime)
                  )
                    ? '上架'
                    : '下架';
                  try {
                    const request = data.voucherStatus
                      ? vsf.services
                          .MallVoucherController_updateVoucherOfflineStatus_882bca
                      : vsf.services
                          .MallVoucherController_updateVoucherGroundingStatus_444f59;
                    const res = await request?.({ mallVoucherId: data.id });
                    if (res?.code === 200) {
                      if (info) {
                        // 本地更新数据
                        const {
                          groundingNum = 0,
                          offlineNum = 0,
                          ...rest
                        } = info;
                        if (data.voucherStatus) {
                          setInfo({
                            ...rest,
                            groundingNum: Math.max(groundingNum - 1, 0),
                            offlineNum: offlineNum + 1,
                          });
                        } else {
                          setInfo({
                            ...rest,
                            groundingNum: groundingNum + 1,
                            offlineNum: Math.max(offlineNum - 1, 0),
                          });
                        }
                      } else {
                        // 让dataProvider刷新
                        setCount(count + 1);
                      }
                      vsf.showToast(`商券${text1}成功`, 'success');
                      return true;
                    }
                  } catch (e) {
                    //
                  }
                  vsf.showToast(`商券${text1}失败`, 'error');
                  return false;
                },
                updateType: 'reload',
              }}
              disabled={(data) => {
                return dayjs().isAfter(data?.voucherEffective.end);
              }}
            />

            <VSTableAction
              children={(data) => (data.suggestStatus ? '取消推荐' : '推荐')}
              linkPage={{
                updateTable: true,
                onLinkPageValidate: async (data) => {
                  const text = data.suggestStatus ? '取消推荐' : '商券推荐';
                  try {
                    const request = data.suggestStatus
                      ? vsf.services
                          .MallVoucherController_updateVoucherNotSuggest_10daec
                      : vsf.services
                          .MallVoucherController_updateVoucherSuggest_ebf1b0;
                    const res = await request?.({ mallVoucherId: data.id });
                    if (res?.code === 200) {
                      vsf.showToast(`${text}成功`, 'success');
                      return true;
                    }
                  } catch (e) {
                    //
                  }
                  vsf.showToast(`${text}失败`, 'error');
                  return false;
                },
                updateType: 'reload',
              }}
              disabled={(data) => data.isHidden}
            />
          </VSTableColumn>
        </VSPromiseTable>
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Pagination
            current={querys.page}
            onChange={(page, pageSize) => {
              setQuerys({
                ...querys,
                size: pageSize,
                from: (page - 1) * pageSize,
                page,
              });
            }}
            total={total ?? 0}
          />
        </div> */}
      </div>
    </div>
  );
};
export default definePage(MallVoucherListVp);

import SnapshotConfig from '@pages/SnapshotConfig';
import SnapshotDetail from '@pages/SnapshotDetail';
import styles from '@pages/SnapshotList.module.less';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Divider,
  message,
  Modal,
  Pagination,
  Section,
  Space,
  VSControlledForm,
  VSFormFooter,
  VSFormFooterSubmit,
  VSFormItem,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';

const SnapshotList = (props) => {
  /**
   * 查询参数
   * @type {object}
   */
  const [total, setTotal] = useState(0);
  const [querys, setQuerys] = useState({ size: 10, page: 1 });
  const [currentRecord, setCurrentRecord] = useState();

  const handleConfigSet = useCallback(
    () =>
      new Promise((resolve) => {
        const values = vsf.refs['snapshotConfigForm']?.getFieldsFormatValue();
        vsf.services
          .CommunityConfigController_updateCommunityConfig_8bad86({
            btoParam: {
              ...values,
            },
          })
          .then((res) => {
            if (res?.code === 200) {
              message.success(`编辑成功`);
              resolve(true);
            } else {
              // message.error(`编辑失败: ` + res?.message ?? '请求失败');
              resolve(false);
            }
          });
      }),
    [],
  );
  return (
    <div style={{ minHeight: '88vh' }} className="container-box">
      <Section className={styles['vs-form-footer']}>
        <div className="container__header-snapshotList">
          <VSControlledForm
            id="snapshotQuery"
            value={querys}
            onSubmit={(_value) => {
              setQuerys({ ..._value, from: 0, size: 10, page: 1 });
            }}
            layout="inline"
          >
            <VSFormItem
              name={['userNameLike']}
              label="提交人"
              valueType="text"
              fieldProps={{
                width: 250,
                placeholder: '请输入提交人名称或组织名称',
              }}
              style={{ marginBottom: 16 }}
            />

            <VSFormItem
              name={['communityStatusIs']}
              label="状态"
              valueType="select"
              dataSource={[
                { label: '待审核', value: 'NOT_REVIEW' },
                { label: '审核通过', value: 'REVIEW_PASS' },
                { label: '审核拒绝', value: 'REVIEW_REJECT' },
              ]}
              fieldProps={{
                width: 250,
                placeholder: '请选择状态',
              }}
            />

            <VSFormItem
              name={['createdAtRangeIn']}
              label="提交时间"
              valueType="dateRange"
              transform={(values) => {
                if (!Array.isArray(values)) return values;
                return ['createdAtRangeIn'].reduceRight(
                  (acc, curr) => ({ [curr]: acc }),
                  {
                    begin: values[0],
                    end: values[1],
                    beginInclude: true,
                    endInclude: true,
                  },
                );
              }}
              convertValue={(v) => {
                if (v?.begin) {
                  return [v.begin, v.end];
                }
                return v;
              }}
              fieldProps={{
                format: 'YYYY-MM-DD',
                valueType: 'string',
                width: 250,
              }}
            />

            <VSFormItem
              name={['communityTypeIs']}
              label="提交人类型"
              valueType="select"
              dataSource={[
                { label: '个人发布', value: 'USER' },
                { label: '团队发布', value: 'TEAM' },
              ]}
              fieldProps={{
                width: 250,
                placeholder: '请选择提交人类型',
              }}
            />

            <VSFormFooter>
              <VSFormFooterSubmit children="查询" />
              <Button
                children="重置"
                onClick={() =>
                  setQuerys({
                    orderList: [],
                    userNameLike: undefined,
                    communityStatusIs: undefined,
                    communityTypeIs: undefined,
                    createdAtRangeIn: undefined,
                    page: 1,
                    size: 10,
                    from: 0,
                  })
                }
              />
            </VSFormFooter>
          </VSControlledForm>
        </div>

        {/* <Divider /> */}
        <div className="container__content">
          <Space style={{ marginTop: 5, marginLeft: 10, float: 'left' }}>
            <div className="tableTitle">随手拍列表</div>
          </Space>
          <div className="container__btn__list">
            <Button
              style={{ marginBottom: 24 }}
              children="随手拍设置"
              type="primary"
              onClick={() => vsf.openModal('snapshotSetModal')}
            />
          </div>

          <VSPromiseTable
            cardProps={{
              bodyStyle: {
                padding: 0,
              },
            }}
            id="snapshotTable"
            scroll={{ y: 700 }}
            pagination={{
              showQuickJumper: true,
              showSizeChanger: true,
              defaultPageSize: 20,
            }}
            onFetch={async (params) => {
              const res =
                await vsf?.services?.CommunityInfoController_pageCommunityInfoBase_c98e2d?.(
                  {
                    qto: {
                      from: params.pagination?.from ?? 0,
                      size: params.pagination?.size ?? 20,
                      orderList: params.orderList, // 排序结果
                      ...params.search, // 搜索结果
                      ...params.filters, // 过滤器结果
                      ...(params?.extra ?? {}),
                    },
                  },
                );
              setTotal(res?.data?.count ?? res?.data?.length ?? 0);
              return {
                data: res?.data?.result ?? res?.data ?? [],
                total: res?.data?.count ?? res?.data?.length ?? 0,
              };
            }}
            extraParams={querys}
          >
            <VSTableColumn
              dataIndex={['communityStatus']}
              title="状态"
              align="center"
              editable={false}
              valueType="select"
              dataSource={[
                { label: '待审核', value: 'NOT_REVIEW' },
                { label: '审核通过', value: 'REVIEW_PASS' },
                { label: '审核拒绝', value: 'REVIEW_REJECT' },
              ]}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['pubTime']}
              title="提交时间"
              align="center"
              editable={false}
              valueType="date"
              transform={(v) => {
                return ['createdAt'].reduceRight(
                  (acc, curr) => ({ [curr]: acc }),
                  v,
                );
              }}
              fieldProps={{
                format: 'YYYY-MM-DD HH:mm:ss',
                valueType: 'string',
              }}
            />

            <VSTableColumn
              dataIndex={['user', 'nickname']}
              title="提交人"
              align="center"
              editable={false}
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['communityType']}
              title="提交人类型"
              align="center"
              valueType="select"
              dataSource={[
                { label: '个人发布', value: 'USER' },
                { label: '团队发布', value: 'TEAM' },
              ]}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['operateUser', 'nickname']}
              title="操作人"
              align="center"
              editable={false}
              valueType="text"
              fieldProps={{}}
              render={(_, data) => {
                return data.operateUser?.nickname || '-';
              }}
            />

            <VSTableColumn
              dataIndex={['operateTime']}
              title="操作时间"
              align="center"
              editable={false}
              valueType="date"
              transform={(v) => {
                return ['operateTime'].reduceRight(
                  (acc, curr) => ({ [curr]: acc }),
                  v,
                );
              }}
              fieldProps={{
                format: 'YYYY-MM-DD HH:mm:ss',
                valueType: 'string',
              }}
            />

            <VSTableColumn title="操作" valueType="text" align="center">
              <VSTableAction
                children="查看详情"
                onClick={(v, record) => {
                  setCurrentRecord(record);
                  vsf.openModal('snapshotDetailModal');
                }}
              />
            </VSTableColumn>
          </VSPromiseTable>

          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <Pagination
              current={querys.page}
              onChange={(page, pageSize) => {
                setQuerys({
                  ...querys,
                  size: pageSize,
                  from: (page - 1) * pageSize,
                  page,
                });
              }}
              total={total ?? 0}
            />
          </div> */}
        </div>

        <Modal
          id="snapshotSetModal"
          title="随手拍设置"
          onOk={handleConfigSet}
          destroyOnClose={true}
          okText="确定"
          cancelText="取消"
        >
          <SnapshotConfig />
        </Modal>
        <Modal
          id="snapshotDetailModal"
          width={800}
          title=""
          onOk={() => {}}
          destroyOnClose={true}
          footer={null}
          okText="确定"
          cancelText="取消"
        >
          <SnapshotDetail id={currentRecord?.id} />
        </Modal>
      </Section>
    </div>
  );
};
export default definePage(SnapshotList);

import {
  compose,
  withField,
  withPreview,
  withSize,
  WithSizeProps,
} from '@vs/vsf-kit';
import React from 'react';

export type SealProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * 值
   */
  value?:
    | string
    | {
        text: string;
        color: string;
      };
} & WithSizeProps;

const BaseSeal: React.FC<SealProps> = (props) => {
  const { value, ...rest } = props;

  if (!value) {
    return null;
  }

  const defaultColor = 'red';
  const defaultText = '印章';
  const text = (typeof value === 'string' ? value : value?.text) || defaultText;
  const color =
    typeof value === 'string' ? defaultColor : value?.color || defaultColor;
  return (
    <div {...rest}>
      <div style={{ color, border: `1px solid ${color}`, textAlign: 'center' }}>
        {text}
      </div>
    </div>
  );
};

const InternalSeal = withSize()(BaseSeal);

/**
 * 印章
 */
const Seal = (props: SealProps) => {
  return <InternalSeal {...props} />;
};
Seal.displayName = 'Seal';

export default compose(
  withField<string>({
    name: 'Seal',
  }),
  withPreview<SealProps>({
    renderPreview: (props) => {
      return <InternalSeal {...props} />;
    },
  }),
)(Seal);

/* eslint-disable prettier/prettier */
import {
  Button,
  compose,
  Image,
  message,
  Upload,
  UploadFile,
  UploadProps,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { portalize } from './portal';

export type UploadOssParams = {
  /** 自定义类型字段 */
  dir: string;
  expire: string;
  host: string;
  accessKeyId: string;
  policy: string;
  signature: string;
};

export type FileUploadValue = UploadFile & {
  /** 自定义类型字段 */
  url?: string;
  name?: string;
};

export type getOssPolicyType = () => Promise<UploadOssParams>;

export type FileUploadProps = {
  /**
   * 默认值
   */
  defaultValue?: FileUploadValue[];
  /**
   * 值
   */
  value?: FileUploadValue[];
  /**
   * 值变化回调
   */
  onChange?: (value?: FileUploadValue[]) => void;
  /**
   * 上传参数
   */
  ossParams?: UploadOssParams;
  /**
   *  获取上传参数服务
   */
  getOssPolicy?: getOssPolicyType;
  /**
   * 上传文件类型
   */
  type: 'picture' | 'file';
  /**
   * upload属性
   */
  uploadProps?: UploadProps;
  /**
   * uploader容器，例如ImgCrop
   */
  container?: JSX.Element;
  /**
   * 文件大小限制, byte
   */
  limit?: number;
  /**
   * 文件大小超限
   */
  onLimitOverflow?: (fileSize: number, limit: number) => void;
};

/**
 * 上传
 */
const FileUpload = (props: FileUploadProps) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    ossParams,
    uploadProps,
    container,
    type,
    getOssPolicy,
    limit,
    onLimitOverflow,
  } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });
  const [OSSData, setOSSData] = useState<UploadOssParams>();
  const [isExceed, setIsExceed] = useState(true);
  const portalRef = useRef<any>();
  const urlRef = useRef<string>();
  const loadOssPolicy = useCallback(async () => {
    try {
      const _data = await getOssPolicy?.();
      setOSSData(_data);
      return _data;
    } catch (err) {
      // message.error(`获取ossParams错误: ${err}`)
      // console.error();
    }
  }, [getOssPolicy]);

  useEffect(() => {
    uploadProps?.maxCount === (value ?? []).length
      ? setIsExceed(false)
      : setIsExceed(true);
  }, [uploadProps, value]);

  useEffect(() => {
    if (getOssPolicy) {
      loadOssPolicy();
    } else {
      setOSSData(ossParams);
    }
  }, [getOssPolicy, loadOssPolicy, ossParams]);

  const handleChange = async ({ fileList }) => {
    const reg = /(http|https):\/\/\S*/;
    // 被beforeUpload拦截的file没有status和url
    const _value = fileList
      .filter((item) => item.status || item.url)
      .map((item) => {
        return {
          ...item,
          url: reg.test(item.url) ? item.url : `${OSSData?.host}/${item.url}`,
        };
      });
    setValue([..._value]);
  };

  const onRemove = (file: UploadFile) => {
    const files = (value || []).filter((v) => v.url !== file.url);
    setValue([...files]);
  };

  const getExtraData = useCallback(
    async (file) => {
      // 在文件会进行修改的情况下,例如用ant-img-crop包裹了upload组件时
      // getExtraData的入参file和beforeUpload的入参file不是一个file, 此时file的url可能为空
      // 故需要用urlRef暂存file的url以处理这种情况
      const ret = {
        key: urlRef.current || file.url,
        OSSAccessKeyId: OSSData?.accessKeyId,
        policy: OSSData?.policy,
        Signature: OSSData?.signature,
      };
      urlRef.current = undefined;
      return ret;
    },
    [OSSData],
  );

  const beforeUpload = useCallback(
    async (file) => {
      if (limit) {
        if (file.size > limit) {
          if (onLimitOverflow) {
            onLimitOverflow(file.size, limit);
          } else {
            message.error(`文件大小超过${limit}字节`);
          }
          return false;
        }
      }

      if (!OSSData) {
        return false;
      }

      const suffix = file.name.slice(file.name.lastIndexOf('.'));
      const filename = Date.now() + suffix;
      const url = OSSData.dir + filename;
      file.url = url;
      urlRef.current = url;
      return file;
    },
    [OSSData, limit, onLimitOverflow],
  );

  const aTagDownload = (url, filename) => {
    const a = document.createElement('a'); // 创建 a 标签
    a.href = url; // 下载路径
    a.download = filename; // 下载属性，文件名
    a.style.display = 'none'; // 不可见
    document.body.appendChild(a); // 挂载
    a.click(); // 触发点击事件
    document.body.removeChild(a); // 移除
  };

  const download = (path, fileName) => {
    fetch(path)
      .then((res) => res.blob())
      .then((blob) => {
        const objectUrl = URL.createObjectURL(blob); // 创建 url 对象
        aTagDownload(objectUrl, fileName); // 调用 上面 [2] 动态方式
      })
      .catch((err) => {
        console.error();
      });
  };

  const handlePreviewClose = (visible) => {
    if (!visible) {
      portalRef?.current?.close();
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (type === 'picture') {
      portalRef.current = portalize({
        component: (
          <Image
            style={{
              opacity: 0,
            }}
            width={200}
            src={file?.url || (file.preview as string)}
            preview={{
              visible: true,
              onVisibleChange: handlePreviewClose,
            }}
          />
        ),
      });
    } else if (type === 'file') {
      download(`${file.url}`, file.name);
    }
  };

  // 上传参数
  const uploadParams: UploadProps = {
    name: 'file',
    fileList: value,
    defaultFileList: value,
    action: OSSData?.host,
    onChange: handleChange,
    onRemove,
    data: getExtraData,
    beforeUpload,
    listType: type === 'picture' ? 'picture-card' : 'text',
    onPreview: handlePreview,
    ...uploadProps,
  };

  const uploader = (
    <Upload {...uploadParams}>
      {isExceed ? (
        <>
          {type && type === 'picture' ? (
            <div>点击上传</div>
          ) : (
            <Button>点击上传</Button>
          )}
        </>
      ) : null}
    </Upload>
  );

  return (
    <div>
      {container
        ? React.cloneElement(container, container.props, uploader)
        : uploader}
    </div>
  );
};

FileUpload.displayName = 'FileUpload';

export default compose(
  withField<FileUploadValue>({
    name: 'FileUpload',
  }),
  withPreview<FileUploadProps>({
    renderPreview: (props) => {
      const { value, type } = props;
      const aTagDownload = (url, filename) => {
        const a = document.createElement('a'); // 创建 a 标签
        a.href = url; // 下载路径
        a.download = filename; // 下载属性，文件名
        a.style.display = 'none'; // 不可见
        document.body.appendChild(a); // 挂载
        a.click(); // 触发点击事件
        document.body.removeChild(a); // 移除
      };

      const download = (path, fileName) => {
        fetch(path)
          .then((res) => res.blob())
          .then((blob) => {
            const objectUrl = URL.createObjectURL(blob); // 创建 url 对象
            aTagDownload(objectUrl, fileName); // 调用 上面 [2] 动态方式
          })
          .catch((err) => {
            console.error();
          });
      };
      /** 返回预览模式下的dom */
      return (
        <>
          {/* 预览值： */}
          {value?.map((item) => (
            <div key={item.url}>
              {type === 'picture' && (
                <>
                  <Image width={100} src={item.url || ''} />
                  {/* <div>{item?.name}</div> */}
                </>
              )}
              {type === 'file' && (
                <a
                  onClick={() => {
                    download(item.url, item?.name);
                  }}
                  href={item.url}
                >
                  {item?.name}
                </a>
              )}
            </div>
          ))}
        </>
      );
    },
  }),
)(FileUpload);

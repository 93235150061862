import styles from '@pages/MemberList.module.less';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Divider,
  Input,
  message,
  Modal,
  Pagination,
  Section,
  VSControlledForm,
  VSFormFooter,
  VSFormFooterSubmit,
  VSFormItem,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
const MemberCheck = (props) => {
  /**
   * 查询参数
   * @type {object}
   */
  const [total, setTotal] = useState(0);
  const [querys, setQuerys] = useState({ size: 10, page: 1 });
  /**
   * joinTeamId
   * @param {string} id
   * @param {object} params
   * @returns {object}
   */
  const joinTeamId = ({ id, params }) => {
    return { teamIdIs: id, ...(params || {}) };
  };

  const [currentRecord, setCurrentRecord] = useState({});

  const handleRejectOpen = useCallback((record) => {
    setCurrentRecord(record);
    vsf.openModal('rejectModal');
  }, []);
  const handleReject = useCallback(
    () =>
      new Promise((resolve) => {
        if (!currentRecord?.id) {
          message.error(`操作失败：没有选中项`);
          resolve(true);
          return;
        }
        const reason =
          vsf.refs.rejectReasonInput?.resizableTextArea?.textArea?.value ?? '';
        vsf.services
          .TeamReviewController_teamReviewReject_407d84({
            reviewId: currentRecord.id,
            rejectReason: reason,
          })
          .then((res) => {
            if (res?.code === 200) {
              message.success(`操作成功`);
              props?.onChange('reject');

              setTimeout(() => {
                vsf.refs.memberCheckTable?.reload();
              }, 1000);
              resolve(true);
            } else {
              // message.error(`操作失败: ` + res?.message ?? '请求失败');
              resolve(false);
            }
          });
      }),
    [currentRecord.id, props],
  );
  const handlePass = useCallback(
    (record) =>
      new Promise((resolve) => {
        if (!record?.id) {
          message.error(`操作失败：没有选中项`);
          resolve(true);
          return;
        }
        vsf.services
          .TeamReviewController_teamReviewPass_294037({
            reviewId: record.id,
          })
          .then((res) => {
            if (res?.code === 200) {
              message.success(`操作成功`);
              props?.onChange('pass');

              setTimeout(() => {
                vsf.refs.memberCheckTable?.reload();
              }, 1000);
              resolve(true);
            } else {
              // message.error(`操作失败: ` + res?.message ?? '请求失败');
              resolve(false);
            }
          });
      }),
    [props],
  );

  return (
    <Section title="" className={styles['vs-form-footer']}>
      <div className="menberList__header">
        <VSControlledForm
          id="memberCheckForm"
          value={querys}
          onSubmit={(_value) =>
            setQuerys({ ..._value, from: 0, size: 10, page: 1 })
          }
          layout="inline"
        >
          <VSFormItem
            name={['nicknameLike']}
            label="人员姓名"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['teamReviewStatusIs']}
            label="审核状态"
            valueType="select"
            dataSource={[
              { label: '未审核', value: 'NOT_REVIEW' },
              { label: '审核通过', value: 'REVIEW_PASS' },
              { label: '审核拒绝', value: 'REVIEW_REJECT' },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['createdAtRangeIn']}
            label="申请时间"
            valueType="dateRange"
            transform={(values) => {
              if (!Array.isArray(values)) return values;
              return ['createdAtRangeIn'].reduceRight(
                (acc, curr) => ({ [curr]: acc }),
                {
                  begin: values[0],
                  end: values[1],
                  beginInclude: true,
                  endInclude: true,
                },
              );
            }}
            convertValue={(v) => {
              if (v?.begin) {
                return [v.begin ?? '', v.end ?? ''];
              }
              return v;
            }}
            fieldProps={{ valueType: 'string' }}
          />
          <VSFormFooter>
            <VSFormFooterSubmit children="查询" />

            <Button
              children="重置"
              onClick={() =>
                setQuerys({
                  from: 0,
                  size: 10,
                  orderList: [],
                  nicknameLike: undefined,
                  teamReviewStatusIs: undefined,
                  createdAtRangeIn: undefined,
                  page: 1,
                })
              }
            />
          </VSFormFooter>
        </VSControlledForm>
      </div>

      <Divider />
      <VSPromiseTable
        id="memberCheckTable"
        cardProps={{
          bodyStyle: {
            padding: 0,
          },
        }}
        pagination={false}
        onFetch={async (params) => {
          const res =
            await vsf?.services?.TeamReviewController_pageTeamReviewUser_391fce?.(
              {
                qto: {
                  from: params.pagination?.from ?? 0,
                  size: params.pagination?.size ?? 20,
                  orderList: params.orderList, // 排序结果
                  ...params.search, // 搜索结果
                  ...params.filters, // 过滤器结果
                  ...(params?.extra ?? {}),
                },
              },
            );
          setTotal(res?.data?.count ?? res?.data?.length ?? 0);
          return {
            data: res?.data?.result ?? res?.data ?? [],
            total: res?.data?.count ?? res?.data?.length ?? 0,
          };
        }}
        extraParams={joinTeamId({ id: props.id, params: querys })}
      >
        <VSTableColumn
          dataIndex={['id']}
          title="编号"
          editable={false}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['applyUser', 'nickname']}
          title="人员姓名"
          editable={false}
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['applyUser', 'userInfoDto', 'phone']}
          title="手机号"
          editable={false}
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['applyUser', 'userInfoDto', 'idNum']}
          title="身份证号"
          editable={false}
          valueType="text"
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['createdAt']}
          title="申请时间"
          editable={false}
          valueType="date"
          transform={(v) => {
            return ['createdAt'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              v,
            );
          }}
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss', valueType: 'string' }}
        />

        <VSTableColumn
          dataIndex={['teamReviewStatus']}
          title="状态"
          editable={false}
          valueType="select"
          render={(text, record) => {
            if (record.teamReviewStatus === 'REVIEW_PASS') {
              return <div style={{ color: 'green' }}>{text}</div>;
            }
            if (record.teamReviewStatus === 'REVIEW_REJECT') {
              return <div style={{ color: 'red' }}>{text}</div>;
            }
            return text;
          }}
          dataSource={[
            { label: '未审核', value: 'NOT_REVIEW' },
            { label: '审核通过', value: 'REVIEW_PASS' },
            { label: '审核拒绝', value: 'REVIEW_REJECT' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['rejectReason']}
          title="拒绝原因"
          editable={false}
          valueType="text"
          fieldProps={{}}
        />
        <VSTableColumn
          title="操作"
          valueType="text"
          render={(v, record) => {
            return (
              <div style={{ display: 'flex', gap: 8 }}>
                <Button
                  type="link"
                  children="通过"
                  disabled={record.teamReviewStatus !== 'NOT_REVIEW'}
                  style={{ padding: 0 }}
                  onClick={(ecord) => handlePass(record)}
                />
                <Button
                  type="link"
                  children="拒绝"
                  disabled={record.teamReviewStatus !== 'NOT_REVIEW'}
                  style={{ padding: 0 }}
                  onClick={() => handleRejectOpen(record)}
                />
              </div>
            );
          }}
        ></VSTableColumn>
        <VSTableColumn
          dataIndex={['reviewTime']}
          title="操作时间"
          editable={false}
          valueType="date"
          transform={(v) => {
            return ['reviewTime'].reduceRight(
              (acc, curr) => ({ [curr]: acc }),
              v,
            );
          }}
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss', valueType: 'string' }}
        />
        <VSTableColumn
          dataIndex={['reviewUser', 'nickname']}
          title="操作人"
          editable={false}
          valueType="text"
          fieldProps={{}}
        />
      </VSPromiseTable>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Pagination
          current={querys.page}
          onChange={(page, pageSize) => {
            setQuerys({
              ...querys,
              size: pageSize,
              from: (page - 1) * pageSize,
              page,
            });
          }}
          total={total ?? 0}
        />
      </div>
      <Modal id="rejectModal" title="不通过原因" onOk={handleReject}>
        <Input.TextArea id="rejectReasonInput" placeholder="请输入不通过原因" />
      </Modal>
    </Section>
  );
};
export default definePage(MemberCheck);

import './TeamListVp.less';

import EditTeam from '@pages/EditTeam';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Breadcrumb,
  Button,
  message,
  Modal,
  Pagination,
  RadioGroup,
  Section,
  Space,
  useControllableState,
  VSControlledForm,
  VSForm,
  VSFormFooter,
  VSFormFooterSubmit,
  VSFormItem,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';

import { getAgeAndGenderFromID } from '@/utils/index';

import styles from './TeamListVp.module.less';

const ScoreStatusRadio = ({ value, onChange }) => {
  const [v, setV] = useControllableState({
    value: String(value),
    onChange: (val) => {
      onChange?.(val === 'true');
    },
  });
  return (
    <RadioGroup
      value={v}
      onChange={setV}
      dataSource={[
        {
          label: '加分',
          value: 'true',
        },
        {
          label: '扣分',
          value: 'false',
        },
      ]}
    />
  );
};
const userInfo = JSON.parse(localStorage.getItem('userInfo') ?? '{}');
const PersonListVp = (props) => {
  /**
   * 查询参数
   * @type {TeamInfoPersistQtoPageTeamInfoQto}
   */
  const [total, setTotal] = useState(0);
  const [querys, setQuerys] = useState({ size: 10, page: 1 });
  const [currentRecord, setCurrentRecord] = useState({});

  const handleScore = useCallback(async () => {
    const values = vsf.refs.scoreUpdateForm?.getFieldsFormatValue() ?? {};
    const res =
      await vsf.services.UserIntegralSerialOperationController_handleUserIntegralSerial_e57684(
        {
          btoParam: {
            userId: currentRecord?.user?.id,
            ...values,
          },
        },
      );

    if (res?.data > 0) {
      message.success('赋分成功');
      vsf.refs.table?.reload();
      return true;
    }

    return false;
  }, [currentRecord]);

  const renderSex = (_, record) => {
    return getAgeAndGenderFromID(record.idNum).gender ?? '未知';
  };
  const renderAge = (_, record) => {
    return getAgeAndGenderFromID(record.idNum).age ?? '未知';
  };

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.UserInfoIntegralController_getPagedByUserInfoIntegral_05337b?.(
        {
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}),
          },
        },
      );
    setTotal(res?.data?.count ?? res?.data?.length ?? 0);
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  return (
    <div style={{ minHeight: '88vh' }} className="container-box">
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item>人员管理</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Section className={styles['vs-form-footer']}>
        <div className="container__team-headr">
          <VSControlledForm
            id="form"
            layout="inline"
            value={querys}
            onSubmit={(_value) =>
              setQuerys({ ..._value, from: 0, size: 10, page: 1 })
            }
          >
            <VSFormItem
              name={['nameLike']}
              label="个人姓名"
              valueType="text"
              fieldProps={{
                placeholder: '请输入个人姓名',
              }}
            />

            <VSFormItem
              name={['idNumLike']}
              label="身份证号"
              valueType="text"
              fieldProps={{
                placeholder: '请输入身份证号',
              }}
            />
            <VSFormItem
              name={['phoneLike']}
              label="手机号码"
              valueType="text"
              fieldProps={{
                placeholder: '请输入手机号',
              }}
            />

            <VSFormFooter>
              <VSFormFooterSubmit children="查询" />
              <Button
                children="重置"
                onClick={() =>
                  setQuerys({
                    page: 1,
                    size: 10,
                    from: 0,
                    phoneLike: undefined,
                    nameLike: undefined,
                    idNumLike: undefined,
                  })
                }
              />
            </VSFormFooter>
          </VSControlledForm>
        </div>
      </Section>
      <div className="container__content">
        <Space style={{ marginTop: 5, marginLeft: 10, float: 'left' }}>
          <div className="tableTitle">共{total}位村民/居民</div>
        </Space>
        <Space style={{ marginBottom: 24, float: 'right' }}>
          <Button
            children="赋分记录"
            type="primary"
            onClick={() => vsf.openModal('scoreHistoryModal')}
          />
        </Space>

        <div style={{ margin: '24px 0', display: 'flex' }} />

        <VSPromiseTable
          id="table"
          cardProps={{
            bodyStyle: {
              padding: 0,
            },
          }}
          // scroll={{ y: 550 }}
          onFetch={onFetch}
          // pagination={false}
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            defaultPageSize: 10,
          }}
          extraParams={querys}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="个人姓名"
            align="center"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['idNum']}
            title="身份证号"
            align="center"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['phone']}
            title="手机号"
            align="center"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            title="性别"
            align="center"
            editable={false}
            valueType="text"
            render={renderSex}
          />
          <VSTableColumn
            dataIndex={['phone']}
            title="年龄"
            align="center"
            editable={false}
            valueType="text"
            render={renderAge}
          />

          <VSTableColumn
            dataIndex={['user', 'userIntegralDto', 'totalIncomeAmount']}
            title="累计获得义工分"
            align="center"
            editable={false}
            valueType="digit"
            render={(_, data) => {
              return data.user.userIntegralDto?.totalIncomeAmount || 0;
            }}
          />

          <VSTableColumn
            dataIndex={['user', 'userIntegralDto', 'balanceAmount']}
            title="义工分余额"
            align="center"
            editable={false}
            valueType="digit"
            render={(_, data) => {
              return data.user.userIntegralDto?.balanceAmount || 0;
            }}
          />
          <VSTableColumn title="操作" valueType="text" align="center">
            <VSTableAction
              children="赋分"
              onClick={(v, record) => {
                setCurrentRecord(record);
                vsf.openModal('scoreUpdateModal');
              }}
            />

            <VSTableAction
              children="更新"
              onClick={(v, record) => {
                Modal.confirm({
                  content:
                    '您确定想要更新此用户的个人信息、管理权限、商家信息等全部信息吗？',
                  icon: '',
                  okText: '确认',
                  cancelText: '取消',
                  onOk: () => {
                    vsf.services
                      .UserInfoIntegralController_updateUserFromQunzhi_f8a4b8({
                        accountId: record?.user.id,
                      })
                      .then((res) => {
                        if (res?.code === 200) {
                          message.success(`用户信息更新成功`);
                          vsf.refs.table?.reload();
                        }
                      });
                  },
                });
              }}
            />
          </VSTableColumn>
        </VSPromiseTable>
      </div>

      <Modal
        id="scoreHistoryModal"
        title="赋分记录"
        width={800}
        okText="确定"
        cancelText="取消"
      >
        <VSPromiseTable
          cardProps={{
            bodyStyle: {
              padding: 0,
            },
          }}
          id="scoreListTable"
          pagination={{
            showQuickJumper: true,
            showSizeChanger: true,
            defaultPageSize: 10,
          }}
          onFetch={async (params) => {
            const res =
              await vsf?.services?.UserIntegralSerialOperationController_getPagedByUserIntegralSerialQto_24f346?.(
                {
                  qto: {
                    from: params.pagination?.from ?? 0,
                    size: params.pagination?.size ?? 20,
                    orderList: params.orderList, // 排序结果
                    ...params.search, // 搜索结果
                    ...params.filters, // 过滤器结果
                    ...(params?.extra ?? {}),
                  },
                },
              );
            return {
              data: res?.data?.result ?? res?.data ?? [],
              total: res?.data?.count ?? res?.data?.length ?? 0,
            };
          }}
          rowClassName={(record) =>
            record.serialAmount < 0 ? styles['scoreListRed'] : ''
          }
          searchConfig={{
            filterType: 'inline',
          }}
        >
          <VSTableColumn
            dataIndex={['createdAt']}
            width={200}
            title="记录时间"
            editable={false}
            valueType="date"
            transform={(v) => {
              return ['createdAt'].reduceRight(
                (acc, curr) => ({ [curr]: acc }),
                v,
              );
            }}
            fieldProps={{
              format: 'YYYY-MM-DD HH:mm:ss',
              valueType: 'string',
            }}
          />
          <VSTableColumn
            dataIndex={['serialAmount']}
            title="积分"
            editable={false}
            valueType="digit"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['operationName']}
            title="操作人"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['serialDescr']}
            title="描述"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Modal>
      <Modal
        title="赋分"
        id="scoreUpdateModal"
        onOk={handleScore}
        okText="确定"
        cancelText="取消"
      >
        <VSForm
          id="scoreUpdateForm"
          layout="horizontal"
          initialValues={{ addStatus: true }}
        >
          <VSFormItem
            name={['addStatus']}
            label=""
            valueType="custom"
            customComponent={ScoreStatusRadio}
            fieldProps={{}}
          />
          <VSFormItem
            name={['serialAmount']}
            label="积分"
            valueType="digit"
            rules={[
              {
                message: '数量的值不合法',
                type: 'number',
                min: 0,
                max: null,
              },
            ]}
            fieldProps={{
              style: {
                width: 300,
              },
            }}
          />
          <div className="antd-formitem-contnent-border ">
            <VSFormItem
              name={['serialDescr']}
              label="描述"
              valueType="textarea"
              rules={[
                {
                  message: '流水描述长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1024,
                },
              ]}
              fieldProps={{
                style: {
                  width: 300,
                },
              }}
            />
          </div>
        </VSForm>
      </Modal>
    </div>
  );
};
export default definePage(PersonListVp);

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSControlledForm,
  VSDataProvider,
  VSFormItem,
} from '@vs/vsf-kit';
import React, { useState } from 'react';
const SnapshotConfig = (props) => {
  /**
   * 设置详情
   * @type {object}
   */
  const [configInfo, setConfigInfo] = useState();
  return (
    <Section title="">
      <VSDataProvider
        service={
          vsf.services.CommunityConfigController_getCommunityConfig_0c8b31
        }
        onDataLoaded={(_value) => setConfigInfo({ ..._value })}
      />
      <VSControlledForm
        id="snapshotConfigForm"
        value={configInfo}
        layout="horizontal"
      >
        <VSFormItem
          name={['userPubLimit']}
          label="义工每人每天可上传的随手拍数量"
          valueType="digit"
          rules={[
            {
              message: '用户发布限制的值不合法',
              type: 'number',
              min: 0,
              max: null,
            },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['teamPubLimit']}
          label="团队管理员每天可上传的随手拍数量"
          valueType="digit"
          rules={[
            {
              message: '团队发布限制的值不合法',
              type: 'number',
              min: 0,
              max: null,
            },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['reviewIntegral']}
          label="审核通过积分"
          valueType="digit"
          rules={[
            {
              message: '审核通过积分的值不合法',
              type: 'number',
              min: 0,
              max: null,
            },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['reviewDailyLimit']}
          label="每日审核限制"
          valueType="digit"
          rules={[
            {
              message: '每日审核限制的值不合法',
              type: 'number',
              min: 0,
              max: null,
            },
          ]}
          fieldProps={{}}
        />

        <VSFormItem
          name={['reviewMonthLimit']}
          label="审核每月限制"
          valueType="digit"
          rules={[
            {
              message: '审核每月限制的值不合法',
              type: 'number',
              min: 0,
              max: null,
            },
          ]}
          fieldProps={{}}
        />
      </VSControlledForm>
    </Section>
  );
};
export default definePage(SnapshotConfig);

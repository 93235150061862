import './index.less';

import { definePage } from '@vs/vsf-boot';
import React, { useEffect, useState } from 'react';

import CheckedDefaultIcon from '@/assets/images/check_default_icon.png';
import CheckedIcon from '@/assets/images/checked_icon.png';
import DefaultCode from '@/assets/images/default_code.jpg';
import LoginBg from '@/assets/images/login_bg.png';

const Login: React.FC<React.Component> = (props) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (window.WxLogin) {
      const obj = new window.WxLogin({
        id: 'wx_code_view',
        appid: 'wxee97b7c08ccdd7df',
        scope: 'snsapi_login',
        redirect_uri: encodeURI(
          `${location.protocol}//${location.host}/MiddlePage`,
        ),
        href: `${location.protocol}//${location.host}/custom_wxlogin_code_style.css`,
      });
    }
  }, []);

  const handelCheckClick = () => {
    setChecked(!checked);
  };

  return (
    <div className="login_container">
      <img src={LoginBg} className="login_container__bg" />
      <div className="login_container__content">
        <div className="login_container__content__title">
          群治分义工银行专区
        </div>
        <div
          className="login_container__content__defaultCode"
          style={{
            display: checked ? 'none' : 'block',
          }}
        >
          <img src={DefaultCode} />
        </div>
        <div
          style={{
            display: checked ? 'block' : 'none',
          }}
          id="wx_code_view"
          className="login_container__content__code"
        ></div>
        {!checked && (
          <div className="login_container__content__label">
            请勾选我已阅读并同意
          </div>
        )}
        <div className="login_container__content__privacy">
          <div
            className="login_container__content__privacy__icon"
            onClick={handelCheckClick}
          >
            <img src={checked ? CheckedIcon : CheckedDefaultIcon} />
          </div>
          <div className="login_container__content__privacy__content">
            我已阅读并同意
            <a>
              《群治分服务协议》、《群治分隐私政策》、《群治分儿童隐私保护指引》
            </a>
          </div>
        </div>
      </div>
      <div className="login_container__trademark">
        copyright © 2022 杭州岭上数字科技公司出品
      </div>
    </div>
  );
};

export default definePage(Login);

import './index.less';

import vsf from '@vs/vsf-boot';
import { renderRoutes } from '@vs/vsf-boot';
import { Dropdown, Image, Layout } from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';

import titleIcon from '@/assets/images/titleIcon.png';
import defaultImage from '@/assets/images/titlePhoto.png';
import getOssPolicy from '@/utils/getOssPolicy';

import Left from './Left';

const { Sider } = Layout as any;

type containerProps = {
  route: any;
};
const Container: any = (props: containerProps) => {
  const { route } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] =
    useState<ComVsUserAccountEntranceWebVoAccountInfoVo>();

  const loadUserData = () => {
    try {
      const data = JSON.parse(localStorage.getItem('userInfo') ?? '{}');
      setUserData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOssPolicy();
    loadUserData();
  }, []);
  return (
    <div className="vsf-layout">
      <div className="vsf-layout-header vsf-text-20">
        <div className="vsf-layout-header-left">
          <div>
            <img
              src={defaultImage}
              className="vsf-layout-header-left-icon"
            ></img>
          </div>
          <div className="vsf-layout-header-left-text">义工银行专区</div>
        </div>

        <div className="vsf-layout-header-right">
          <img
            src={userData?.avatar}
            className="vsf-layout-header-right-avatar"
          />
          <Dropdown
            open={isOpen}
            menu={{
              items: [
                {
                  label: '退出登录',
                  key: 'exit',
                  onClick: () => {
                    try {
                      localStorage.clear();
                      vsf?.services?.AccountInfoController_logout_0c48b9();
                      vsf?.navigateTo('/Login');
                    } catch (error) {
                      console.error(error);
                    }
                  },
                },
              ],
            }}
          >
            <div
              className="vsf-layout-header-right-dropDown"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              {userData?.nickname}
              <img
                src={titleIcon}
                className={
                  !isOpen
                    ? 'vsf-layout-header-right-icon'
                    : 'vsf-layout-header-right-close'
                }
              ></img>
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="vsf-layout-content">
        <Sider
          className="vsf-layout-sider"
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Left />
        </Sider>
        <div className="vsf-layout-main">
          {renderRoutes(route.routes, route.redirect)}
        </div>
      </div>
    </div>
  );
};

export default Container;

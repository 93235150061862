import MallVoucherListVp from '@pages/MallVoucherListVp';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';
const MallVoucherList = (props) => {
  return (
    <Page title="">
      <MallVoucherListVp />
    </Page>
  );
};
export default definePage(MallVoucherList);

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Breadcrumb,
  Button,
  Divider,
  Input,
  InputNumber,
  prompt,
  Section,
  VSPromiseTable,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';

const findDuplicate = (data, name) => {
  return data?.some((d) => {
    return (
      d.categoryName === name ||
      findDuplicate(data?.activityCategoryDtoList, name)
    );
  });
};

const flatten = (data) => {
  return data?.reduce((ret, d) => {
    if (d.activityCategoryDtoList) {
      const children = flatten(d.activityCategoryDtoList);
      ret.push(...children);
    }
    ret.push(d);
    return ret;
  }, []);
};

const ScoreConfigVp = (props) => {
  const [expandedRows, setExpandedRows] = useState(undefined);
  const editPronmpt = (value, title) => {
    return prompt(
      {
        title,
        value,
        items: [
          {
            name: 'categoryName',
            placeholder: '请输入类目名称',
            formItemProps: {
              label: '类目名称',
              rules: [
                {
                  required: true,
                  message: '必须输入类目名称',
                },
                {
                  validator: async (rule, value) =>
                    new Promise((resolve, reject) => {
                      const data = vsf.refs.activityCategoryTable?.getValues();
                      if (findDuplicate(data, value)) {
                        reject();
                      } else {
                        resolve();
                      }
                    }),
                  message: '类目名称不可重复',
                },
              ],
            },
            render: (item) => (
              <Input {...item} style={{ width: '100%' }} maxLength={10} />
            ),
          },
          {
            name: 'activityIntegral',
            formItemProps: {
              label: '参与活动可得积分',
              rules: [
                {
                  required: true,
                  message: '必须参与活动可得积分',
                },
              ],
            },
            render: (item) => (
              <InputNumber
                {...item}
                style={{ width: '100%' }}
                addonAfter="分"
              />
            ),
          },
        ],
        formProps: {
          labelCol: {
            span: 8,
          },
        },
      },
      { width: 500 },
    );
  };

  const createCategory = async (params, parent) => {
    try {
      const res =
        await vsf?.services?.ActivityCategoryController_createActivityCategory_f8fd46?.(
          {
            btoParam: {
              ...params,
              parentId: parent?.id,
              level: (parent?.level ?? 0) + 1,
            },
          },
        );
      if (res?.code === 400) {
        return false;
      }
      if (parent) {
        setExpandedRows((pre) => {
          if (pre && pre.findIndex((r) => r.id === parent.id) > -1) {
            return pre;
          }
          return [...(pre || []), parent];
        });
      }
      return res?.data;
    } catch (error) {
      return undefined;
    }
  };

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.ActivityCategoryController_getActivityCategoryDetail_0c5bb6?.(
        { btoParam: { ...(params ?? {}) } },
      );
    const ret = {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
    setExpandedRows((pre) => {
      if (!pre) {
        return flatten(ret.data);
      }
      return pre;
    });
    return ret;
  }, []);

  return (
    <div style={{ minHeight: '88vh' }}>
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item
            style={{ cursor: 'pointer' }}
            onClick={() => {
              vsf.navigateTo('/activityManage/activityList');
            }}
          >
            活动列表
          </Breadcrumb.Item>
          <Breadcrumb.Item>赋分规则</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Section title="赋分规则">
        <VSPromiseTable
          id="activityCategoryTable"
          pagination={false}
          cardProps={{
            bodyStyle: {
              padding: 0,
            },
          }}
          editable={{
            editType: 'single',
            extraOperations: [
              {
                order: -1,
                children: '添加子类目',
                show: (data) => data.level < 3,
                linkPage: {
                  onLinkPageValidate: async (v, vv, data) => {
                    const newData = await editPronmpt({}, '添加子类目');
                    if (newData) {
                      const ret = await createCategory(newData, data);
                      if (ret) {
                        vsf.showToast('添加子类目成功', 'success');
                        return true;
                      } else {
                        if (ret !== false) {
                          vsf.showToast('添加子类目失败', 'error');
                        }
                      }
                    }
                    return false;
                  },
                  updateTable: true,
                  updateType: 'reload',
                },
              },
            ],
            deleteConfirmProps: {
              icon: <></>,
            },
            onCanEdit: (data) => data.level > 1,
            onCanDelete: (data) => data.level > 1,
          }}
          updateLinkPage={{
            onLinkPageValidate: async (v, vv, data) => {
              const newData = await editPronmpt(data, '编辑类目');
              if (newData) {
                return {
                  ...data,
                  ...newData,
                };
              }
              return newData;
            },
          }}
          rowClick={{
            rowClickType: 'expand',
          }}
          expandable={{
            expandedRows: expandedRows,
            expandDataIndex: 'activityCategoryDtoList',
            rowExpandable: (data) => data?.activityCategoryDtoList?.length > 0,
            onExpandedRowsChange: (rows) => {
              setExpandedRows(rows);
            },
          }}
          onFetch={onFetch}
          onUpdate={async (params) => {
            try {
              const res =
                await vsf?.services?.ActivityCategoryController_updateActivityCategory_e3c8d4?.(
                  { btoParam: { ...(params ?? {}) } },
                );

              if (res?.data) {
                vsf.showToast('编辑成功', 'success');
                return res?.data;
              }
            } catch (error) {
              //
            }
            vsf.showToast('编辑失败', 'error');
            return params;
          }}
          onRemove={async (params) => {
            try {
              const res =
                await vsf?.services?.ActivityCategoryController_deleteActivityCategory_26ab3e?.(
                  { activityCategoryId: params.id },
                );
              vsf.showToast('删除成功', 'success');
              return res?.code === 200;
            } catch (error) {
              //
            }
            vsf.showToast('删除失败', 'error');
            return false;
          }}
        >
          <VSTableToolbar title="活动类目">
            <Button
              type="primary"
              onClick={async () => {
                const newData = await editPronmpt({}, '添加一级类目');
                if (newData) {
                  const ret = await createCategory(newData, 1);
                  if (ret) {
                    vsf.refs.activityCategoryTable?.reload();
                    vsf.showToast('添加一级类目成功', 'success');
                  } else {
                    if (ret !== false) {
                      vsf.showToast('添加一级类目失败', 'error');
                    }
                  }
                }
              }}
            >
              添加一级类目
            </Button>
          </VSTableToolbar>
          <VSTableColumn
            dataIndex={['categoryName']}
            title="类目名称"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '类目名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1024,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['level']}
            title="级别"
            align="center"
            width={100}
            valueType="digit"
            formItemProps={{
              rules: [
                {
                  message: '级别的值不合法',
                  type: 'number',
                  min: 0,
                  max: null,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['activityIntegral']}
            title="参与活动可得积分"
            align="center"
            valueType="digit"
            formItemProps={{
              rules: [
                {
                  message: '参与活动可得积分的值不合法',
                  type: 'number',
                  min: 0,
                  max: null,
                },
              ],
            }}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>
    </div>
  );
};
export default definePage(ScoreConfigVp);

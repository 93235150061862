import {
  compose,
  Image as AntImage,
  ImageProps as AntImageProps,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import React from 'react';

export type ImageProps = {
  /**
   * url
   */
  value?: string;
} & Omit<AntImageProps, 'src'>;

/**
 * 图片浏览
 */
const Image = (props: ImageProps) => {
  const { value, ...rest } = props;
  return <AntImage {...rest} src={value} />;
};
Image.displayName = 'Image';

export default compose(
  withField<string>({
    name: 'Image',
  }),
  withPreview<ImageProps>({
    renderPreview: (props) => {
      const { value } = props;

      /** 返回预览模式下的dom */
      return <AntImage src={value} />;
    },
  }),
)(Image);

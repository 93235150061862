import data from './data';

// 全国四个不设县（区、县级市）级编制之地级市: 广东省中山市442000、广东省东莞市441900、甘肃省嘉峪关市620200、海南省儋州市460400
const CITIES_NO_DISTRICT = ['442000', '441900', '620200', '460400'];
const allKeys = Object.keys(data);

function getDistricts(cityCode) {
  const result = [];
  const justProvince = cityCode.substring(0, 2);
  const justCity = cityCode.substring(0, 4);
  const districts = allKeys.filter((pk, index) => {
    if (cityCode.endsWith('00')) {
      if (pk.startsWith(justCity)) {
        if (!pk.endsWith('00')) {
          return true;
        }
      }
      //特别行政区, 直辖市
      if (justCity.endsWith('00')) {
        if (pk.startsWith(justProvince) && !pk.endsWith('0000')) {
          return true;
        }
      }
      // 全国四个不设县（区、县级市）级编制之地级市，要把第三级补上
      if (CITIES_NO_DISTRICT.includes(cityCode) && pk === cityCode) {
        return true;
      }
      return false;
    } else {
      return pk === cityCode;
    }
  });
  districts.forEach((ck) => {
    result.push({
      code: ck,
      name: data[ck],
    });
  });
  return result;
}

function getCities(provinceCode) {
  const result = [];
  const justProvince = provinceCode.substring(0, 2);
  const cities = allKeys.filter((pk, index) => {
    if (pk.startsWith(justProvince)) {
      if (pk.endsWith('00')) {
        const next = index + 1;
        const nextKey = allKeys[next];
        if (!nextKey.endsWith('00')) {
          return true;
        }
        if (
          pk.substring(2, 4) !== nextKey.substring(2, 4) &&
          pk.substring(2, 4) !== '00'
        ) {
          return true;
        }
      } else {
        if (index > 0) {
          let preCityIndex = index - 1;
          while (preCityIndex >= 0) {
            const preCityKey = allKeys[preCityIndex];
            if (preCityKey.endsWith('00')) {
              if (
                preCityKey.substring(2, 4) !== pk.substring(2, 4) &&
                preCityKey.substring(2, 4) !== '00'
              ) {
                return true;
              }
              break;
            }
            preCityIndex--;
          }
        }
      }
    }
    return false;
  });
  cities.forEach((ck) => {
    result.push({
      code: ck,
      name: data[ck],
      children: getDistricts(ck),
    });
  });
  return result;
}

export default () => {
  const result = [];
  const provinceKeys = allKeys.filter((key) => {
    return key.endsWith('0000');
  });
  provinceKeys.forEach((pk) => {
    result.push({
      code: pk,
      name: data[pk],
      children: getCities(pk),
    });
  });

  return result;
};

import { defineStore } from '@vs/vsf-boot';

const user = defineStore({
  userInfo: {},

  setUserInfo: (userInfo) => {
    user.userInfo = userInfo;
  },
});

export default user;

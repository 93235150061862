import vsf, { definePage } from '@vs/vsf-boot';
import { Section, Todo, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React from 'react';

const MallVoucherExchangeList = (props) => {
  return (
    <Section>
      <VSPromiseTable
        cardProps={{
          bodyStyle: {
            padding: 0,
          },
        }}
        onFetch={async (params) => {
          console.log(props, 'props?.value', params);
          const res =
            await vsf?.services?.VoucherExchangeRecordController_pageVoucherExchangeRecord_e606b1?.(
              {
                qto: {
                  voucherIdIs: props?.id,
                  from: params.pagination?.from ?? 0,
                  size: params.pagination?.size ?? 20,
                  orderList: params.orderList, // 排序结果
                  ...params.search, // 搜索结果
                  ...params.filters, // 过滤器结果
                  ...(params?.extra ?? {}), // 传入的额外参数
                },
              },
            );
          return {
            data: res?.data?.result ?? res?.data ?? [],
            total: res?.data?.count ?? res?.data?.length ?? 0,
          };
        }}
      >
        <VSTableColumn
          dataIndex={['voucher', 'voucherName']}
          title="商券名称"
          align="center"
          editable={false}
          valueType="text"
        />

        <VSTableColumn
          dataIndex={['recordTime']}
          title="记录时间"
          align="center"
          editable={false}
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['exchangeUser', 'nickname']}
          title="核销人"
          align="center"
          editable={false}
          valueType="text"
        />
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(MallVoucherExchangeList);

import MallVoucherExchangeList from '@pages/MallVoucherExchangeList';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Breadcrumb,
  Button,
  Column,
  Columns,
  confirm,
  message,
  Modal,
  Section,
  Todo,
  VSForm,
  VSFormFooter,
  VSFormItem,
} from '@vs/vsf-kit';
import ImgCrop from 'antd-img-crop';
import dayjs from 'dayjs';
import React, { useCallback, useRef, useState } from 'react';

import { SelectUser } from '@/components';
import getOssPolicy from '@/utils/getOssPolicy';

const MallVoucherEditor = (props) => {
  const { isCreate, value } = props;
  const { id } = value || {};
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [readonly, setReadonly] = useState(!isCreate);
  const [data, setData] = useState();
  const formRef = useRef(null);

  const formData = (data) => {
    if (!data) {
      return data;
    }
    const { associationMerchantDtoList, ...rest } = data;
    return { ...rest, associationMerchant: associationMerchantDtoList };
  };

  const toData = (data) => {
    if (!data) {
      return data;
    }
    const { associationMerchant, ...rest } = data;
    return { btoParam: rest, camBtoParam: associationMerchant };
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => {
    return current && current < dayjs().subtract(1, 'day').endOf('day');
  };

  const disabledDateTime = () => {
    const nowHour = dayjs().getHours();
    const nowMinutes = dayjs().getMinutes();
    return {
      disabledHours: () => range(0, nowHour),
      disabledMinutes: () => range(0, nowMinutes),
      disabledSeconds: () => [0, 60],
    };
  };

  const onFetch = useCallback(async (params) => {
    if (!params?.mallVoucherId || params?.mallVoucherId < 0) {
      setLoading(false);
      return undefined;
    }
    try {
      const res =
        await vsf?.services?.MallVoucherController_getMallVoucher_5fcff3?.({
          mallVoucherId: params?.mallVoucherId,
        });
      if (res?.code !== 200) {
        vsf.showToast('获取活动详情失败', 'error');
      }
      const data = formData(res?.data);
      setData(data);
      setLoading(false);

      return data;
    } catch (error) {
      vsf.showToast('获取活动详情失败', 'error');
    }
    setLoading(false);
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div style={{ marginBottom: '15px' }}>
        <Breadcrumb>
          <Breadcrumb.Item
            style={{ cursor: 'pointer' }}
            onClick={() => {
              vsf.navigateTo('/mallVoucherManage/mallVoucherList');
            }}
          >
            商券列表
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {isCreate ? '新建商券' : '商券详情'}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Section
        style={{
          backgroundColor: '#ffffff',
          padding: '20px',
          minHeight: '88vh',
          borderRadius: '6px',
        }}
        title={isCreate ? '新建商券' : '商券详情'}
        className="mallVoucherContainer"
      >
        <VSForm
          ref={formRef}
          readonly={readonly}
          style={{ height: loading ? '0px' : undefined, overflow: 'hidden' }}
          layout="horizontal"
          labelCol={{
            span: 2,
          }}
          params={{ mallVoucherId: id }}
          onFetch={onFetch}
          onFinish={(data, success) => {
            const text = isCreate ? '商券上架' : '商券编辑';
            if (success) {
              setTimeout(() => {
                setData(data);
                vsf.showToast(`${text}成功`, 'success');
                setReadonly(true);
              }, 2000);
            }
          }}
          onSubmit={async (params) => {
            setIsLoading(true);
            try {
              const request = isCreate
                ? vsf.services
                    .MallVoucherController_createMallVoucherInfo_f2514b
                : vsf.services.MallVoucherController_updateVoucherInfo_b4a70c;
              const res = await request?.(toData(params));
              if (res?.code === 200) {
                setTimeout(() => {
                  setIsLoading(false);
                  vsf.navigateTo('/mallVoucherManage/mallVoucherList');
                }, 2000);
              } else {
                setIsLoading(false);
              }
              return formData(res?.data);
            } catch (error) {
              //
              setIsLoading(false);
            }
            return undefined;
          }}
        >
          <VSFormItem
            name={['id']}
            label="主键"
            style={{ display: 'none' }}
            valueType="digit"
            fieldProps={{}}
          />
          <VSFormItem
            name={['voucherName']}
            label="商劵名称"
            valueType="text"
            rules={[
              { required: true, message: '请输入商券名称' },
              {
                message: '商劵名称长度不合法',
                type: 'string',
                min: 0,
                max: 1024,
              },
            ]}
            fieldProps={{
              placeholder: '请输入商券名称',
              maxLength: 15,
              width: 250,
            }}
          />

          <VSFormItem
            name={['voucherImg']}
            label="商劵图片"
            valueType="fileUpload"
            rules={[{ required: true, message: '请上传商券图片' }]}
            fieldProps={{
              type: 'picture',
              getOssPolicy,
              limit: 500 * 1024,
              onLimitOverflow: (fileSize, limit) => {
                vsf.showToast('图片内存大小不可超过500kb', 'error');
              },
              uploadProps: {
                maxCount: 3,
                accept: '.jpg,.png,.jpeg',
              },
              container: (
                <ImgCrop
                  modalTitle="裁剪图片"
                  rotationSlider
                  aspect={1 / 1}
                  showReset
                  modalWidth={1000}
                  children={<></>}
                />
              ),
            }}
            convertValue={(v) => {
              if (!v) {
                return v;
              }
              return v.map((vv) => {
                if (typeof vv === 'string') {
                  return { url: vv };
                }
                return vv;
              });
            }}
            transform={(v) => {
              return {
                voucherImg: v?.map((vv) => {
                  return vv?.url ? vv.url : vv;
                }),
              };
            }}
          />
          {isCreate ? (
            <>
              <div
                style={{
                  margin: '-20px 10px 12px 90px',
                  fontSize: '12px',
                }}
              >
                建议上传jpg/png文件，且大小不超过500kb
              </div>
            </>
          ) : null}
          <VSFormItem
            name={['voucherPrice']}
            label="商劵价值"
            valueType="digit"
            rules={[
              { required: true, message: '请输入商券价值' },
              {
                message: '商劵价值的值不合法',
                type: 'number',
                min: 0,
                max: null,
              },
            ]}
            fieldProps={{
              placeholder: '请输入商券价值',
              width: 250,
            }}
            convertValue={(value) =>
              typeof value === 'number' && value >= 0 ? value : ''
            }
          />
          <VSFormItem
            name={['voucherEffectiveStart']}
            label="商劵有效期"
            valueType="date"
            rules={[{ required: true, message: '请选择有效期开始时间' }]}
            fieldProps={{
              format: 'YYYY-MM-DD HH:mm',
              showTime: true,
              placeholder: '请选择有效期开始时间',
              width: 250,
              disabledDate: disabledDate,
              // disabledDateTime: disabledDateTime,
            }}
            transform={(v) => {
              return ['voucherEffectiveStart'].reduceRight(
                (acc, curr) => ({ [curr]: acc }),
                dayjs(v).format('YYYY-MM-DD HH:mm:00'),
              );
            }}
          />
          <VSFormItem
            name={['voucherEffectiveEnd']}
            label="至"
            valueType="date"
            rules={[{ required: true, message: '请选择有效期结束时间' }]}
            fieldProps={{
              format: 'YYYY-MM-DD HH:mm',
              showTime: true,
              placeholder: '请选择有效期结束时间',
              width: 250,
              disabledDate: disabledDate,
              // disabledDateTime: disabledDateTime,
            }}
            transform={(v) => {
              return ['voucherEffectiveEnd'].reduceRight(
                (acc, curr) => ({ [curr]: acc }),
                dayjs(v).format('YYYY-MM-DD HH:mm:00'),
              );
            }}
          />
          <VSFormItem
            name={['totalStock']}
            label="总库存"
            valueType="digit"
            rules={[
              { required: true, message: '请输入总库存' },
              { message: '总库存不可低于0', type: 'number', min: 0, max: null },
            ]}
            fieldProps={{
              placeholder: '请输入总库存',
              width: 250,
            }}
            convertValue={(value) =>
              typeof value === 'number' && value >= 0 ? value : ''
            }
          />
          {!isCreate && (
            <>
              <VSFormItem
                name={['exchangeNum']}
                label="已核销数"
                valueType="digit"
                readonly
                convertValue={(value) =>
                  typeof value === 'number' ? value : 0
                }
              />
              <VSFormItem
                name={['residueStock']}
                label="剩余库存"
                valueType="digit"
                readonly
                convertValue={(value) =>
                  typeof value === 'number' ? value : 0
                }
              />
              <VSFormItem
                name={['notUsedStock']}
                label="未使用数"
                valueType="digit"
                readonly
                convertValue={(value) =>
                  typeof value === 'number' ? value : 0
                }
              />
            </>
          )}
          <div className="antd-formitem-contnent-border">
            <VSFormItem
              name={['exchangeAddress']}
              label="兑换地址"
              valueType="textarea"
              rules={[
                { required: true, message: '请输入兑换地址' },
                {
                  message: '兑换地址长度不合法',
                  type: 'string',
                  min: 0,
                  max: 24,
                },
              ]}
              fieldProps={{
                placeholder: '请输入兑换地址',
                maxLength: 24,
                showCount: true,
                width: 250,
              }}
            />
          </div>
          <div className="antd-formitem-contnent-border">
            <VSFormItem
              name={['voucherInfo']}
              label="商劵详情"
              valueType="textarea"
              rules={[
                { required: true, message: '请输入商劵详情' },
                {
                  message: '商劵详情长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1024,
                },
              ]}
              fieldProps={{
                placeholder: '请输入商劵详情',
                maxLength: 300,
                showCount: true,
                width: 250,
              }}
            />
          </div>

          <VSFormItem
            name={['associationMerchant']}
            label="关联商家"
            valueType="selectUser"
            initialValue={[]}
            fieldProps={{
              mallVoucherId: data?.id,
              defaultOpen: true,
              defaultActiveFirstOption: true,
            }}
            rules={[
              { required: true, message: '请至少关联一个商家' },
              {
                validator: async (rule, value) => {
                  if (Array.isArray(value)) {
                    return value.length > 0;
                  }
                  return false;
                },
                // message: '请至少关联一个商家',
                // message: message.errorr('请至少关联一个商家'),
              },
            ]}
          ></VSFormItem>
          <VSFormFooter>
            <Button
              onClick={() => {
                if (isCreate || readonly) {
                  vsf.navigateTo('/mallVoucherManage/mallVoucherList');
                } else {
                  formRef.current?.resetFields();
                  formRef.current?.setFieldsValue(data);
                  setReadonly(true);
                }
              }}
            >
              {isCreate || readonly ? '返回' : '取消'}
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: readonly ? 'orange' : undefined,
                borderColor: readonly ? 'orange' : undefined,
              }}
              loading={isLoading}
              onClick={() => {
                if (readonly) {
                  setReadonly(false);
                } else {
                  formRef.current?.submit();
                }
              }}
            >
              {readonly ? '编辑' : '保存'}
            </Button>
            {!isCreate && readonly && (
              <Button type="primary" onClick={() => setOpen(true)}>
                商券核销记录
              </Button>
            )}
          </VSFormFooter>
        </VSForm>
        {!isCreate && (
          <Modal
            title="商券核销记录"
            open={open}
            onCancel={() => setOpen(false)}
            width={800}
            footer={false}
          >
            <MallVoucherExchangeList id={data?.id} />
          </Modal>
        )}
      </Section>
    </div>
  );
};

export default definePage(MallVoucherEditor);

import React from 'react';

export const timeFormat = (stamp?: number | string | Date) => {
  if (!stamp) return '-';
  const time = new Date(stamp);
  const z = (n: number) => (n < 10 ? '0' + n : n);
  return `${time.getFullYear()}年${
    time.getMonth() + 1
  }月${time.getDate()}日 ${z(time.getHours())}:${z(time.getMinutes())}:${z(
    time.getSeconds(),
  )}`;
};

export const duplicateName = (fields: { name?: string | undefined }[]) => {
  const countList = fields.reduce<{ [key: string]: number }>(function (p, c) {
    if (c.name) {
      p[c.name] = (p[c.name] || 0) + 1;
    }
    return p;
  }, {});
  const result = fields.filter((o) => {
    return o.name ? countList[o.name] > 1 : false;
  });
  if (result.length !== 0) {
    const names = result
      .map((o) => o.name)
      .reduce<Array<string>>((p, c) => {
        if (c && !p.includes(c)) {
          p.push(c);
        }
        return p;
      }, []);
    return names;
  }
  return undefined;
};

// 诡异，在mock的页面上，有时不能正常强制刷新
export function useForceUpdateOld() {
  const [updateValue, setUpdateValue] = React.useState(0);
  return () => {
    const value = updateValue;
    setUpdateValue(value + 1);
  };
}

// source from https://www.npmjs.com/package/use-force-update
export function useForceUpdate(): () => void {
  const [, dispatch] = React.useState<Record<string, any>>(Object.create(null));

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = React.useCallback((): void => {
    dispatch(Object.create(null));
  }, [dispatch]);
  return memoizedDispatch;
}

export const randomColor = () => {
  const h = Math.floor(Math.random() * 360);
  return `hsl(${h}deg, 100%, 30%)`;
};

export function isPromise(obj) {
  return (
    !!obj &&
    (typeof obj === 'object' || typeof obj === 'function') &&
    typeof obj.then === 'function'
  );
}

export function formatNumber(num) {
  if (num <= 10000) {
    return num;
  }
  const str = String(num);
  let formattedStr = str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const decimalStr = formattedStr.split('.')[1];
  if (decimalStr && decimalStr.length < 2) {
    formattedStr += '0';
  }
  if (formattedStr.split('.')[1] && formattedStr.split('.')[1].length > 2) {
    formattedStr =
      formattedStr.split('.')[0] + '.' + formattedStr.split('.')[1].slice(0, 2);
  }
  if (formattedStr.split('.')[0] === '0') {
    formattedStr = formattedStr.split('.')[1];
  }
  return formattedStr;
}

export function getAgeAndGenderFromID(id) {
  // 提取出生日期
  const birthYear = id.substring(6, 10);
  const birthMonth = id.substring(10, 12);
  const birthDay = id.substring(12, 14);

  // 计算年龄
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  let age = currentYear - parseInt(birthYear);
  if (
    currentMonth < parseInt(birthMonth) ||
    (currentMonth === parseInt(birthMonth) && currentDay < parseInt(birthDay))
  ) {
    age--;
  }

  // 获取性别
  const genderCode = parseInt(id.charAt(16));
  const gender = genderCode % 2 === 0 ? '女' : '男';

  return {
    age: age,
    gender: gender,
  };
}

import ActivityListVp from '@pages/ActivityListVp';
import vsf, { definePage, renderRoutes } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';
const ActivityList = (props) => {
  return (
    <Page>
      <ActivityListVp type={props.routes.query?.type} />
    </Page>
  );
};
export default definePage(ActivityList);
